<template>
  <a
    @click="toggleSpeechRecognition()"
    class="position-relative bottom-dock-btn"
  >
    <i
      :class="`bottom-dock-btn__icon  bottom-dock-btn__ ${iconClass} bx bx-${iconName}`"
      title="Toggle Speech Recognisation"
    ></i>
  </a>
</template>

<script>
import annyang from 'annyang';
import stringSimilarity from 'string-similarity';
import graphReactor from '@/common/core/Graph/graphReactor';
import voiceCommands from '@/config/voiceCommands';

export default {
  data() {
    return {
      speechData: '',
      isActive: false,
    };
  },

  computed: {
    iconName() {
      return this.isActive ? 'microphone' : 'microphone-off';
    },

    iconClass() {
      return this.isActive ? 'icon--active' : 'icon--inactive';
    },
  },

  methods: {
    openComponentActiveLayout(rqComp) {
      const componentName = rqComp.split(' ').toString().replace(',', '');
      const matches = stringSimilarity.findBestMatch(
        componentName,
        this.$store.state.allComponentList,
      );

      // console.log('OPERATION BLACKOUT', this.$store.state.layouts.view, this.$store.state.layouts[this.$store.state.layouts.view]);

      this.componentName = matches.bestMatch.target;
      const payload = {
        componentName: this.componentName,
        layoutKey: this.$store.state.layouts[this.$store.state.layouts.view].activeLayoutKey,
      };

      this.$store.dispatch('activateLayout', payload);
    },
    openDynamicComponent(rqComp, key) {
      const componentName = rqComp.split(' ').toString().replace(',', '');
      const matches = stringSimilarity.findBestMatch(
        componentName,
        this.$store.state.allComponentList,
      );
      const bestMatchKey = stringSimilarity.findBestMatch(key, [
        'first',
        'second',
        'third',
        'fourth',
        'fifth',
        'sixth',
      ]);
      const familarKey = bestMatchKey.bestMatch.target;
      this.layoutKey = '';

      if (familarKey === 'first') {
        this.layoutKey = '_a';
      } else if (familarKey === 'second') {
        this.layoutKey = '_b';
      } else if (familarKey === 'third') {
        this.layoutKey = '_d';
      } else if (familarKey === 'fourth') {
        this.layoutKey = '_c';
      } else if (familarKey === 'fifth') {
        this.layoutKey = '_f';
      } else if (familarKey === 'sixth') {
        this.layoutKey = '_e';
      } else {
        this.layoutKey = '_a';
      }
      const payload = {
        componentName: matches.bestMatch.target,
        layoutKey: this.layoutKey,
      };
      this.$store.dispatch('activateLayout', payload);
    },
    convertFirstLetterToUpperCaseOfEachWord(word) {
      return word.split(' ').map((i) => i[0].toUpperCase() + i.substring(1)).join(' ');
    },
    fetchNodeFocus(searchWord) {
      const finalSearchWord = this.convertFirstLetterToUpperCaseOfEachWord(searchWord);
      // console.log('searchWord', finalSearchWord);
      const nodeInstance = graphReactor.exploreInstance.findById(finalSearchWord);
      if (nodeInstance) {
        const wordObj = {
          word: finalSearchWord,
          label: finalSearchWord,
          type: 'string',
          lang: 'en',
        };
        this.$store.dispatch('setCurrentThought', {
          thoughtObject: wordObj,
          excludeCallList: ['gmodule/exploreGraphFetch'],
        });
      }
    },
    fetchInfo(searchWord) {
      const wordObj = {
        word: searchWord,
        label: searchWord,
        type: 'string',
        lang: 'en',
      };

      this.$store.dispatch('setCurrentThought', {
        thoughtObject: wordObj,
      });
    },
    toggleSpeechRecognition() {
      if (this.isActive) {
        this.stopSpeechRecognition();
      } else {
        this.startSpeechRecognition();
      }
    },

    startSpeechRecognition() {
      this.isActive = true;
      const commands = {};
      voiceCommands.forEach((item) => {
        if (item.level === 'layout') {
          commands[item.command] = () => {
            this.$root.$emit(item.event);
          };
        } else if (item.level === 'route') {
          commands[item.command] = () => {
            this.$router.push({
              path: item.path,
            });
          };
        } else if (item.level === 'speech') {
          commands[item.command] = () => {
            this.stopSpeechRecognition();
          };
        } else if (item.level === 'dynamic-active-comp') {
          commands[item.command] = this.openComponentActiveLayout;
        } else if (item.level === 'dynamic-open-comp') {
          commands[item.command] = this.openDynamicComponent;
        } else if (item.level === 'global') {
          commands[item.command] = (tag) => {
            this.fetchInfo(tag);
          };
        } else if (item.level === 'graphNode') {
          commands[item.command] = (tag) => {
            this.fetchNodeFocus(tag);
          };
        }
        return null;
      });
      annyang.addCommands(commands);
      annyang.start();
    },

    stopSpeechRecognition() {
      this.isActive = false;
      annyang.abort();
    },

    escapeListener(e) {
      if (e.key === 'm' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault(); // present "Save Page" from getting triggered.
        this.toggleSpeechRecognition();
      }
    },
  },

  mounted() {
    this.$root.$on('toggleSpeechRecognition', this.toggleSpeechRecognition);
  },
  beforeDestroy() {
    this.$root.$off('toggleSpeechRecognition');
  },

  created() {
    window.addEventListener('keydown', this.escapeListener);
  },

  destroyed() {
    window.removeEventListener('keydown', this.escapeListener);
  },
};
</script>

<style lang="scss" scoped>
.voice-active {
  fill: #0d6efd;
}

.voice-inactive {
  fill: #aeaeae;
}
</style>
