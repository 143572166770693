export default {
  // access local state by using {state} and rootState by {rootState}
  EMPTY_GRAPH: (state) => {
    state.g6_explore_data = { nodes: [], edges: [], combos: [] };
  },

  SET_GRAPH_SAVED_DATA: (state, data) => {
    state.g6_explore_data = data;
  },

  SET_ANCHOR: (state) => {
    if (state.anchor !== state.active_node.label) {
      state.anchor = state.active_node.label;
    } else {
      state.anchor = '';
    }
  },

  UPDATE_HIDDEN_NODES: (state, hiddenNodes) => {
    state.hidden_node_list = [...hiddenNodes];
  },

  ADD_HIDDEN_NODES: (state, listOfNodes) => {
    state.hidden_node_list = [...state.hidden_node_list, ...listOfNodes];
  },

  SET_ANCHOR_FROM_TEXT: (state, payload) => {
    state.anchor = payload;
  },

  SET_ACTIVE_NODE: (state, nodeModel) => {
    state.active_node = nodeModel;
  },

  SET_ACTIVE_GRAPH_DETAIL: (state, data) => {
    state.graph_detail = data;
  },

  SET_GROUP_MODE: (state, data) => {
    state.groupMode = data;
  },

  SET_GRAPH_DELETED_DATA: (state) => {
    state.g6_explore_data = { nodes: [], edges: [] };
  },

  SET_EDIT_MODE: (state, data) => {
    state.edit_mode = data;
  },

  pushMyNode: (state, data) => {
    state.mainNodesOfExplore.push(data);
  },

  SET_GRAPH_UPDATED_DATA: (state, data) => {
    state.g6_explore_data = data.g6_data.gdata;
  },

  ADD_NOTE_NODES_EDGES: (state, { type, data }) => {
    if (type === 'node') {
      state.g6_explore_data.nodes.push(data);
    } else if (type === 'edge') {
      state.g6_explore_data.edges.push(data);
    }
  },
  UPDATE_USER_LIST: (state, data) => {
    state.usersList = data;
  },

  EXPAND_DATA: (state, data) => {
    const rawData = {};
    rawData.nodes = [...data.nodes, ...state.g6_explore_data.nodes];
    rawData.edges = [...data.edges, ...state.g6_explore_data.edges];
    rawData.combos = [...data.combos, ...state.g6_explore_data.combos];
    state.g6_explore_data = rawData;
  },

  setExploreGraphExpansion: (state, { exploreMapResponse, exploreEntity }) => {
    const allNodes = [...state.g6_explore_data.nodes, ...exploreMapResponse.nodes];
    const newData = {};
    const uniqueNodes = allNodes.reduce((uniqueMap, node) => {
      if (uniqueMap[node.id] === undefined) {
        // eslint-disable-next-line no-param-reassign
        uniqueMap[node.id] = node;
      } else if (node.id === exploreEntity) {
        // eslint-disable-next-line no-param-reassign
        uniqueMap[node.id] = {
          id: exploreEntity,
          label: exploreEntity,
          type: 'circle',
          size: 40,
          style: {
            fill: '#fff',
            stroke: '#333',
          },
          cluster: exploreEntity,
        };
      }
      return uniqueMap;
    }, {});
    newData.nodes = Object.values(uniqueNodes);
    newData.edges = [...state.g6_explore_data.edges, ...exploreMapResponse.edges];

    if (exploreMapResponse.combos === undefined) {
      newData.combos = [...state.g6_explore_data.combos];
    } else {
      newData.combos = [...state.g6_explore_data.combos, ...exploreMapResponse.combos];
    }
    state.g6_explore_data = newData;
  },

  SET_G6_EXPLORE_DATA: (state, data) => {
    const newData = {};
    newData.nodes = data.nodes;
    newData.edges = data.edges;
    newData.combos = data.combos;
    state.g6_explore_data = newData;
  },

  SET_DIMENSION_DATA: (state, data) => {
    state.dimension_data = data;
  },
  SET_EDGE_VAL: (state, data) => {
    state.edge = data;
  },
  IS_ADDING_EDGE: (state, data) => {
    state.addingEdge = data;
  },

  TOGGLE_ARROW_KEY: (state) => {
    state.isArrowEdge = !state.isArrowEdge;
  },

  TOGGLE_GENERATIVE_FUNCTION: (state, payload) => {
    state.generativeFunction.isActive = payload.value;
  },

  SET_VR_GRAPH: (state, payload) => {
    state.vr.gdata = payload.graph;
  },
  SET_VR_SCALE: (state, payload) => {
    state.vr.scale = payload.scale;
  },
};
