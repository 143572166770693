const voiceCommands = [
  {
    command: 'jump to *tag',
    event: 'nodeFocus',
    level: 'graphNode',
  },
  {
    command: 'start screen recorder',
    event: 'startScreenRecord',
    level: 'layout',
  },
  {
    command: 'stop screen recorder',
    event: 'stopScreenRecord',
    level: 'layout',
  },
  {
    command: 'go on mute',
    event: 'toggleSpeechRecognition',
    level: 'speech',
  },
  {
    command: 'Hello Santa',
    event: 'toggleSpeechRecognition',
    level: 'speech',
  },
  {
    command: 'pause microphone',
    event: 'micPause',
    level: 'speech',
  },
  {
    command: 'edit layout',
    event: 'editLayout',
    level: 'layout',
  },
  {
    command: 'close edit layout',
    event: 'editLayout',
    level: 'layout',
  },
  {
    command: 'resize layout',
    event: 'resizeLayout',
    level: 'layout',
  },
  {
    command: 'close resize layout',
    event: 'resizeLayout',
    level: 'layout',
  },
  {
    command: 'jump to next thought',
    event: 'goForwardThought',
    level: 'layout',
  },
  {
    command: 'jump to previous thought',
    event: 'goBackThought',
    level: 'layout',
  },
  {
    command: 'save current thought',
    event: 'savethought',
    level: 'layout',
  },
  {
    command: 'start camera',
    event: 'openCamera',
    level: 'layout',
  },
  {
    command: 'stop camera',
    event: 'openCamera',
    level: 'layout',
  },
  {
    command: 'start thought recorder',
    event: 'recordThoughtMap',
    level: 'layout',
  },
  {
    command: 'stop thought recorder',
    event: 'recordThoughtMap',
    level: 'layout',
  },
  {
    command: 'open sidebar',
    event: 'sidebar',
    level: 'layout',
  },
  {
    command: 'close sidebar',
    event: 'sidebar',
    level: 'layout',
  },
  {
    command: 'switch to explore view',
    path: '/',
    level: 'route',
  },
  {
    command: 'switch to author view',
    path: '/Author-view',
    level: 'route',
  },
  {
    command: 'switch to simplified view',
    path: '/Simplified-view',
    level: 'route',
  },
  {
    command: 'switch to reading view',
    path: '/Reading-view',
    level: 'route',
  },
  {
    command: 'switch to canvas view',
    path: '/canvas',
    level: 'route',
  },
  {
    command: 'show *rqComp',
    event: 'dynamic',
    level: 'dynamic-active-comp',
  },
  {
    command: 'open *rqComp in *key',
    event: 'dynamic',
    level: 'dynamic-open-comp',
  },
  {
    command: 'Tell me about *tag',
    event: 'search',
    level: 'global',
  },
  {
    command: 'Describe *tag',
    event: 'search',
    level: 'global',
  },
  {
    command: 'Speak details',
    event: 'textToSpeechDetails',
    level: 'layout',
  },
  {
    command: 'Speak information',
    event: 'textToSpeechInformation',
    level: 'layout',
  },
  {
    command: 'Speak help',
    event: 'textToSpeechHelp',
    level: 'layout',
  },
  {
    command: 'new slot',
    event: 'addNewPanel',
    level: 'layout',
  },
];

export default voiceCommands;
