import chroma from 'chroma-js';

function nodePlate(id, label) {
  return {
    type: 'circle',
    label,
    tjType: 'custom-node',
    description: {
      content: '',
    },
    size: 30,
    labelCfg: {
      /* label's position, options: center, top, bottom, left, right */
      position: 'top',
      color: '#fff',
      /* label's offset to the keyShape, 4 by default */
      offset: 2,
      /* label's style */
      style: {
        fontSize: 15,
        fill: '#fff',
        fontWeight: 500,
        fontFamily: 'Poppins',
        color: '#fff',
      },
    },
    style: {
      size: [200],
      stroke: '#fff',
      fill: '#659dbd',
      lineWidth: 1,
      // shadowOffsetX: 10,
      // shadowOffsetY: 10,
      shadowColor: '#659dbd',
      shadowBlur: 10,
    },
    id, // Generate a unique id
  };
}

function comboPlate(label) {
  const combo = {
    id: label,
    label,
    tjType: 'custom-combo',
    style: {
      lineWidth: 1,
      opacity: 0.3,
      fill: chroma.random().darken().hex(),
    },
  };
  return combo;
}

export {
  nodePlate,
  comboPlate,
};
