import hotkeys from 'hotkeys-js';
import store from '@/store';
import graphReactor from '@/common/core/Graph/graphReactor';

export default () => {
  hotkeys('ctrl+x, command+x', () => {
    const selectedNodes = graphReactor.exploreInstance.findAllByState('node', 'selected');
    if (selectedNodes.length > 0) {
      selectedNodes.forEach((node) => {
        graphReactor.exploreInstance.removeItem(node);
      });
    }
  });

  hotkeys('del, delete', () => {
    store.commit('gmodule/EMPTY_GRAPH');
  });
};
