/* eslint-disable no-unused-vars */
// eslint-disable
import flagReader from '@/utils/flagReader';
import graphReactor from '@/common/core/Graph/graphReactor';
import GraphOps from '@/common/core/Graph/graphReactor/GraphOps';

export default {
  /**
   * @fn addVertex
   * @params
   *---------------------------------------- | -------------------------------------------|
   *
   * graphName:
   *    type: String.
   *    desc: Graph Name which we want to use.
   *    sample:'explore'.
   *
   *  payload:
   *    type: Object/[Object]
   *    desc: Any thing we pass through the function
   *    sample:-
   *      const model = {
   *        id: 'node',
   *        label: 'node',
   *        address: 'cq',
   *        x: 200,
   *        y: 150,
   *        style: {
   *          fill: 'blue',
   *        },
   *      };
   *
   *  flags:
   *    type: String
   *    desc: flags defined what kind of action does this have to perform
   *    sample: '-create-combo'
   *
   *---------------------------------------- | -------------------------------------------|
   *
   * @return
   *  Nodes:
   *    type: [Object]
   *    (See Node Model samples and Description in Graph Docs)
   */
  addVertex(graphName, payload, flags = '') {
    const graph = GraphOps.graph(graphName);

    if (graph && graph !== null) {
      // get options
      const options = flagReader(flags);

      // handle payload Input
      let payloads;
      if (!Array.isArray(payload)) {
        payloads = [payload];
      } else {
        payloads = payload;
      }

      const Nodes = [];
      payloads.forEach((payloadItem) => {
        const existingVertex = this.getVertexValue(graphName, payloadItem);
        if (existingVertex && existingVertex !== -1) {
          Nodes.push(existingVertex);
        } else {
          const newNode = graph.addItem('node', {
            ...payloadItem,
          });
          if (payloadItem.tjNodeState !== null) {
            graph.setItemState(newNode, 'tjNodeState', payloadItem.tjNodeState);
          }
          Nodes.push(Nodes);
        }
      });
      if (Nodes.length > 1) {
        return Nodes;
      }
      return Nodes[0];
    }
    console.warn('graph in add vertex does not exists');
    return null;
  },

  /**
   * @fn addEdge
   * @params
   *---------------------------------------- | -------------------------------------------|
   *
   * graphName:
   *    type: String.
   *    desc: Graph Name which we want to use.
   *    sample:'explore'.
   *
   *  payload:
   *    type: Object/[Object]
   *    desc: Any thing we pass through the function
   *    sample:-
   *      const model = {
   *        source: userId,
   *        target: item.id,
   *        label: 'has noted',
   *        style: {
   *          endArrow: true,
   *        },
   *      };
   *
   *  flags:
   *    type: String
   *    desc: flags defined what kind of action does this have to perform
   *    sample: '-create-combo'
   *
   *---------------------------------------- | -------------------------------------------|
   *
   * @return
   *  Edges:
   *    type: [Object]
   *    (See Edge Model samples and Description in Graph Docs)
   */
  addEdge(graphName, payload, flags = '') {
    const graph = GraphOps.graph(graphName);

    if (graph && graph != null) {
      // get options
      const options = flagReader(flags);

      // handle payload Input
      let payloads;
      if (!Array.isArray(payload)) {
        payloads = [payload];
      } else {
        payloads = payload;
      }

      const Edges = [];
      payloads.forEach((payloadItem) => {
        const existingEdge = this.getEdgeValue(graphName, payloadItem);
        if (existingEdge && existingEdge !== -1) {
          Edges.push(existingEdge);
        } else {
          const newNode = graph.addItem('edge', payloadItem);

          if (payloadItem.tjEdgeState !== null) {
            graph.setItemState(newNode, 'tjEdgeState', payloadItem.tjEdgeState);
          }
          Edges.push(newNode);
        }
      });

      if (Edges.length > 1) {
        return Edges;
      }
      return Edges[0];
    }
    console.warn('GraphEngine: graphNot found in addEdge');
    return null;
  },

  /**
   * @fn getVertexValue
   * @params
   *---------------------------------------- | -------------------------------------------|
   *
   * graphName:
   *    type: String.
   *    desc: Graph Name which we want to use.
   *    sample:'explore'.
   *
   *  payload:
   *    type: nodeId/Object
   *    desc: Any thing we pass through the function
   *    sample:-
   *      const model = {
   *        id: 'node',
   *        label: 'node',
   *        address: 'cq',
   *        x: 200,
   *        y: 150,
   *        style: {
   *          fill: 'blue',
   *        },
   *      };
   *
   *  flags:
   *    type: String
   *    desc: flags defined what kind of action does this have to perform
   *    sample: '-create-combo',
   *    operations:
   *      1. '-all' flag can be used to get all the node from the graph.
   *
   *---------------------------------------- | -------------------------------------------|
   *
   * @return
   *  Nodes:
   *    type: [Object]
   *    desc: Can return a single node or a array of nodes.
   *    (See Node Model samples and Description in Graph Docs)
   */
  getVertexValue(graphName, payload, flags = '') {
    const graph = GraphOps.graph(graphName);

    if (graph && graph !== null) {
      // get options
      const options = flagReader(flags);

      // console.log('GraphEngine:  options', options);

      /**
       * Pass `-all` flag to get all the
       * vertex/node in the graph
      */
      if (options.fetchAll) {
        return graph.getNodes();
      }

      if (options.byState) {
        const selectedNodes = graph.findAllByState('node', payload.state);
        // console.log(selectedNodes);
        if (selectedNodes.length > 0) {
          return selectedNodes;
        }
        console.warn('GraphEngine: selected nodes are empty in getVertexValue');
      }

      let nodeId = payload;
      if (typeof payload === 'object') {
        // ? if object has _cfg property which means its object itself
        if (Object.hasOwn(payload, '_cfg')) {
          // eslint-disable-next-line no-underscore-dangle
          return payload;
        }
        nodeId = payload.id;
        // ? else assign payload.id to nodeId
      }

      // ? find NodeItem
      const nodeItem = graph.findById(nodeId);
      // ? if nodeItem exist then return
      if (nodeItem) {
        return nodeItem;
      }
    }
    if (!graph) {
      console.warn('GraphEngine: graph not exists in get Vertex Value', graph, graphName);
    }
    return null;
  },

  /**
   * @fn getEdgeValue
   * @params
   *---------------------------------------- | -------------------------------------------|
   *
   * graphName:
   *    type: String.
   *    desc: Graph Name which we want to use.
   *    sample:'explore'.
   *
   *  payload:
   *    type: edgeId/Object
   *    desc: Any thing we pass through the function
   *    sample:-
   *      const model = {
   *        source: userId,
   *        target: item.id,
   *        label: 'has noted',
   *        style: {
   *          endArrow: true,
   *        },
   *      };
   *
   *  flags:
   *    type: String
   *    desc: flags defined what kind of action does this have to perform
   *    sample: '-create-combo',
   *    operations:
   *      1. '-all' flag can be used to get all the node from the graph.
   *
   *---------------------------------------- | -------------------------------------------|
   *
   * @return
   *  null
   */
  getEdgeValue(graphName, payload, flags = '') {
    // fetch Graph Instance
    const graph = GraphOps.graph(graphName);

    if (graph && graph !== null) {
      // get options
      const options = flagReader(flags);

      /**
       * Pass `-all` flag to get all the
       * edges in the graph
       *
      */
      if (options.fetchAll) {
        return graph.getEdges();
      }

      // handle Payload
      let source;
      let target;
      if (typeof payload === 'object') {
        if (Array.isArray(payload)) {
          [source, target] = payload;
        } else if (payload.source && payload.target) {
          ({ source, target } = payload);
        } else if (typeof payload.getModel() === 'object') {
          return payload;
        }

        return graph.find('edge', (edge) => {
          const edgeModel = edge.get('model');
          if (edgeModel.source === source && edgeModel.target === target) {
            return edge;
          } return null;
        });
      }
      const edgeItem = graph.findById(payload);
      // console.log('GraphEngine:getEdgeValue ->', payload.getModel(), typeof payload);

      if (!edgeItem) {
        console.warn('GraphEngine:getEdge => Edge not Found');
        return null;
      }
      return edgeItem;
    }
    console.warn('GraphEngine:getEdgeValue -> Graph not Exists');
    return null;
  },

  /**
   * @fn setVertexValue
   * @params
   *---------------------------------------- | -------------------------------------------|
   *
   * graphName:
   *    type: String.
   *    desc: Graph Name which we want to use.
   *    sample:'explore'.
   *
   *  payload:
   *    type: nodeId/Object
   *    desc: Any thing we pass through the function
   *    sample:-
   *      const model = {
   *        id: 'node',
   *        label: 'node',
   *        address: 'cq',
   *        x: 200,
   *        y: 150,
   *        style: {
   *          fill: 'blue',
   *        },
   *      };
   *
   *  flags:
   *    type: String
   *    desc: flags defined what kind of action does this have to perform
   *    sample: '-create-combo',
   *    operations:
   *      1. '-update' flag can be used to update properties in Node.
   *      2. '-state' flag can be used to update state of the Node.
   *      3. '-clearState' flag can be used clear state of Node.
   *
   *---------------------------------------- | -------------------------------------------|
   *
   * @return
   *  null
   */
  setVertexValue(graphName, payload, flags = '') {
    const graph = GraphOps.graph(graphName);
    if (graph && graph !== null) {
      // fetch options
      const options = flagReader(flags);
      // handle payload Input
      let payloads;
      if (!Array.isArray(payload)) {
        payloads = [payload];
      } else {
        payloads = payload;
      }

      if (options.update) {
        payloads.forEach((payloadItem) => {
          const nodeItem = this.getVertexValue(graphName, payloadItem.item);

          // if nodeItem doesn't existed or if update value is not in object format
          if (!nodeItem) {
            console.warn('noteItem not Found!');
          }

          if (nodeItem) {
            graph.updateItem(nodeItem, payloadItem.value);
          }
        });
      } else if (options.state) {
        payloads.forEach((payloadItem) => {
          const nodeItem = this.getVertexValue(graphName, payloadItem.item);

          // if nodeItem doesn't existed or if update value is not in object format
          if (!nodeItem || (typeof payloadItem.value === 'object')) {
            console.warn('noteItem not Found! or Values are incorrect');
          }

          if (nodeItem) {
            if (options.clearState) {
              graph.clearItemStates(nodeItem, payloadItem.state);
            } else {
              graph.setItemState(nodeItem, payloadItem.state, payloadItem.value);
            }
            graph.paint();
          }
        });
      }
    }
    console.warn('Graph Does not Exists');
    return null;
  },

  /**
   * @fn setEdgeValue
   * @params
   *---------------------------------------- | -------------------------------------------|
   *
   * graphName:
   *    type: String.
   *    desc: Graph Name which we want to use.
   *    sample:'explore'.
   *
   *  payload:
   *    type: edgeId/Object
   *    desc: Any thing we pass through the function
   *    sample:-
   *      const model = {
   *        source: userId,
   *        target: item.id,
   *        label: 'has noted',
   *        style: {
   *          endArrow: true,
   *        },
   *      };
   *
   *  flags:
   *    type: String
   *    desc: flags defined what kind of action does this have to perform
   *    sample: '-create-combo',
   *    operations:
   *      1. '-update' flag can be used to update properties in Node.
   *      2. '-state' flag can be used to update state of the Node.
   *      3. '-clearState' flag can be used clear state of Node.
   *
   *---------------------------------------- | -------------------------------------------|
   *
   * @return
   *  null
   */
  setEdgeValue(graphName, payload, flags = '') {
    // fetch graph Instance
    const graph = GraphOps.graph(graphName);

    if (graph && graph !== null) {
      // handling options
      const options = flagReader(flags);

      // handle payload Input
      let payloads;
      if (!Array.isArray(payload)) {
        payloads = [payload];
      } else {
        payloads = payload;
      }

      /**
       * payloads is a array of datatype `any[]`
       * contains number of edges and perform operations
       * depends on flags provide
       *
       * Mostly used flags here are '-update', '-remove', '-state'
       * */

      if (options.update) {
        payloads.forEach((payloadItem) => {
          // get edge
          const edgeItem = this.getEdgeValue(graphName, payloadItem.item);

          if (!edgeItem) {
            console.warn('Edge not Found');
          }

          if (edgeItem) {
            graph.updateItem(edgeItem, payloadItem.value);
          }
        });
      } else if (options.state) {
        payloads.forEach((payloadItem) => {
          // get Edge
          const edgeItem = this.getEdgeValue(graphName, payloadItem.item);

          if (!edgeItem) {
            console.warn('Edge not Found');
          }

          if (edgeItem) {
            if (options.clearState) {
              graph.clearItemStates(edgeItem, payloadItem.state);
            } else {
              graph.setItemState(edgeItem, payloadItem.state, payloadItem.value);
            }
            graph.paint();
          }
        });
      }
    }
    if (!graph) {
      console.warn('GraphEngine: graph is not exists in setEdgeValue');
    }
  },

  /**
   * @fn removeVertex
   * @params
   *---------------------------------------- | -------------------------------------------|
   *
   * graphName:
   *    type: String.
   *    desc: Graph Name which we want to use.
   *    sample:'explore'.
   *
   *  payload:
   *    type: nodeId/Object
   *    desc: Any thing we pass through the function
   *    sample:-
   *      const model = {
   *        id: 'node',
   *        label: 'node',
   *        address: 'cq',
   *        x: 200,
   *        y: 150,
   *        style: {
   *          fill: 'blue',
   *        },
   *      };
   *
   *  flags:
   *    type: String
   *    desc: flags defined what kind of action does this have to perform
   *    sample: '-create-combo',
   *
   *---------------------------------------- | -------------------------------------------|
   *
   * @return
   *  Nodes:
   *    type: [Object]
   *    desc: Can return a single delete statement or a array of statement of deleted Notes.
   *    (See Node Model samples and Description in Graph Docs)
   */
  removeVertex(graphName, payload, flags = '') {
    /**
     * * payload could be either NodeItem or 'Id'
     */
    const graph = GraphOps.graph(graphName);

    if (graph && graph !== null) {
      // get options
      const options = flagReader(flags);

      // handle Payloads
      let payloads;
      if (!Array.isArray(payload)) {
        payloads = [payload];
      } else {
        payloads = payload;
      }

      // console.log('GraphEngine: Payloads', payloads);
      const Nodes = [];
      payloads.forEach((payloadItem) => {
        const removeNodeItem = this.getVertexValue(graphName, payloadItem);

        if (removeNodeItem) {
          Nodes.push({ message: 'Item Deleted Successfully', ...removeNodeItem });
          graph.removeItem(removeNodeItem);
        } else {
          Nodes.push({ message: 'Node Item does not exists', ...{} });
        }
      });
      return Nodes;
    }
    console.warn('GraphEngine: Graph Does not exists in remove Vertex function', graph, graphName);
    return null;
  },

  removeEdge(graphName, payload, flags = '') {
    // get graph
    const graph = GraphOps.graph(graphName);

    if (graph && graph !== null) {
      // fetch options
      const options = this.flagReader(flags);

      // handle Payloads
      let payloads;
      if (!Array.isArray) {
        payloads = [payload];
      } else {
        payloads = payload;
      }

      const Edges = [];
      payloads.forEach((payloadItem) => {
        const removeEdgeItem = this.getVertexValue(graphName, payloadItem);

        if (removeEdgeItem) {
          Edges.push({ message: 'Item Deleted Successfully', removeEdgeItem });
          graph.removeItem(removeEdgeItem);
        } else {
          Edges.push({ message: 'Edge not found' });
        }
        return Edges;
      });
    }
    if (!graph) {
      console.warn('GraphEngine: graph is not working in removeEdge');
    }
    return null;
  },

  /**
   * @fn getNeighboursValue
   * @params
   *---------------------------------------- | -------------------------------------------|
   *
   * graphName:
   *    type: String.
   *    desc: Graph Name which we want to use.
   *    sample:'explore'.
   *
   *  payload:
   *    type: nodeId/Object
   *    desc: Any thing we pass through the function
   *    sample:-
   *      const model = {
   *        id: 'node',
   *        label: 'node',
   *        address: 'cq',
   *        x: 200,
   *        y: 150,
   *        style: {
   *          fill: 'blue',
   *        },
   *      };
   *
   *  flags:
   *    type: String
   *    desc: flags defined what kind of action does this have to perform
   *    sample: '-create-combo',
   *
   *---------------------------------------- | -------------------------------------------|
   *
   * @return
   *  Nodes:
   *    type: [Object]
   *    desc: Can return a single delete statement or a array of statement of deleted Notes.
   *    (See Node Model samples and Description in Graph Docs)
   */
  getNeighborsValue(graphName, payload, flags = '') {
    /**
     * * payload Structure:
     * {
     *  item: NodeId | NodeItem,
     *  neighborType?: 'Nodes' | 'Edges', default: 'Nodes'
     *  type?: 'source' || 'target' (if you don't define this property then you will get all neighbors of that entity)
     * }
     */

    // fetch Graph
    const graph = GraphOps.graph(graphName);

    if (graph && graph !== null) {
      let neighbors = [];
      let neighborType = 'Nodes';

      if (payload.neighborType) {
        neighborType = payload.neighborType;
      }

      const nodeItem = this.getVertexValue(graphName, payload.item);

      if (!nodeItem) {
        console.warn('GraphEngine: nodeItem not found at getNeighbour function');
        return null;
      }

      // If you need node type neighbors of a node
      if (neighborType === 'Nodes') {
      // if type is given 'source' or 'target'
        if (payload.type) {
          neighbors = graph.getNeighbors(nodeItem, payload.type);
        } else {
        // else return all the neighbors
          neighbors = graph.getNeighbors(nodeItem);
        }
      } else if (neighborType === 'Edges') {
      // If you need node type neighbors of a node
        if (payload.type) {
          if (payload.type === 'source') {
            neighbors = nodeItem.getInEdges(nodeItem);
          } else if (payload.type === 'target') {
            neighbors = nodeItem.getOutEdges(nodeItem);
          } else {
            neighbors = nodeItem.getEdges(nodeItem);
          }
        } else {
          neighbors = nodeItem.getEdges(nodeItem);
        }
      }

      return neighbors;
    }
    if (!graph) {
      console.warn('GraphEngine: Graph is not available in getNeighbours');
    }
    return null;
  },

  adjacentVertex(graphName, payload, flags = '') {
    /**
     * interface payload {
     *    vertex1: object,
     *    vertex2: object,
     * }
    */

    // get values from payload
    const { nodeX, nodeY } = payload;
    const instance = GraphOps.graphInstance(graphName);
    if (graphReactor[instance] && graphReactor[instance] !== null) {
      const commonEdge = graphReactor[instance].find('edge', (edge) => {
        const edgeModel = edge.get('model');
        if (edgeModel.source === nodeX && edgeModel.target === nodeY) {
          return edge;
        } return null;
      });
      if (commonEdge) {
        return true;
      } return false;
    }
    return null;
  },

  // adjacent, // tests whether there is an edge from the vertex x to the vertex y,
  // neighbors, // lists all vertices y such that there is an edge from the vertex x to the vertex y,
  // add_vertex, // adds the vertex x, if it is not there,
  // remove_vertex: remove the vertex x, from graph,
  // add_edge, // adds the edge from the vertex x to the vertex y, if it is not there,
  // remove_edge, // removes the edge from the vertex x to the vertex y, if it is there,
  // set_vertex_value, // sets the value associated with the vertex x to v.
  // get_vertex_value, // get the vertex values with the vertex id/vertex name/instance
  // get_edge_value, // returns the value associated with the edge : edge
  // set_edge_value, // sets the value associated with the edge (x, y) to v.
};

/**
 * Input of vertex: index value | object value | instance of G6 Node | IVertex
 * adjacent:
 *    returns: list of Edges if true : false
 *    action:
 *      adjacent(v1,v2)
 *      1. check if there is the direct betwen v1,v2
 *
 *      adjacent(v1,v2, length = 3)
 *      1. check if there is the betwen v1,v2  with hop lenght less than equal to 3
 *
 *      adjacent(v1,v2, length = 3, filterEdge = ['jumps', 'noted'])
 *      1. check if there is the betwen v1,v2  with hop lenght less than equal to 3, only considering edges with type 'jumps', 'noted'
 *
 *      adjacent(v1,v2, length = 3, blacklistEdge = ['jumps', 'noted'])
 *      1. check if there is the betwen v1,v2  with hop lenght less than equal to 3, only considering edges NOT 'jumps', 'noted'
 */

/**
 * neighbors:
 *    action:
 *      neighbors(v)
 *      1. all neighbors (un directed)
 *
 *      neighbors(v, type = 'in/out')
 *      1. all neighbors (but directional)
 *
 *      neighbors(v, filterEdge = ['edgeType1', 'edgeType2'])
 *      1. only considering edges with type 'edgeType1', 'edgeType2'
 *
 *      neighbors(v, combo = 'comboName')
 *        1. only restricting to a combo
 */

/**
 * add_vertex:
 *    action:
 *      add_vertex(v)
 *      add_vertex([v,v1,v2,v3])
 *      add_vertex([v1,v2,v3], sharedProperties)
 */

/**
 * add_edge:
 *    action:
 *      add_edge(v1,v2)
 *      add_edge(v1, [v,v1,v2,v3])
 *      add_vertex(v1, [v1,v2,v3], sharedProperties)
 */

/**
 * remove_edge:
 *    action:
 *      remove_edge(v1,v2)
 */

/**
 * set_vertex_value:
 *    action:
 *      - add some new details to a Node(s)
 *      - update existing properties/values inside a Node(s)
 *      - remove a set of properties
 */
//  set_vertex_value(graphName, nodes = [{node, payloads, flag}]) {
//   if(nodes.length > 0)
//

/**
 * get_edge_value:
 *    action:
 *      - get_edge_value(edge)
 *      - get_edge_value(v1,v2) === get_edge_value( adjacent(v1,v2) )
 */

/**
 * set_edge_value:
 *    action:
 *      - add some new details to a Edge(s)
 *      - update existing properties/values inside a Edge(s)
 *      - remove a set of properties
 */
