function SendAllThistoNotes(event) {
  const targetEm = event.target;
  const codeEd = targetEm.parentElement.children[0].innerText;
  const code = codeEd.replace(/\$ed(.*?)ed\$/g, '$1');
  // const text = target.code.innerText;

  const runCodeEvent = new CustomEvent('runCode', { detail: code, bubbles: true });
  targetEm.dispatchEvent(runCodeEvent);
}

function nodeFocusFromNote(event) {
  const targetEm = event.target;
  const runNodeEvent = new CustomEvent('runNode', { detail: { text: event.target.innerText, id: event.target.getAttribute('nodeid') }, bubbles: true });
  targetEm.dispatchEvent(runNodeEvent);
}

export { SendAllThistoNotes, nodeFocusFromNote };
