export default {
  specialRoutes: [
    'Auth',
    'embed',
    'Reset-Password',
    'Forgot-Password-Request',
    'Forgot-Password',
    'OAuth-Request',
    'NotFound',
  ],
  featureBlockedRoutes: [
    'MobileView',
  ],
};
