const currentDispatchers = [];
let store;
const mountedWidgets = [];

async function useDispatcher(dispatchername) {
  const excludeDispatch = store.state.excludeCallList;

  const {
    word, type, qid, lang,
  } = store.state.current_selected_entity;

  if (dispatchername === 'twitterDetails' && !excludeDispatch.includes('twitterDetails')) {
    const wordObj = { topic: word, count: 10 };
    await store.dispatch(dispatchername, wordObj);
  } else if (dispatchername === 'wikiGraphAction' && !excludeDispatch.includes('wikiGraphAction')) {
    const wordObj = {
      word,
      type: 'string',
      lang: store.state.locale.currentLocale,
      graph_type: 'force',
    };
    await store.dispatch(dispatchername, wordObj);
  } else if (dispatchername === 'quests/questGraphAction' && !excludeDispatch.includes('quests/questGraphAction')) {
    const wordObj2 = {
      word,
      type: 'string',
      lang: store.state.locale.currentLocale,
      graph_type: 'dendro',
    };
    await store.dispatch(dispatchername, wordObj2);
  } else if (dispatchername === 'wikiSnippet' && !excludeDispatch.includes('wikiSnippet')) {
    if (type === 'entity' && qid) {
      await store.dispatch(dispatchername, { word: qid, type: 'entity', lang });
    } else {
      await store.dispatch(dispatchername, { word, type: 'string', lang });
    }
  } else if (dispatchername === 'searchFromOpenAi' && !excludeDispatch.includes('searchFromOpenAi')) {
    // Run this only if user has allowed to connect its eddie with main search
    if (localStorage.getItem('is_eddie_search') === 'true') {
      await store.dispatch(dispatchername, word);
    }
  } else if (!excludeDispatch.includes(dispatchername)) {
    await store.dispatch(dispatchername, word);
  }
}

async function singleDispatcher(dispatchername) {
  currentDispatchers.push(dispatchername);
  const isCurrentSelectedEntity = Object.keys(store.state.current_selected_entity).length > 0;
  if (isCurrentSelectedEntity && !mountedWidgets.includes(dispatchername)) {
    console.log('xvf', store.state.current_selected_entity);
    useDispatcher(dispatchername);
    mountedWidgets.push(dispatchername);
  }
  store.subscribe(async (mutation) => {
    if (mutation.type === 'SET_CURRENT_ENTITY' && store.state.current_selected_entity) {
      useDispatcher(dispatchername);
    }
  });
}

async function excludeDispatcher(searchWord, excludeDispatchername) {
  const wikiSnippetObj = { word: searchWord, type: 'word' };
  const twitterObj = { topic: searchWord, count: 10 };
  const wikiGraphActionObj = {
    word: searchWord,
    type: 'string',
    lang: store.state.locale.currentLocale,
    graph_type: 'force',
  };
  const questGraphPayload = {
    word: searchWord,
    type: 'string',
    lang: store.state.locale.currentLocale,
    graph_type: 'dendro',
  };
  if (!excludeDispatchername.includes('wikiWordFetch')) {
    store.dispatch('wikiWordFetch', searchWord);
  }
  if (!excludeDispatchername.includes('wikiSnippet')) {
    store.dispatch('wikiSnippet', wikiSnippetObj);
  }
  if (!excludeDispatchername.includes('twitterDetails')) {
    store.dispatch('twitterDetails', twitterObj);
  }
  if (!excludeDispatchername.includes('wikiGraphAction')) {
    store.dispatch('wikiGraphAction', wikiGraphActionObj);
  }
  if (!excludeDispatchername.includes('quests/questGraphAction')) {
    store.dispatch('quests/questGraphAction', questGraphPayload);
  }
  if (!excludeDispatchername.includes('fetchExploreInformation')) {
    store.dispatch('fetchExploreInformation', searchWord);
  }
  if (!excludeDispatchername.includes('fetchThreeDModel')) {
    store.dispatch('fetchThreeDModel', searchWord);
  }
  if (!excludeDispatchername.includes('gmodule/exploreGraphFetch')) {
    store.dispatch('gmodule/exploreGraphFetch', searchWord);
  }
  if (!excludeDispatchername.includes('fetchExploreDescription')) {
    store.dispatch('fetchExploreDescription', searchWord);
  }
  if (!excludeDispatchername.includes('youtubeSearchByKeyword')) {
    store.dispatch('youtubeSearchByKeyword', searchWord);
  }
  if (!excludeDispatchername.includes('thirdPartyIntegrations/getJobs')) {
    store.dispatch('thirdPartyIntegrations/getJobs', searchWord);
  }
}

export default {
  install(app, options) {
    // configure the app
    if (!options || !options.store) {
      throw new Error('Please initialise plugin with a Vuex store.');
    }
    store = options.store;
    const appInstance = app;
    appInstance.prototype.$highway = {
      singleDispatcher, excludeDispatcher,
    };
  },
};
