<template>
  <div class="auth-page" id="auth-page">
    <div class="auth-page__col-1">
      <carousel-3d :perspective="60" :space="200" :display="3" :border="0">
        <slide
          v-for="(slide, i) in slides"
          :index="i"
          :key="i"
          @click="onSlideMove"
        >
          <div class="introduction">
            <h3 class="introduction__title">
              <span class="introduction__title--primary"
                >{{ slide.title.primary }}
              </span>
              <span class="introduction__title--secondary">{{
                slide.title.secondary
              }}</span>
            </h3>
            <p class="introduction__desc">{{ slide.description }}</p>
          </div>
          <img :src="slide.img" :alt="slide" />
        </slide>
      </carousel-3d>
      <div class="tj-logo">
        <img :src="require('../assets/images/logo.svg')" alt="" />
      </div>
    </div>
    <div class="auth-page__col-2">
      <div class="auth-box">
        <div class="header">
          <span class="auth-label">Do you want to Login ?</span>
          <button
            class="ed-btn__blim auth-btn--sm"
            @click="changeRedirect('login')"
          >
            Login
          </button>
        </div>
        <div class="other-methods-box">
          <h3 class="other-methods__header">
            Reset Password Here
          </h3>
        </div>
        <!-- ----------------------IF PARAMETER IS SIGNUP ------------------------------- -->
        <!-------------------------- IF PARAMETER IS LOGIN---------------- -->
        <div class="auth">
          <form @submit.prevent="onSubmit" class="auth__form">
            <!-- SIGNUP : PASSWORD GROUP -->
            <div class="form-group">
              <span class="auth__label">New Password</span>
              <div class="auth__password-box">
                <input
                  :type="showPassword ? 'text' : 'password'"
                  class="auth__password-box__field"
                  placeholder="***********"
                  v-model="password"
                  v-focus="fieldErrors.password"
                  @focus="fieldErrors.password = true"
                  @blur="fieldErrors.password = false"
                />
                <i
                  class="auth__password-box__icon"
                  :title="showPassword ? 'Hide Password' : 'Show Password'"
                  :class="showPassword ? 'bx bx-hide' : 'bx bx-show'"
                  @click="toggleShowPassword"
                ></i>
              </div>
              <!-- LOGIN: password[ERROR]: Show when element is focused -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.password.error }"
                v-if="!$v.password.required && fieldErrors.password"
                >Password is required
              </span>
              <!-- LOGIN: password[ERROR]: Show when submit button is pressed -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.password.error }"
                v-if="
                  !fieldErrors.password &&
                  submitErrors.password &&
                  !$v.password.required
                "
                >Password is required</span
              >
              <!-- LOGIN: password[ERROR]: Show when there is a invalidation -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.password.error }"
                v-if="$v.password.required && !$v.password.minLength"
                >minimum 8 Characters Long
              </span>
            </div>
            <!-- SIGNUP : CONFIRM PASSWORD GROUP -->
            <div class="form-group">
              <span class="auth__label">Confirm Password</span>
              <input
                type="password"
                class="auth__input"
                placeholder="***********"
                v-model="confirmPassword"
                v-focus="fieldErrors.confirmPassword"
                @focus="fieldErrors.confirmPassword = true"
                @blur="fieldErrors.confirmPassword = false"
              />
              <!-- LOGIN: confirmPassword[ERROR]: Show when element is focused -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.confirmPassword.error }"
                v-if="
                  !$v.confirmPassword.required && fieldErrors.confirmPassword
                "
                >Confirm Password is required
              </span>
              <!-- LOGIN: confirmPassword[ERROR]: Show when submit button is pressed -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.confirmPassword.error }"
                v-if="
                  !fieldErrors.confirmPassword &&
                  submitErrors.confirmPassword &&
                  !$v.confirmPassword.required
                "
                >Confirm Password is required</span
              >
              <!-- LOGIN: confirmPassword[ERROR]: Show when there is invalidation -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.password.error }"
                v-if="
                  $v.confirmPassword.required &&
                  !$v.confirmPassword.sameAsPassword
                "
              >
                Should be same as Password
              </span>
            </div>
            <div class="pageError" v-if="this.pageError.length > 0">
              {{ this.pageError }}
            </div>
            <!-- LOGIN : SUBMIT BUTTON GROUP -->
            <div class="form-group">
              <button
                class="auth-btn auth-btn--lg"
                :disabled="isLoading"
                :style="{
                  opacity: isLoading ? 0.7 : 1,
                  cursor: isLoading ? 'default' : 'pointer',
                }"
              >
                Login
                <span
                  :style="{
                    opacity: isLoading ? 1 : 0,
                  }"
                  class="spinner-border spinner-border-sm"
                ></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';
import {
  required, sameAs, email, minLength,
} from 'vuelidate/lib/validators';
import { focus } from 'vue-focus';

const usernameValidator = (value) => {
  const reg = /^[A-Za-z][A-Za-z0-9_.-]{2,30}$/;
  return reg.test(value);
};

export default {
  name: 'auth-page',
  metaInfo: {
    title: 'Login / Signup',
  },
  directives: {
    focus,
  },
  data() {
    return {
      mode: 'login',
      otherSignupMethods: false,
      max: 100,
      showPassword: false,
      slide: 5,
      firstName: '',
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      errors: [],
      isLoading: false,
      pageError: '',
      fieldErrors: {
        firstName: false,
        username: false,
        email: false,
        password: false,
        confirmPassword: false,
      },
      submitErrors: {
        firstName: false,
        username: false,
        email: false,
        password: false,
        confirmPassword: false,
      },
    };
  },
  components: {
    Carousel3d,
    Slide,
  },
  methods: {
    cleanUp() {
      this.password = '';
      this.confirmPassword = '';
    },
    onSubmit() {
      const { passwordRequestKey } = this.$route.params;
      this.$v.$touch();
      const fields = ['confirmPassword', 'password'];
      // this.clearFieldErrors();
      if (!this.$v.password.$invalid && !this.$v.confirmPassword.$invalid) {
        this.toggleLoading();
        this.$store
          .dispatch('auth/resetPassword', {
            resetPasswordRequest: passwordRequestKey,
            password: this.password,
            confirmPassword: this.confirmPassword,
          })
          .then((data) => {
            this.toggleLoading();
            this.logPageError(data.data.msg);
            setTimeout(() => {
              this.cleanUp();
              this.successRedirect();
            }, 2000);
          })
          .catch((err) => {
            this.toggleLoading();
            this.logPageError(err.response.data.message);
          });
      } else {
        // this.logPageError("!Fields can't be empty");
        const invalidFields = Object.keys(this.$v.$params).filter(
          (fieldName) => this.$v[fieldName].$invalid,
        );
        invalidFields.forEach((each) => {
          if (fields.includes(each)) {
            this.submitErrors[each] = true;
            setTimeout(() => {
              this.submitErrors[each] = false;
            }, 2000);
          }
        });
      }
    },
    successRedirect() {
      this.$router.push(this.$route.query.redirectTo || { name: 'Auth', params: { action: 'Login' } });
    },
    toggleLoading() {
      this.isLoading = !this.isLoading;
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    changeRedirect(action) {
      this.cleanUp();
      this.mode = action;
      const redirectTo = this.$route.query.redirectTo || {
        name: 'Auth',
        params: { action },
      };
      this.$router.push(redirectTo);
    },
    logPageError(err) {
      this.pageError = err;
    },
    onSlideMove() {
      document.querySelector('.carousel-3d-slide.current');
    },
    focus(field) {
      this.fieldErrors[field] = true;
    },
  },
  mounted() {
    const { action } = this.$route.params;
    if (action === 'signup') {
      this.mode = 'signup';
    } else {
      this.mode = 'login';
    }
  },
  validations: {
    firstName: {
      required,
      minLength: minLength(2),
    },
    username: {
      required,
      usernameValidator,
    },
    password: {
      required,
      minLength: minLength(8),
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs('password'),
    },
    email: {
      required,
      email,
    },
  },
};
</script>
<style lang="scss">
@import "../assets/scss/abstracts/variables";

.carousel-3d-container {
  height: auto !important;
  padding-top: 30px;
  user-select: none;
  .carousel-3d-slider {
    height: 40rem !important;
  }
  .carousel-3d-slide {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 58rem !important;
    height: 40rem !important;
    background: none;
    border: none;
    // box-shadow: 0 0 30px 15px rgb(0 0 0 / 20%);
    .title {
      font-size: 1.375rem;
    }
  }
  .right-1 {
    transform: translateX(250px) translateZ(25px) rotateY(-60deg) !important;
  }
  .left-1 {
    transform: translateX(-800px) translateZ(80px) rotateY(60deg) !important;
  }
  .carousel-3d-slide.current {
    transform: translateX(-285px) !important;
    // width: 576px !important;
    .introduction {
      display: block;
      opacity: 1;
      transition: all 0.8s ease-in-out;
    }
  }
  img {
    width: 60% !important;
    // height: 100%;
    border-radius: 0.3rem;
    border: none;
    box-shadow: 0 0 30px 15px rgba(0, 0, 0, 0.2);
  }
}

.introduction {
  opacity: 0;
  font-size: 0.8rem;
  text-align: center;
  width: 50%;
  position: absolute;
  top: 1.5rem;
  &__title {
    &--primary {
      color: var(--gray);
    }
    &--secondary {
      color: var(--accentPink);
    }
  }
}

.pageError {
  color: var(--accentPink);
  margin-bottom: 0.2em;
  font-size: 0.9em;
}
</style>
