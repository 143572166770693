import routeMapping from '../config/routeMapping';

function notSpecialRoute(routeName, view = null) {
  if (view === 'mobile') {
    return !routeMapping.featureBlockedRoutes.includes(routeName);
  }
  return !routeMapping.specialRoutes.includes(routeName);
}

export default notSpecialRoute;
