<template lang="">
    <div class="ed-popup" ref="ed-popup" v-click-outside="close">
        <span class="ed-popup__tip"></span>
        <div class="ed-popup__body">
            <h3 class="ed-popup__title"></h3>
            <p class="ed-popup__text"></p>
        </div>
        <i title="close" class="box-icon ed-popup__close bx bx-x" @click="close"/>
    </div>
</template>
<script>
import clickOutside from '@/directives/click-outside';

export default {
  name: 'ed-tooltip',
  directives: {
    clickOutside,
  },
  props: {
    wordMeaningPopup: {
      type: Object,
      required: true,
    },
    toggleWordMeaningPopup: {
      type: Function,
      required: true,
    },
  },
  watch: {
    wordMeaningPopup: {
      immediate: true,
      deep: true,
      handler() {
      // do your stuff
        if (this.wordMeaningPopup.isActive) {
          this.edvantaPopup(this.wordMeaningPopup.title, this.wordMeaningPopup.info);
        }
      },
    },
  },
  methods: {
    close() {
      this.$refs['ed-popup'].style.display = 'none';
      this.toggleWordMeaningPopup('close');
    },
    checkShow() {
      if (
        this.wordMeaningPopup.title
        && this.wordMeaningPopup.info
        && this.wordMeaningPopup.isActive
      ) {
        return true;
      }
      return false;
    },
    resetTip(selector) {
      /*
        fn() => resetTip

        desc
          get selector and remove all the classes that are added there

        returns
          null
      */
      const popup = document.querySelector(selector);
      if (popup.children[0].classList.contains('ed-popup__tip--top')) {
        popup.children[0].classList.remove('ed-popup__tip--top');
      }
      if (popup.children[0].classList.contains('ed-popup__tip--left')) {
        popup.children[0].classList.remove('ed-popup__tip--left');
      }
      if (popup.children[0].classList.contains('ed-popup__tip--right')) {
        popup.children[0].classList.remove('ed-popup__tip--right');
      }
      if (popup.children[0].classList.contains('ed-popup__tip--bottom')) {
        popup.children[0].classList.remove('ed-popup__tip--bottom');
      }
    },
    getClasses(direction, initClass) {
      const classList = [];

      /*
        fn() => getClasses

        desc
          return classes for as per the direction

        returns
          classList: list of classes used in popup
      */
      if (direction === 'bottom-left') {
        classList.push(`${initClass}__tip--top`);
        classList.push(`${initClass}__tip--left`);
      } else if (direction === 'bottom-right') {
        classList.push(`${initClass}__tip--top`);
        classList.push(`${initClass}__tip--right`);
      } else if (direction === 'top-left') {
        classList.push(`${initClass}__tip--bottom`);
        classList.push(`${initClass}__tip--left`);
      } else {
        classList.push(`${initClass}__tip--bottom`);
        classList.push(`${initClass}__tip--right`);
      }
      return classList;
    },
    getPositions(top, left, popupWidth, popupHeight) {
      const position = {};
      if (top < popupHeight) {
        if (document.body.clientWidth - left > popupWidth) {
          // popup: bottom-left
          // tip: top left
          position.direction = 'bottom-left';
          position.x = left + 100;
          position.y = popupHeight - 50;
        } else {
          // popup: bottom-right
          // tip: top-right
          position.direction = 'bottom-right';
          position.x = left - (popupWidth * 90) / 100;
          position.y = top + 30;
        }
      } else {
        // popup: top-left
        // tip: bottom-right
        // eslint-disable-next-line no-lonely-if
        if (document.body.clientWidth - left > popupWidth) {
          position.direction = 'top-left';
          position.x = left;
          position.y = top - popupHeight - 20;
        } else {
          position.direction = 'top-right';
          position.x = left - (popupWidth * 90) / 100;
          position.y = top - popupHeight - 20;
        }
      }
      return position;
    },
    edvantaPopup(title, info) {
      const selection = document.getSelection();
      const oRange = selection.getRangeAt(0); // get the text range
      const oRect = oRange.getBoundingClientRect();

      const popup = this.$refs['ed-popup'];

      popup.children[1].children[0].innerHTML = title;
      popup.children[1].children[1].innerHTML = info;
      popup.style.display = 'block';
      const position = this.getPositions(
        oRect.top,
        oRect.left,
        popup.clientWidth,
        popup.clientHeight,
      );

      popup.style.top = `${position.y}px`;
      popup.style.left = `${position.x}px`;
      this.resetTip('.ed-popup');
      this.getClasses(position.direction, 'ed-popup').forEach((eachClass) => {
        popup.children[0].classList.add(eachClass);
      });
    },
  },
};
</script>
<style lang="scss">
.ed-popup {
  background-color: var(--white);
  color: var(--edvantaBlue);
  font-family: var(--fontFamily);
  /* border: 1px solid #adadad; */
  box-shadow: 0px 0px 0px 4px var(--white) inset, 0px 0px 0px 5px var(--color-grey-2) inset;
  position: fixed;
  display: none;
  padding: 2rem 2.5rem;
  max-width: 430px;
//   text-align: center;
  z-index: 10;
  &__tip {
    border: solid;
    border-color: var(--white) transparent;
    content: "";
    position: absolute;
    &--top {
      top: -10px;
      border-width: 0px 6px 12px 6px;
    }
    &--left {
      left: 5%;
    }
    &--bottom {
      border-width: 12px 6px 0 6px;
      bottom: -10px;
    }
    &--right {
      left: 95%;
    }
  }
  &__body {
    display: flex;
    align-items: baseline;
  }
    &__title {
        font-size: 1.1rem;
        margin-top: .1rem;
        &::after {
            content: ': ';
            margin-right: .5rem;
        }
    }
    &__text {
        margin: 0;
        // white-space: nowrap;
    }
  &__close {
    position: absolute;
    top: 4px;
    right: 4px;
  }
}
</style>
