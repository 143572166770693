import startToEnd from '@/utils/startToEnd';

function uniqueIdGenerate() {
  // Generate random id for node
  return Math.random().toString(36).substr(2, 9);
}

export default function smartQueryModePayload(payload) {
  // Convet all of em into one line string
  const baseQuery = payload.replace(/(\r\n|\n|\r)/gm, ' ');

  const globalOperation = {};
  // For Create hook................................................................

  if (baseQuery.includes('create(')) {
    const createQuery = startToEnd({
      str: baseQuery,
      start: 'create(',
      end: ')',
    });

    const mainQuery = createQuery[0].split('.');

    const nodes = [];
    const edges = [];
    const combos = [];
    mainQuery.forEach((edQuery) => {
      const sanitizedQuery = edQuery.split(/\|/g);
      sanitizedQuery.forEach((subItem, subIndex) => {
        if ((subIndex + 1) % 2 === 0) {
          const relationshipLabel = subItem.trim();

          // If both source and target contains ,
          if (sanitizedQuery[subIndex - 1].includes(',') && sanitizedQuery[subIndex + 1].includes(',')) {
            let sourceComboId = null;
            let targetComboId = null;
            let sourceNodes = sanitizedQuery[subIndex - 1].split(',');
            let targetNodes = sanitizedQuery[subIndex + 1].split(',');

            if (sanitizedQuery[subIndex - 1].includes('[' && ']')) {
              const splitted = sanitizedQuery[subIndex - 1].split('[');

              sourceComboId = splitted[0].trim();
              sourceNodes = splitted[1].replace(']', '').split(',');
            }
            if (sanitizedQuery[subIndex + 1].includes('[' && ']')) {
              const splitted = sanitizedQuery[subIndex + 1].split('[');
              targetComboId = splitted[0].trim();
              targetNodes = splitted[1].replace(']', '').split(',');
            }

            combos.push({
              id: targetComboId,
              label: targetComboId,
            });
            combos.push({
              id: sourceComboId,
              label: sourceComboId,
            });
            // else {
            //   sourceNodes = sanitizedQuery[subIndex - 1].split(',');
            //   targetNodes = sanitizedQuery[subIndex + 1].split(',');
            // }

            targetNodes.forEach((targetNode) => {
              if (targetComboId === null) {
                nodes.push({
                  id: targetNode.trim(),
                  label: targetNode.trim(),
                });
              } else {
                nodes.push({
                  id: targetNode.trim(),
                  label: targetNode.trim(),
                  comboId: targetComboId,
                });
              }
            });

            sourceNodes.forEach((sourceNode) => {
              if (sourceComboId === null) {
                nodes.push({
                  id: sourceNode.trim(),
                  label: sourceNode.trim(),
                });
              } else {
                nodes.push({
                  id: sourceNode.trim(),
                  label: sourceNode.trim(),
                  comboId: sourceComboId,
                });
              }

              targetNodes.forEach((targetNode) => {
                if (relationshipLabel.includes('>')) {
                  edges.push({
                    source: sourceNode.trim(),
                    target: targetNode.trim(),
                    label: relationshipLabel.replace('>', ''),
                    style: {
                      endArrow: true,
                    },
                  });
                } else {
                  edges.push({
                    source: sourceNode.trim(),
                    target: targetNode.trim(),
                    label: relationshipLabel,
                  });
                }
              });
            });
          } else if (sanitizedQuery[subIndex - 1].includes(',') && !sanitizedQuery[subIndex + 1].includes(',')) {
            let sourceComboId = null;
            let targetComboId = null;
            let sourceNodes = sanitizedQuery[subIndex - 1].split(',');
            let targetNode = sanitizedQuery[subIndex + 1].trim();

            if (sanitizedQuery[subIndex - 1].includes('[' && ']')) {
              const splitted = sanitizedQuery[subIndex - 1].split('[');

              sourceComboId = splitted[0].trim();

              sourceNodes = splitted[1].replace(']', '').split(',');
            }
            if (sanitizedQuery[subIndex + 1].includes('[' && ']')) {
              const splitted = sanitizedQuery[subIndex + 1].split('[');
              targetComboId = splitted[0].trim();
              targetNode = splitted[1].replace(']', '');
            }
            combos.push({
              id: targetComboId,
              label: targetComboId,
            });
            combos.push({
              id: sourceComboId,
              label: sourceComboId,
            });
            sourceNodes.forEach((sourceNode) => {
              if (sourceComboId === null) {
                nodes.push({
                  id: sourceNode.trim(),
                  label: sourceNode.trim(),
                });
              } else {
                nodes.push({
                  id: sourceNode.trim(),
                  label: sourceNode.trim(),
                  comboId: sourceComboId,
                });
              }
              if (targetComboId === null) {
                nodes.push({
                  id: targetNode,
                  label: targetNode,
                });
              } else {
                nodes.push({
                  id: targetNode,
                  label: targetNode,
                  comboId: targetComboId,
                });
              }
              if (relationshipLabel.includes('>')) {
                edges.push({
                  source: sourceNode.trim(),
                  target: targetNode,
                  label: relationshipLabel.replace('>', ''),
                  style: {
                    endArrow: true,
                  },
                });
              } else {
                edges.push({
                  source: sourceNode.trim(),
                  target: targetNode,
                  label: relationshipLabel,
                });
              }
            });
            // eslint-disable-next-line brace-style
          }
          // if only target contains ,
          else if (!sanitizedQuery[subIndex - 1].includes(',') && sanitizedQuery[subIndex + 1].includes(',')) {
            let sourceComboId = null;
            let targetComboId = null;
            let sourceNode = sanitizedQuery[subIndex - 1].trim();
            let targetNodes = sanitizedQuery[subIndex + 1].split(',');

            if (sanitizedQuery[subIndex - 1].includes('[' && ']')) {
              const splitted = sanitizedQuery[subIndex - 1].split('[');

              sourceComboId = splitted[0].trim();
              sourceNode = splitted[1].replace(']', '');
            }
            if (sanitizedQuery[subIndex + 1].includes('[' && ']')) {
              const splitted = sanitizedQuery[subIndex + 1].split('[');
              targetComboId = splitted[0].trim();
              targetNodes = splitted[1].replace(']', '').split(',');
            }
            combos.push({
              id: targetComboId,
              label: targetComboId,
            });
            combos.push({
              id: sourceComboId,
              label: sourceComboId,
            });
            targetNodes.forEach((targetNode) => {
              if (targetComboId === null) {
                nodes.push({
                  id: targetNode.trim(),
                  label: targetNode.trim(),
                });
              } else {
                nodes.push({
                  id: targetNode.trim(),
                  label: targetNode.trim(),
                  comboId: targetComboId,
                });
              }
              if (sourceComboId === null) {
                nodes.push({
                  id: sourceNode,
                  label: sourceNode,
                });
              } else {
                nodes.push({
                  id: sourceNode,
                  label: sourceNode,
                  comboId: sourceComboId,
                });
              }

              if (relationshipLabel.includes('>')) {
                edges.push({
                  source: sourceNode,
                  target: targetNode.trim(),
                  label: relationshipLabel.replace('>', ''),
                  style: {
                    endArrow: true,
                  },
                });
              } else {
                edges.push({
                  source: sanitizedQuery[subIndex - 1].trim(),
                  target: targetNode.trim(),
                  label: relationshipLabel,
                });
              }
            });
            // eslint-disable-next-line brace-style
          }
          // if neither contains ,
          else {
            let sourceComboId = null;
            let targetComboId = null;
            let sourceNode = sanitizedQuery[subIndex - 1].trim();
            let targetNode = sanitizedQuery[subIndex + 1].trim();
            if (sanitizedQuery[subIndex - 1].includes('[' && ']')) {
              const splitted = sanitizedQuery[subIndex - 1].split('[');

              sourceComboId = splitted[0].trim();
              sourceNode = splitted[1].replace(']', '').trim();
            }
            if (sanitizedQuery[subIndex + 1].includes('[' && ']')) {
              const splitted = sanitizedQuery[subIndex + 1].split('[');
              targetComboId = splitted[0].trim();
              targetNode = splitted[1].replace(']', '').trim();
            }
            combos.push({
              id: targetComboId,
              label: targetComboId,
            });
            combos.push({
              id: sourceComboId,
              label: sourceComboId,
            });
            if (sourceComboId === null) {
              nodes.push({
                id: sourceNode,
                label: sourceNode,
              });
            } else {
              nodes.push({
                id: sourceNode,
                label: sourceNode,
                comboId: sourceComboId,
              });
            }
            if (targetComboId === null) {
              nodes.push({
                id: targetNode,
                label: targetNode,
              });
            } else {
              nodes.push({
                id: targetNode,
                label: targetNode,
                comboId: targetComboId,
              });
            }

            if (relationshipLabel.includes('>')) {
              edges.push({
                source: sourceNode,
                target: targetNode,
                label: relationshipLabel.replace('>', ''),
                style: {
                  endArrow: true,
                },
              });
            } else {
              edges.push({
                source: sourceNode,
                target: targetNode,
                label: relationshipLabel,
              });
            }
          }
          // eslint-disable-next-line no-const-assign
          const refinedCombos = combos.filter((item) => item.id !== null);
          const finalCombos = refinedCombos.reduce((uniqueObj, item) => {
            if (uniqueObj[item.id] === undefined) {
              // eslint-disable-next-line no-param-reassign
              uniqueObj[item.id] = item;
            }
            return uniqueObj;
          }, {});

          // eslint-disable-next-line no-undef
          const createList = {};
          createList[uniqueIdGenerate()] = {
            nodes: Object.values(nodes.reduce((acc, curr) => {
              if (!acc[curr.id]) {
                acc[curr.id] = curr;
              }
              return acc;
            }, {})),
            edges,
            combos: Object.values(finalCombos),
          };

          globalOperation.createList = createList;
        }
      });
    });
  }
  if (baseQuery.includes('fetch(')) {
    const fetchQuery = startToEnd({
      str: baseQuery,
      start: 'fetch(',
      end: ')',
    });

    const mainQuery = fetchQuery[0].split(',');

    globalOperation.fetchList = mainQuery;
  }

  return globalOperation;
}
