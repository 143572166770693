import { render, staticRenderFns } from "./ShareCollaborate.vue?vue&type=template&id=174ad883&scoped=true"
import script from "./ShareCollaborate.vue?vue&type=script&lang=js"
export * from "./ShareCollaborate.vue?vue&type=script&lang=js"
import style0 from "./ShareCollaborate.vue?vue&type=style&index=0&id=174ad883&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "174ad883",
  null
  
)

export default component.exports