/* eslint-disable no-param-reassign */
import axios from 'axios';

const exploreEdgeMap = async (data, title) => {
  // ? first Node Link form of data from /explore/links api array of { links: , label: }
  const fetchedNodes = data.map((item) => item.title); // ? ['title1', 'title2', 'title3']
  // ? fetched Detail from WikiBase graph
  const response = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/custom/entity-cluster?word=${title}&type=string&lang=en`);
  // ? response data
  const wikibaseData = response.data;
  // ? Keep only those nodes that belongs from 6 line no.
  const filteredWikBase = wikibaseData.nodes.filter((item) => fetchedNodes.includes(item.label)); // ? [id, label, qid]
  // ? here extract all qid inside array [qid, qid, qid], as edges has only qid info and make it unique
  const wikibaseId = Array.from(new Set(filteredWikBase.map((item) => item.id)));

  // ? filter all edges from wikiGraph data that belongs to qid from 14 line no
  const edgesFilter = wikibaseData.edges.filter((item) => wikibaseId.includes(item.target));
  // ? reduce and create an object, so that later we can compare and check with edges;
  const edgesMapObj = filteredWikBase.reduce((unique, item) => {
    if (unique[item.id] === undefined) {
      unique[item.id] = item;
    }
    return unique;
  }, {});

  // ? finally, just loop over extracted edges and extract label from this as edgeLabel inside edgesMapObj
  edgesFilter.forEach((item) => {
    edgesMapObj[item.target].edgeLabel = item.label;
  });

  // ? just return it...
  return edgesMapObj;
};

export default exploreEdgeMap;
