<template>
  <div>
    <video
      v-if="isCameraOpen"
      ref="camera"
      :width="canvasWidth"
      :height="canvasHeight"
      autoplay
      class="vdo"
      @dblclick="zoomInCamera"
      @click="handleClick"
    ></video>
  </div>
</template>

<script>
import swal from 'sweetalert';

export default {
  name: 'Webcam',
  data() {
    return {
      canvasHeight: '44%',
      canvasWidth: '44%',
      clickCount: 0,
    };
  },
  computed: {
    isCameraOpen() {
      return this.$store.state.cameraStatus;
    },
  },
  watch: {
    isCameraOpen(newCameraStatus) {
      if (newCameraStatus) {
        this.startCameraStream();
      } else {
        this.stopCameraStream();
      }
    },
  },
  methods: {
    startCameraStream() {
      const constraints = {
        audio: false,
        video: true,
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          swal(`Browser doesn't support or there is some errors.${error}`, 'error');
        });
    },
    stopCameraStream() {
      const tracks = this.$refs.camera.srcObject.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    },
    zoomInCamera() {
      const scaleFactor = 1.5;

      if (this.clickCount % 2 === 0) {
        this.canvasWidth = `${parseFloat(this.canvasWidth) * scaleFactor}%`;
        this.canvasHeight = `${parseFloat(this.canvasHeight) * scaleFactor}%`;
      } else {
        this.canvasWidth = '44%';
        this.canvasHeight = '44%';
      }
      this.clickCount = 0;
    },
    handleClick() {
      // eslint-disable-next-line no-plusplus
      this.clickCount++;
      if (this.clickCount === 3) {
        this.clickCount = 0;
        this.canvasWidth = `${parseFloat(this.canvasWidth) / 1.5}%`;
        this.canvasHeight = `${parseFloat(this.canvasHeight) / 1.5}%`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
