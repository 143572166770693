import Vue from 'vue';
import VueCompositionAPI, { reactive } from '@vue/composition-api';

Vue.use(VueCompositionAPI);

// GraphReactor init
const graphReactor = reactive({
  exploreInstance: null,
  linkedInstace: null,
});

export default {
  graphReactor,
};
