<template>
  <div class="iam d-flex justify-content-center align-items-center">
    <div class="iam__login nav-item" @click="authRedirect('login')">
      Login
    </div>
    <button
      class="nav-item btn btn-primary btn-discord mx-3 iam__register"
      @click="authRedirect('signup')"
    >
      <span class="mx-2">Sign Up</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Login',

  methods: {
    authRedirect(action) {
      this.$router.push({ name: 'Auth', params: { action } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/abstracts/variables';

.iam {
  gap: 1em;

  &__login {
    color: $black;
    opacity: 0.7;
    transition: opacity ease-in-out 200ms;

    &:hover {
      cursor: pointer;
    }

    &:hover, &:active, &:focus {
      opacity: 1;
    }
  }

  &__register {
    background-color: $secondaryGreen;
    border: $secondaryGreen;

    &:hover, &:focus, &:active {
      background-color: $secondaryGreenHover;
    }
  }
}
</style>
