import axios from 'axios';

export default {
  namespaced: true,

  state: {
    jobs: [],
  },

  actions: {
    async getJobs({ commit }, title) {
      try {
        const url = `${process.env.VUE_APP_AXIOS_URI}/custom/jobs/${title}`;
        const response = await axios.get(url);
        const result = response.data;

        // Extract the job data from the result and commit it to the state
        // console.log('xvf', result);

        commit('UPDATE_JOBS', result);
      } catch (error) {
        console.error('xvf', error);
      }
    },
  },

  mutations: {
    UPDATE_JOBS: (state, jobs) => {
      state.jobs = jobs;
    },
  },
};
