class Branch {
  constructor({
    dir, first, second, split,
  }) {
    this.dir = dir;
    this.first = first;
    this.second = second;
    this.split = split;
  }
}

export default Branch;
