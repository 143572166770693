/* eslint-disable */
import actions from "@/store/modules/graph/actions";
import mutations from "@/store/modules/graph/mutations";
import getters from "@/store/modules/graph/getters";

export default {
  namespaced: true, // works for actions, mutations and getters... because for state its namespaced by default
  state: {
    g6_explore_data: {
      nodes: [],
      edges: [],
      combos: [],
    },
    isArrowEdge: false,
    edit_mode: 'looked_at',
    groupMode: false,
    dimension_data: null,
    globalStates: {
      nodes: [
        'searchEd', 'opacityO', 'searchRelatedEd', 'focusEd', 'relatedEd', 'current_ent', 'selected', 'highlight', 'inactive', 'spNode'
      ],
      edges: [
        'opacityO', 'relatedEd', 'selected', 'highlight', 'inactive', 'spEdge'
      ],
    },
    hidden_node_list: [],
    anchor: '',
    usersList: [],
    mainNodesOfExplore: [],
    test: "test_modules",
    pList: {
      What: '{ wdt:P31 wdt:P279 wdt:P366 wdt:P828 wdt:P1479 }',
      When: '{ wdt:P569 wdt:P570 wdt:P6949 wdt:P1636 wdt:P746 wdt:P1191 wdt:P1619 }', //
      Where: '{ wdt:P625 wdt:P915 wdt:P159 wdt:P276 wdt:P19 wdt:P20 wdt:P291 wdt:P27 wdt:P36 }',
      Who: '{ wdt:P31 wdt:P279 wdt:P26 wdt:P106 wdt:P1830 }',
      Which: '{ wdt:P1830 wdt:P127 }',
      How: '{ wdt:P2218 }',
      Why: '{ wdt:P828 }',
    },
    edge: null,
    addingEdge: false,
    graph_detail: null,
    active_node: null,
    csvReserveHeads: ['nodeid', 'color', 'fetch', 'combo', 'description'],
    generativeFunction: {
      isActive: false,
    },
    vr: {
      scale: 3,
      gdata: {},
    },
  },

  actions,
  mutations,
  getters,
};
