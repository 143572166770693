<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <div class="nav-first-half" v-if="blockSpecialRoute">
        <SidebarToggle v-if="abilities.can('sidebar', 'service')" />
        <Search v-if="abilities.can('search', 'service')" />
      </div>
      <div class="nav-middle">
        <a href="/" title="Thoughtjumper">
          <img
            src="@/assets/images/logo.svg"
            alt="Thoughtjumper"
            class="nav-middle__logo"
          />
        </a>
      </div>
      <div class="nav-second-half">
        <div
          class="
            collapse
            navbar-collapse
            justify-content-end
            nav-second-half__content
          "
          id="navbarSupportedContent"
          v-if="blockSpecialRoute"
        >
          <ThoughtNavController v-if="abilities.can('nav_controller', 'service')" />
          <ChangeView :currentRoute="this.$route.name" v-if="abilities.can('view_controller', 'service')" />
          <!-- <LayoutEditor v-if="isLoggedIn" /> -->
          <!-- <NotificationBell v-if="isLoggedIn" /> -->
          <LanguageSelector />
          <div class="navbar-nav">
            <p class="nav__user"></p>
            <Login v-if="!isLoggedIn" />
            <div class="d-flex flex-row align-items-center" v-else>
              <UserInfo />
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import LanguageSelector from '@/common/components/LanguageSelector.vue';
import Login from '@/common/components/Login.vue';
import UserInfo from '@/common/components/UserInfo.vue';
import Search from '@/common/components/Search.vue';
import SidebarToggle from '@/common/components/SidebarToggle.vue';
import ChangeView from '@/common/components/ChangeView.vue';
import notSpecialRoute from '@/utils/notSpecialRoute';
import AbilityMixin from '@/mixins/AbilityMixin';
import ThoughtNavController from './ThoughtNavController.vue';

export default {
  name: 'Navbar',
  components: {
    LanguageSelector,
    ChangeView,
    Login,
    UserInfo,
    Search,
    SidebarToggle,
    ThoughtNavController,
  },

  data() {
    return {
      // isLoggedIn: false,
    };
  },
  mixins: [AbilityMixin],
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
    }),
    currentEntityLabel() {
      return this.$store.state.current_thought.label;
    },
    blockSpecialRoute() {
      return notSpecialRoute(this.$store.state.routeInfo, 'mobile');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/abstracts/variables";

nav {
  opacity: 1;
  z-index: 3;
  padding-bottom: 0.4em;
}

.navbar {
  position: sticky;
  z-index: 5;
  max-height: 7vh;
  top: 0;
  right: 0;
  left: 0;
  background: $white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}
.direct-chat-msg {
  border-radius: 5px;
  position: relative;
  padding: 5px 10px;
  background: #ff3456;
  border: 1px solid #d2dbde;
  color: #fff;
  border-radius: 15px 15px 0 15px;
  margin: 5px 0 0 50px;
}

.nav-first-half,
.nav-second-half {
  display: flex;
  align-items: center;

  &__content {
    gap: 1em;
  }
}

.nav-middle {
  flex: 1;
  text-align: center;

  &__logo {
    height: 1.8em;
    width: auto;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
