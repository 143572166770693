import axios from 'axios';
// eslint-disable-next-line import/no-cycle
// import router from '../../routecr';

export default {
  namespaced: true,

  state: {
    status: {
      isLoggedIn: false,
      isRefreshTokenRequested: false,
    },
    loginFailed: {
      status: false,
      message: '',
    },
    userToken: null,
    refreshToken: null,
    user: null,
    forgotPasswordRequest: false,
    resetPassword: false,
    updatePasswordStatus: null,

  },

  getters: {
    authUser(state) {
      return state.user ? state.user : null;
    },

    isLoggedIn(state) {
      return state.status.isLoggedIn ? state.status.isLoggedIn : false;
    },

    openAIKey(state) {
      return state.user.apiKey ? state.user.apiKey : false;
    },
  },

  actions: {
    async resetPasswordstatus({ commit, state }, { currentPassword, password, confirmPassword }) {
      try {
        const token = state.userToken || localStorage.getItem('userToken');
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        console.log('hearder', token);
        const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI_BASE}/auth/update-password`, {
          currentPassword,
          password,
          confirmPassword,
        }, config);
        if (response.data.msg === 'Password changed successfully') {
          this.$toast.success(response.data.msg);
          this.$router.replace('/auth/login');
        } else {
          this.$toast.error(response.data.msg);
        }
        commit('SET_RESET_PASSWORD_STATUS', 'success');
      } catch (error) {
        commit('SET_RESET_PASSWORD_STATUS', 'error');
      }
    },
    initAuthentication({ state, dispatch, commit }) {
      return new Promise((resolve, reject) => {
        const token = state.userToken || localStorage.getItem('userToken');
        if (token) {
          dispatch('fetchAuthUser', token)
            .then((user) => {
              commit('SET_LOGIN_SUCCESS', token);
              dispatch('setUserOnLogin', {}, { root: true });
              resolve(user);
            })
            .catch((error) => {
              if (error.response && error.response.data.message === 'jwt expired') {
                commit('SET_REFRESH_TOKEN_REQUEST', true);
                dispatch('refreshUserToken').then((user) => {
                  resolve(user);
                }).catch((err) => {
                  commit('SET_LOGIN_FAIL', 'It looks like your session has expired');
                  commit('SET_REFRESH_TOKEN_REQUEST', false);
                  localStorage.removeItem('userToken');
                  localStorage.removeItem('refreshToken');
                  reject(err);
                });
              } else {
                commit('SET_LOGIN_FAIL', 'It Looks like server is not Responding');
                reject(error);
              }
            });
        } else {
          resolve(null);
        }
      });
    },

    // refresh TOken
    refreshUserToken({ state, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        const refreshToken = state.refreshToken || localStorage.getItem('refreshToken');
        axios.get(`${process.env.VUE_APP_AXIOS_URI_BASE}/auth/refresh-token`, {
          headers: {
            RefreshToken: `Bearer ${refreshToken}`,
          },
        }).then((response) => {
          console.log('xvf', 'Refresh response', response);
          const newLoginToken = response.data?.token;
          const newRefreshToken = response.data?.refreshToken;
          localStorage.setItem('userToken', newLoginToken);
          localStorage.setItem('refreshToken', newRefreshToken);
          commit('SET_LOGIN_SUCCESS', { token: newLoginToken, refreshToken: newRefreshToken });
          dispatch('initAuthentication').then((user) => {
            resolve(user);
          }).catch((err) => {
            reject(err);
          });
        }).catch((error) => {
          reject(error);
        });
      });
    },

    login({ commit, dispatch }, user) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_AXIOS_URI_BASE}/auth/login`, {
            username: user.username,
            password: user.password,
          })
          .then((response) => {
            const loginToken = response.data?.token;
            const refreshToken = response.data?.refreshToken;
            if (loginToken) {
              localStorage.setItem('userToken', loginToken);
              localStorage.setItem('refreshToken', refreshToken);
              commit('SET_LOGIN_SUCCESS', { token: loginToken, refreshToken });
              dispatch('setUserOnLogin', {}, { root: true });
              resolve(loginToken);
            } else {
              commit('SET_LOGIN_FAIL');
              resolve(false);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    signUp({ dispatch }, user) {
      return new Promise((resolve, reject) => {
        const createUser = {
          firstName: user.firstName,
          username: user.username,
          password: user.password,
          email: user.email,
        };
        if (user.tjKey && user.tjKey.length > 0) {
          createUser.tjKey = user.tjKey;
        }
        axios
          .post(`${process.env.VUE_APP_AXIOS_URI_BASE}/auth/signup`, {
            ...createUser,
          })
          .then((createdUser) => {
            dispatch('login', user).then(() => {
              resolve(createdUser);
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    logout({ state, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOGOUT');
        const refreshToken = state.refreshToken || localStorage.getItem('refreshToken');
        axios.get(`${process.env.VUE_APP_AXIOS_URI_BASE}/auth/logout`, {
          headers: {
            RefreshToken: `Bearer ${refreshToken}`,
          },
        }).then((response) => {
          if (response.status === 200) {
            dispatch('CleanUpUserOnLogout', {}, { root: true });
            localStorage.removeItem('userToken');
            localStorage.removeItem('refreshToken');
            resolve(true);
          }
        }).catch((err) => {
          reject(err);
        });
      });
    },

    fetchAuthUser({ dispatch, commit }, token) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_AXIOS_URI_BASE}/auth/me`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (response.data.message === 'refresh_token') {
              const output = response.data;
              localStorage.setItem('userToken', output.token);
              localStorage.setItem('refreshToken', output.refreshToken);
              commit('SET_LOGIN_SUCCESS', { token: output.token, refreshToken: output.refreshToken });
              dispatch('initAuthentication');
            } else {
              commit('SET_USER_PROFILE', response.data);
              resolve(response.data);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // request-forgot-password
    requestForgotPassword({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_AXIOS_URI_BASE}/auth/request-forgot-password`, {
            email: user.email,
          })
          .then((data) => {
            commit('SET_FORGOT_PASSWORD_REQUEST');
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // request-oAuth-validation
    requestOAuthTokens({ commit, dispatch }, requestAccessToken) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_AXIOS_URI_BASE}/auth/oauth-request/${requestAccessToken}`)
          .then((response) => {
            if (response.status === 200) {
              const output = response.data;
              localStorage.setItem('userToken', output.token);
              localStorage.setItem('refreshToken', output.refreshToken);
              commit('SET_LOGIN_SUCCESS', { token: output.token, refreshToken: output.refreshToken });
              resolve(true);
              dispatch('initAuthentication');
            }
          }).catch((error) => {
            reject(error);
          });
      });
    },

    // validate-forgot-password-request
    validateResetPasswordRequest({ commit }, passwordRequestKey) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_AXIOS_URI_BASE}/auth/check-forgot-request/${passwordRequestKey}`)
          .then((data) => {
            if (data.status === 200) {
              commit('SET_RESET_PASSWORD');
              resolve(data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async updateProfile({ state, commit }, obj) {
      const token = state.userToken || localStorage.getItem('userToken');
      const response = await axios
        .patch(`${process.env.VUE_APP_AXIOS_URI_BASE}/auth/me/profile`,
          obj,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
      commit('SET_USER_PROFILE', response.data);
    },

    // validate-forgot-password-request
    resetPassword({ commit }, obj) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_AXIOS_URI_BASE}/auth/reset-password`, {
            resetPasswordRequest: obj.resetPasswordRequest,
            password: obj.password,
            confirmPassword: obj.confirmPassword,
          })
          .then((data) => {
            if (data.status === 200) {
              commit('SET_RESET_PASSWORD');
              resolve(data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },

  mutations: {
    SET_RESET_PASSWORD_STATUS(state, status) {
      state.updatePasswordStatus = status;
    },
    SET_LOGIN_SUCCESS(state, { token, refreshToken }) {
      state.status.isLoggedIn = true;
      state.refreshToken = refreshToken;
      state.userToken = token;
      state.loginFailed.status = false;
      state.loginFailed.message = '';
    },
    SET_LOGIN_FAIL(state, message) {
      state.status.isLoggedIn = false;
      state.userToken = null;
      state.loginFailed.status = true;
      state.loginFailed.message = message;
    },

    SET_LOGOUT(state) {
      state.status.isLoggedIn = false;
      state.user = null;
      state.userToken = null;
    },

    SET_USER_PROFILE(state, payload) {
      state.user = payload;
    },

    SET_FORGOT_PASSWORD_REQUEST(state) {
      state.requestForgotPassword = !state.requestForgotPassword;
    },

    SET_RESET_PASSWORD(state) {
      state.resetPassword = !state.resetPassword;
    },
    SET_REFRESH_TOKEN_REQUEST(state) {
      state.status.isRefreshTokenRequested = !state.status.isRefreshTokenRequested;
    },
  },
};
