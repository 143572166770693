export default {
  SET_QUEST_GRAPH_DATA: (state, questGraphData) => {
    state.quest_graph_data = questGraphData;
  },

  SET_QUEST_GRAPH_EDGES: (state, questGraphEdges) => {
    state.quest_graph_edges = { ...state.quest_graph_edges, ...questGraphEdges };
  },

  IS_QUEST_GRAPH_LOADING(state, isQuestGraphLoading) {
    state.is_quest_graph_loading = isQuestGraphLoading;
  },

  SET_QUEST_MODE: (state, payload) => {
    state.quest_mode = payload;
  },

  SET_ACTIVE_DETAIL_FOR_QUEST: (state, wordObj) => {
    state.active_detail_for_quest = wordObj;
  },

  SET_LANGCHAIN_CONTEXT: (state, data) => {
    state.langchain = {
      context: data,
    };
  },

  SET_LANGCHAIN_RESPONSE: (state, data) => {
    state.langchain = {
      res: data,
    };
  },

  UPDATE_NAMESPACE: (state, data) => {
    state.namespace = data;
  },

  SET_PROMPTS: (state, data) => {
    state.prompts = data;
  },

  ADD_TO_PROMPTS: (state, payload) => {
    const currentPromptList = state.prompts;
    currentPromptList.push(payload.prompt);
    state.prompts = currentPromptList;
  },

  UPDATE_TO_PROMPTS: (state, payload) => {
    const currentPromptList = state.prompts.map((prompt) => {
      if (prompt.id === payload.prompt.id) {
        return payload.prompt;
      } return prompt;
    });
    state.prompts = currentPromptList;
  },

  DELETE_A_PROMPT: (state, payload) => {
    const filterPrompts = state.prompts.filter((prompt) => prompt.id !== payload.prompt.id);
    state.prompts = filterPrompts;
  },

  SET_ACTIVE_PROMPT: (state, data) => {
    state.activePrompt = data;
  },
};
