<template>
  <li class="dock-icons" id="screenRecordingLi">
    <a @click="startRecording()" class="bottom-dock-btn" v-if="!isRecording">
      <i class="bottom-dock-btn__icon bx bx-slideshow" title="Start Screen recording"></i>
    </a>
    <a @click="stopRecording()" class="bottom-dock-btn" v-if="isRecording && !isUploading">
      <i class="bottom-dock-btn__icon stop-btn  bx bx-stop" style="color: #fe330e" title="Recording..."></i>
    </a>
    <div class="bottom-dock-btn bottom-dock-btn--disabled" v-if="isRecording && isUploading">
      <span class="bottom-dock-btn__icon spinner-border text-danger spinner-border-sm"></span>
    </div>
  </li>
</template>

<script>
export default {
  data() {
    return {
      isRecording: false,
      isUploading: false,
    };
  },
  methods: {
    async startRecording() {
      // Get a Screen Recorder instance
      const stream = await this.$screenRecorderPlugin.screenRecorder();

      // define the mime type
      const mimeType = 'video/webm';

      // call the media Recorder
      this.mediaRecorder = this.$screenRecorderPlugin.createRecorder(stream, mimeType);
      this.isRecording = true;
    },

    async stopRecording() {
      this.isUploading = true;
      // console.log('RECORDER', this.mediaRecorder);
      this.mediaRecorder.stopRecording().then((isUploaded) => {
        if (!isUploaded) {
          // eslint-disable-next-line no-alert
          alert('Recording is not Completed');
        }
        // console.log('RECORDER', 'Finished Recording...');
        this.isRecording = false;
        this.isUploading = false;
      }).catch(() => {
        // eslint-disable-next-line no-alert
        alert('Something is wrong! Please try again');
        this.isRecording = false;
        this.isUploading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.rec-output {
  position: relative;
  #scr {
    width: 70%;
    height: 30%;
  }

  #videoRec {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  }

}

.stop-btn {
  animation: grow 1500ms infinite ease-in-out forwards;
}

@keyframes grow {
  0% {
    scale: 1;
    transform: rotate(0deg);
  } 50% {
    scale: 1.5;
    transform: rotate(180deg);
  } 100% {
    scale: 1;
    transform: rotate(360deg);
  }
}
</style>
