<template>
  <div class="oauth__page">
    <div class="oauth__section">
      <span class="spinner-border text-info"></span>
      <h5 class="oauth__text">Getting your Process Done</h5>
    </div>

  </div>
</template>

<script>
export default {
  name: 'oAuthPage',
  mounted() {
    const { oauthRequestKey } = this.$route.params;
    this.$store.dispatch('auth/requestOAuthTokens', oauthRequestKey).then((response) => {
      if (response) {
        const redirectTo = {
          name: 'Auth',
          params: { action: 'Login' },
        };
        this.$router.push(redirectTo);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.oauth {
  &__page {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &__section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    margin-top: 1rem;
  }
}
</style>
