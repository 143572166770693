export default {
  editLayout({ commit }) {
    commit('EDIT_LAYOUT');
  },

  resizeLayout({ commit }) {
    commit('RESIZE_LAYOUT');
  },

  toggleChangeView({ commit }, updatedView) {
    commit('SET_UPDATED_VIEW', updatedView);
  },
};
