export default {
  textNode: `var $zoho = $zoho || {}; 
  $zoho.salesiq = $zoho.salesiq || { widgetcode: "dc0ff82261947de37479c2b1838a2a7fa254e881085b0fcad83c3ce09612048af7c305c2a31f970cf47811fbbca424f3",
  values: {}, ready: function () { } };
  var d = document;
  s = d.createElement("script");
  s.type = "text/javascript";
  s.id = "zsiqscript";
  s.defer = true;
  s.src = "https://salesiq.zoho.in/widget";
  t = d.getElementsByTagName("script")[0];
  t.parentNode.insertBefore(s, t);`,
};
