<template>
  <div class="footer">

    <img src="@/assets/images/illustrations/illustration-1.svg" alt="Thoughtjumper" class="footer__img img-fluid" />
    <span class="footer__texts">Copyright &copy; 2012-{{ getCurrentYear }}</span>
    <span class="footer__texts">Edvanta Technologies.</span>
    <span class="footer__texts">All rights reserved</span>
    <span class="footer__texts badge bg-secondary">{{app_version}} </span>
    <div class="footer__img2">
    <a href="https://discord.gg/fx4beU48Wf" target="_blank">
      <img src="https://discordapp.com/api/guilds/963077822082068512/widget.png?style=banner2" alt="Thoughtjumper Discord link"  />
    </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',

  computed: {
    getCurrentYear() {
      return new Date().getFullYear();
    },
    app_version() {
      return process.env.VUE_APP_GIT_VERSION;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/abstracts/variables";
.footer {
  color: $black;
  // box-shadow: 0em -0.125em 0.3125em rgb(0 0 0 / 10%);
  max-height: 18vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 2em; */
  font-size: .8rem;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  &__img {
    position: absolute;
    top: -9rem;
    max-width: 17rem;
  }
  &__img2 {
    // position: absolute;
    // top: -19rem;
    padding-top: 1rem;
    padding-bottom: 0;
    // max-width: 100%;

    img {
      width: 100%;
    }

  }
}
</style>
