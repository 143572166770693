/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */

const uniqueIdGenerate = (str) => {
  const id = str + Math.random().toString(36).substr(2, 9);
  return id;
};

// const tokenizer = {
//   code(src) {
//     // one line string
//     // eslint-disable-next-line
//     // js to from regexp syntax
//     const re = /\$ed(.*?)ed\$/g;
//     const match = src.match(/^\$ed+([^\$\n]+?)ed\$+/);
//
//     if (match) {
//       if (match[0].startsWith('$ed')) {
//         return {
//           type: 'code',
//           raw: match[0],
//           text: match[1].trim(),
//         };
//       }
//       return {
//         type: 'code',
//         raw: match[0],
//         text: match[1].trim(),
//       };
//     }

//     // return false to use original codespan tokenizer
//     return false;
//   },
// };

const codeList = {
  name: 'codeList',
  level: 'block', // Is this a block-level or inline-level tokenizer?
  start(src) {
    return src;
  }, // Hint to Marked.js to stop and check for a match
  // eslint-disable-next-line
  tokenizer(src, tokens) {
    // eslint-disable-next-line
    const rule = /^\$ed+([^$]+?)\ed\$+/; // Regex for the complete token, anchor to string start
    const match = rule.exec(src);
    if (match) {
      const token = {
        // Token to generate
        type: 'codeList', // Should match "name" above
        raw: match[0], // Text to consume from the source
        text: match[0].trim(), // Additional custom properties
        tokens: [], // Array where child inline tokens will be generated
      };
      return token;
    }
  },
  renderer(token) {
    return `<div>
      <code>${token.raw}\n</code>
      <button onclick="SendAllThistoNotes(event)"> Run </button>
     </div>
    `; // parseInline to turn child tokens into HTML
  },
};

const nodeLink = {
  name: 'nl',
  level: 'block', // Is this a block-level or inline-level tokenizer?
  start(src) {
    return src;
  }, // Hint to Marked.js to stop and check for a match
  // eslint-disable-next-line
  tokenizer(src, tokens) {
    // eslint-disable-next-line
    const rule = /^\$nl+([^$]+?)nl\$+/; // Regex for the complete token, anchor to string start
    const match = rule.exec(src);
    if (match) {
      const token = {
        // Token to generate
        type: 'nl', // Should match "name" above
        raw: match[0], // Text to consume from the source
        text: match[0].trim(), // Additional custom properties
        tokens: [], // Array where child inline tokens will be generated
      };
      return token;
    }
  },
  renderer(token) {
    const elData = token.raw.replace('$nl', '').replace('nl$', '').trim();
    const label = elData.split(']')[0].replace('[', ''); // [game](id)
    const id = elData.split('(')[1].replace(')', ''); // [game](id)
    return `<div>
      <p onclick="nodeFocusFromNote(event)" nodeid="${id}" style="cursor:pointer;margin:0;padding:0;color:#659dbd;">${label}</p>
     </div>
    `; // parseInline to turn child tokens into HTML
  },
};

const underlineExtension = {
  name: 'underline',
  level: 'inline', // This is an inline-level tokenizer
  start(src) {
    return src.indexOf('++');
  },
  // eslint-disable-next-line
  tokenizer(src, tokens) {
    const rule = /^\+\+(.+?)\+\+/; // Regex for the complete token, anchor to string start
    const match = rule.exec(src);
    if (match) {
      const token = {
        type: 'underline', // Should match "name" above
        raw: match[0], // Text to consume from the source
        text: match[1], // Additional custom properties
      };
      return token;
    }
  },
  renderer(token) {
    return `<u>${token.text}</u>`;
  },
};

module.exports = {
  codeList,
  nodeLink,
  underlineExtension,
};
