<template>
    <div v-if="showSearch" class="search-backdrop" @click="showSearch = false">
      <div
        style="width: 40%; background-color: #fff; padding: 15px; border-radius: 4px"
      >
        <p>{{randomTitle}}</p>
        <a-input
            style="height: 50px"
            v-model="searchQuery"
            @pressEnter="search"
            ref="searchInput"
            placeholder="Search..."
            @click.stop
        ></a-input>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      showSearch: false,
      searchQuery: '',
      searchTitles: [
        "🧠 Jumpstart your thoughts. What's the keyword?",
        "🔗 Connect the dots. What's your starting point?",
        '🌌 Navigate the knowledge cosmos. Seek what?',
        '📊 Graphical wonders await. Start with a term...',
        "🚀 Propel your thoughts. What's the ignition keyword?",
        '🔍 Dive deep into the sea of insights. Where to begin?',
        '🗺️ Chart your learning journey. First destination?',
        '🌐 Traverse the thought-web. Where to?',
        '💡 Illuminate your mind. What sparks your curiosity?',
        '📘 Open the book of the universe. Search term?',
        '🤔 Ponder, explore, discover. Start with...',
        '📚 From pages to patterns. Seeking what?',
        '🎨 Paint your knowledge canvas. First stroke?',
        '🕸️ Weave your web of wisdom. Begin with...',
        "📈 Elevate your understanding. What's the ascent keyword?",
        "🎓 Graduate every search. Today's lesson?",
        "🎯 Aim for insights. What's the bullseye?",
        '🌟 Star-gaze the info galaxy. Spotting what?',
        '🔥 Kindle the flame of knowledge. Sparked by...',
        '🌳 Grow your wisdom tree. Planting which seed?',
      ],
      randomTitle: '',
    };
  },

  mounted() {
    window.addEventListener('keydown', (event) => {
    // Check if Ctrl (or Cmd in Mac) and S keys are pressed together
      if ((event.ctrlKey || event.metaKey) && event.code === 'KeyK') {
        // Prevent the default behavior (in this case, saving the page)
        event.preventDefault();
        // Optionally, you can provide your custom functionality here
        // eslint-disable-next-line no-alert
        if (!this.showSearch) {
          this.enableQuickSearch();
          this.$nextTick(() => {
            this.$refs.searchInput.focus();
          });
        } else {
          this.disableQuickSearch();
        }
      }
    });
  },

  methods: {
    search() {
      this.disableQuickSearch();

      const wordObj = {
        word: this.searchQuery,
        label: this.searchQuery,
        type: 'string',
        lang: this.$store.state.locale.currentLocale,
      };

      this.$store.dispatch('setCurrentThought', { thoughtObject: wordObj });

      this.searchQuery = '';
    },
    disableQuickSearch() {
      this.showSearch = false;
    },
    enableQuickSearch() {
      const index = Math.floor(Math.random() * 20);
      this.randomTitle = this.searchTitles[index];
      this.showSearch = true;
    },
  },

};
</script>

<style lang="scss" scoped>

.search-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); // Darkened backdrop
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  > div {
    box-shadow: 0px 6px 15px rgba(1, 1, 20, 0.15); // A subtle shadow
    border: 1px solid #01014D; // Using the primary dark color
  }

  p {
    color: #01014D; // Using the primary dark color
    font-weight: bold; // Making the title more pronounced
    margin-bottom: 15px; // A bit of space before the input
  }

  .ant-input {
    height: 50px;
    border-radius: 4px; // Slightly rounded corners
    border: 1px solid #01014D; // Using the primary dark color for the border
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus {
      border-color: #004cfd; // Highlight with yellow on hover/focus
      box-shadow: 0 0 10px rgba(0, 89, 253, 0.4); // Subtle yellow glow
    }

    &::placeholder {
      color: #01014D; // Using the primary dark color for placeholder text
      opacity: 0.7; // A bit transparent for better visual hierarchy
    }
  }
}

</style>
