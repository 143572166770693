<template>
  <div class="auth-page" id="auth-page">
    <div class="auth-page__col-2">
      <div class="tj-logo">
        <img :src="require('@/assets/images/logo.svg')" alt="" />
      </div>
      <div class="auth-box">
        <div class="header">
          <span class="auth-label">Don't have an account?</span>
          <button class="ed-btn__blim auth-btn--sm" @click="successRedirect">
            Signup
          </button>
        </div>
        <div class="other-methods-box">
          <h3 class="other-methods__header">Forgot Password</h3>
          <span class="auth__label">We will help you</span>
        </div>
        <!-- illustration-image -->
        <div class="auth__img-container">
          <img
            class="auth__img"
            :src="require('@/assets/images/illustrations/illustration-4.svg')"
          />
        </div>
        <!-- ---------------------- Forgot password Box ------------------------------- -->
        <div class="auth">
          <form @submit.prevent="onSignup" class="auth__form">
            <!-- FORGOT-PASSWORD : EMAIL GROUP-->
            <div class="form-group">
              <span class="auth__label">Email</span>
              <input
                type="text"
                class="auth__input"
                name="email"
                placeholder="johndoo@abc.com"
                v-model="email"
                v-focus="fieldErrors.email"
                @focus="fieldErrors.email = true"
                @blur="fieldErrors.email = false"
                autocomplete="off"
              />
              <!-- FORGOT-PASSWORD: email[ERROR]: Show when element is focused -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.email.error }"
                v-if="!$v.email.required && fieldErrors.email"
                >Email is required</span
              >
              <!-- FORGOT-PASSWORD: email[ERROR]: Show when submit button is pressed -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.email.error }"
                v-if="
                  !fieldErrors.email && submitErrors.email && !$v.email.required
                "
                >Email is required</span
              >
              <!-- FORGOT-PASSWORD: email[ERROR]: Show when there is a invalidation -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.email.error }"
                v-if="$v.email.required && !$v.email.email"
              >
                Please enter a valid email address</span
              >
            </div>
            <div class="pageError" v-if="this.pageError.length > 0">
              {{ this.pageError }}
            </div>
            <!-- FORGOT-PASSWORD : SUBMIT BUTTON GROUP -->
            <div class="form-group">
              <button
                class="ed-btn__blim auth-btn--lg"
                :disabled="isLoading"
                :style="{
                  opacity: isLoading ? 0.7 : 1,
                  cursor: isLoading ? 'default' : 'pointer',
                }"
              >
                Send Reset Password Request
                <span
                  :style="{
                    opacity: isLoading ? 1 : 0,
                  }"
                  class="spinner-border spinner-border-sm"
                ></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators';
import { focus } from 'vue-focus';

export default {
  name: 'auth-page',
  metaInfo: {
    title: 'Forgot Password',
  },
  directives: {
    focus,
  },
  data() {
    return {
      max: 100,
      email: '',
      errors: [],
      isLoading: false,
      pageError: '',
      fieldErrors: {
        email: false,
      },
      submitErrors: {
        firstName: false,
        username: false,
        email: false,
        password: false,
        confirmPassword: false,
      },
    };
  },
  methods: {
    cleanUp() {
      this.email = '';
    },
    // clearFieldErrors() {
    //   Object.keys(this.submitErrors).map((each) => {
    //     this.fieldErrors[each] = false;
    //     return each;
    //   });
    // },
    onSignup() {
      this.$v.$touch();
      if (!this.$v.email.$invalid) {
        this.toggleLoading();
        this.$store
          .dispatch('auth/requestForgotPassword', {
            email: this.email,
          })
          .then((data) => {
            this.logPageError(data.data.msg);
            this.toggleLoading();
            setTimeout(() => {
              this.cleanUp();
              this.successRedirect();
            }, 2000);
          })
          .catch((err) => {
            this.toggleLoading();
            this.logPageError('Please check your Email address');
            throw err;
          });
      } else {
        // this.logPageError("!Fields can't be empty");
        const invalidFields = Object.keys(this.$v.$params).filter(
          (fieldName) => this.$v[fieldName].$invalid,
        );
        invalidFields.forEach((each) => {
          this.submitErrors[each] = true;
          setTimeout(() => {
            this.submitErrors[each] = false;
          }, 2000);
        });
      }
    },
    successRedirect() {
      const redirectTo = {
        name: 'Auth',
        params: { action: 'Login' },
      };
      this.$router.push(redirectTo);
    },
    loginHandler() {
      this.$store.dispatch('auth/login', this.user).then(() => {
        this.successRedirect();
      });
    },
    toggleLoading() {
      this.isLoading = !this.isLoading;
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    changeRedirect(action) {
      this.cleanUp();
      this.mode = action;
      const redirectTo = this.$route.query.redirectTo || {
        name: 'Auth',
        params: { action },
      };
      this.$router.push(redirectTo);
    },
    logPageError(err) {
      this.pageError = err;
    },
    focus(field) {
      this.fieldErrors[field] = true;
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/abstracts/variables";

.auth-page {
  min-height: 100vh;
}

.auth {
  &__img-container {
    position: fixed;
    left: 1rem;
    bottom: 1rem;
  }

  &__img {
    width: 100% !important;
    transform: scaleX(-1), scale(1);
    opacity: 0.4;
    transform: scaleX(-1.5) scaleY(1.5);
  }
}
.auth-box {
  margin-bottom: 1rem;
}

.tj-logo {
  text-align: center;
  margin-bottom: 3rem;

  img {
    width: 50%;
  }
}
.introduction {
  opacity: 0;
  font-size: 0.8rem;
  text-align: center;
  width: 50%;
  position: absolute;
  top: 1.5rem;
  &__title {
    &--primary {
      color: var(--gray);
    }
    &--secondary {
      color: var(--accentPink);
    }
  }
}

.pageError {
  color: var(--accentPink);
  margin-bottom: 0.2em;
  font-size: 0.9em;
  text-align: center;
}
</style>
