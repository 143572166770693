<template>
  <div class="auth-page" id="auth-page" :style="`background: #fff`">
    <div class="auth-page__col-1">
      <carousel-3d :perspective="60" :space="2" :display="3" :border="0">
        <slide
          v-for="(slide, i) in slides"
          :index="i"
          :key="i"
          @click="onSlideMove"
        >
          <div class="introduction">
            <h3 class="introduction__title">
              <span class="introduction__title--primary"
                >{{ slide.title.primary }}
              </span>
              <span class="introduction__title--secondary">{{
                slide.title.secondary
              }}</span>
            </h3>
            <p class="introduction__desc">{{ slide.description }}</p>
          </div>
          <img :src="slide.img" :alt="slide" />
        </slide>
      </carousel-3d>
      <div class="tj-logo">
        <img :src="require('../assets/images/logo_invert.svg')" alt="" />
      </div>
    </div>
    <div class="auth-page__col-2">
      <div class="auth-box">
        <div class="header" v-if="mode === 'signup'">
          <span class="auth-label">Already have an account?</span>
          <button
            class="ed-btn__blim auth-btn--sm"
            @click="changeRedirect('login')"
          >
            Login
          </button>
        </div>
        <div class="header" v-if="mode === 'login'">
          <span class="auth-label">Don't have an account?</span>
          <button
            class="ed-btn__blim auth-btn--sm"
            @click="changeRedirect('signup')"
          >
            Signup
          </button>
        </div>
        <!-- Header image Container -->
        <div class="auth__img-container" v-if="mode === 'login'">
          <img
            class="auth__img"
            :src="require('@/assets/images/illustrations/illustration-3.svg')"
          />
        </div>
        <div class="other-methods-box">
          <h3 class="other-methods__header" v-if="mode === 'signup' || mode === 'signup-bootcamp24'">
            Sign up
          </h3>
          <h3 class="other-methods__header" v-else>Login</h3>
          <!-- <div class="pageError" v-if="this.pageError.length > 0">
            {{ this.pageError }}
          </div> -->
          <div class="other-methods" v-if="otherSignupMethods">
            <!-- <div class="other-methods__discord-signup"></div> -->
            <a class="other-methods__google-signup" :href='`${baseURI}/auth/google-auth`'>
              <img
                class="other-methods__google-signup__icon"
                alt="Google sign-in"
                src="../assets/google-icon.png"
              />
              <span class="other-methods__google-signup__text"
                >Continue with Google</span
              >
            </a>
          </div>
        </div>
        <!-- ----------------------IF PARAMETER IS SIGNUP ------------------------------- -->
        <div class="auth" v-if="mode === 'signup'">
          <div class="auth__header" v-if="otherSignupMethods">
            <hr class="auth__break" />
            <span class="auth__header__label">or sign up with an email</span>
          </div>
          <form @submit.prevent="onSignup" class="auth__form">
            <!-- SIGNUP : USERNAME -->
            <div class="form-group">
              <span class="auth__label">Username</span>
              <input
                type="text"
                :maxlength="max"
                class="auth__input"
                name="username"
                placeholder="johndoo"
                v-model="username"
                v-focus="fieldErrors.username"
                @focus="fieldErrors.username = true"
                @blur="fieldErrors.username = false"
                autocomplete="off"
              />
              <!-- LOGIN: username[ERROR]: Show when element is focused -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.username.error }"
                v-if="!$v.username.required && fieldErrors.username"
                >Username is required</span
              >
              <!-- LOGIN: username[ERROR]: Show when submit button is pressed -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.username.error }"
                v-if="
                  !fieldErrors.username &&
                  submitErrors.username &&
                  !$v.username.required
                "
                >Username is required</span
              >
              <!-- LOGIN: username[ERROR]: Show when there is a invalidation -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.username.error }"
                v-if="$v.username.required && !$v.username.usernameValidator"
                >Should be alphanumeric and cannot use special characters other
                than: . - _</span
              >
            </div>
            <!-- SIGNUP : EMAIL GROUP-->
            <div class="form-group">
              <span class="auth__label">Email</span>
              <input
                type="text"
                class="auth__input"
                name="email"
                placeholder="johndoo@abc.com"
                v-model="email"
                v-focus="fieldErrors.email"
                @focus="fieldErrors.email = true"
                @blur="fieldErrors.email = false"
                autocomplete="off"
              />
              <!-- LOGIN: email[ERROR]: Show when element is focused -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.email.error }"
                v-if="!$v.email.required && fieldErrors.email"
                >Email is required</span
              >
              <!-- LOGIN: email[ERROR]: Show when submit button is pressed -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.email.error }"
                v-if="
                  !fieldErrors.email && submitErrors.email && !$v.email.required
                "
                >Email is required</span
              >
              <!-- LOGIN: email[ERROR]: Show when there is a invalidation -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.email.error }"
                v-if="$v.email.required && !$v.email.email"
              >
                Please enter a valid email address</span
              >
            </div>
            <!-- SIGNUP : PASSWORD GROUP -->
            <div class="form-group">
              <span class="auth__label">Password</span>
              <div class="auth__password-box">
                <input
                  :type="showPassword ? 'text' : 'password'"
                  class="auth__password-box__field"
                  placeholder="***********"
                  v-model="password"
                  v-focus="fieldErrors.password"
                  @focus="fieldErrors.password = true"
                  @blur="fieldErrors.password = false"
                />
                <i
                  class="auth__password-box__icon"
                  :title="showPassword ? 'Hide Password' : 'Show Password'"
                  :class="showPassword ? 'bx bx-hide' : 'bx bx-show'"
                  @click="toggleShowPassword"
                ></i>
              </div>
              <!-- LOGIN: password[ERROR]: Show when element is focused -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.password.error }"
                v-if="!$v.password.required && fieldErrors.password"
                >Password is required
              </span>
              <!-- LOGIN: password[ERROR]: Show when submit button is pressed -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.password.error }"
                v-if="
                  !fieldErrors.password &&
                  submitErrors.password &&
                  !$v.password.required
                "
                >Password is required</span
              >
              <!-- LOGIN: password[ERROR]: Show when there is a invalidation -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.password.error }"
                v-if="$v.password.required && !$v.password.minLength"
                >minimum 8 Characters Long
              </span>
            </div>
            <!-- SIGNUP : CONFIRM PASSWORD GROUP -->
            <div class="form-group">
              <span class="auth__label">Confirm Password</span>
              <input
                type="password"
                class="auth__input"
                placeholder="***********"
                v-model="confirmPassword"
                v-focus="fieldErrors.confirmPassword"
                @focus="fieldErrors.confirmPassword = true"
                @blur="fieldErrors.confirmPassword = false"
              />
              <!-- LOGIN: confirmPassword[ERROR]: Show when element is focused -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.confirmPassword.error }"
                v-if="
                  !$v.confirmPassword.required && fieldErrors.confirmPassword
                "
                >Confirm Password is required
              </span>
              <!-- LOGIN: confirmPassword[ERROR]: Show when submit button is pressed -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.confirmPassword.error }"
                v-if="
                  !fieldErrors.confirmPassword &&
                  submitErrors.confirmPassword &&
                  !$v.confirmPassword.required
                "
                >Confirm Password is required</span
              >
              <!-- LOGIN: confirmPassword[ERROR]: Show when there is invalidation -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.password.error }"
                v-if="
                  $v.confirmPassword.required &&
                  !$v.confirmPassword.sameAsPassword
                "
              >
                Should be same as Password
              </span>
            </div>
            <!-- SIGNUP: NAME GROUP -->
            <div class="form-group">
              <span class="auth__label">What Should we call you?</span>
              <input
                type="text"
                class="auth__input"
                name="first-name"
                placeholder="e.g. Amelia Earhart, Ama, AE"
                v-model.trim="firstName"
                v-focus="fieldErrors.firstName"
                @focus="fieldErrors.firstName = true"
                @blur="fieldErrors.firstName = false"
              />
              <!-- LOGIN: firstName[ERROR]: Show when is focused -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.firstName.required }"
                v-if="!$v.firstName.required && fieldErrors.firstName"
                >Name is required</span
              >
              <!-- LOGIN: firstName[ERROR]: Show when submit button is pressed -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.firstName.error }"
                v-if="
                  !fieldErrors.firstName &&
                  submitErrors.firstName &&
                  !$v.firstName.required
                "
                >Name is required</span
              >
              <!-- LOGIN: firstName[ERROR]: Show when there is invalidation -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.firstName.required }"
                v-if="$v.firstName.required && !$v.firstName.minLength"
                >should be at least 2 words</span
              >
            </div>
            <!-- SIGNUP: Redeem Code -->
            <div class="form-group">
              <span class="auth__label">Do you have a subscription key?</span>
              <input
                type="text"
                class="auth__input"
                name="redeem-code"
                placeholder="Optional (fi34-ki89-u3u0-e59l)"
                v-model.trim="tjKey"
              />
            </div>
            <div class="pageError" v-if="this.pageError.length > 0">
              {{ this.pageError }}
            </div>
            <!-- SIGNUP : SUBMIT BUTTON GROUP -->
            <div class="form-group">
              <button
                class="ed-btn__blim auth-btn--lg"
                :disabled="isLoading"
                :style="{
                  opacity: isLoading ? 0.7 : 1,
                  cursor: isLoading ? 'default' : 'pointer',
                }"
              >
                Create Account
                <span
                  :style="{
                    opacity: isLoading ? 1 : 0,
                  }"
                  class="spinner-border spinner-border-sm"
                ></span>
              </button>
            </div>
          </form>
        </div>

        <!-- ----------------------IF PARAMETER IS Bootcamp23 SIGNUP ------------------------------- -->
        <div class="auth" v-else-if="mode === 'signup-bootcamp24'">
          <div class="auth__header" v-if="otherSignupMethods">
            <hr class="auth__break" />
            <span class="auth__header__label">or sign up with an email</span>
          </div>
          <form @submit.prevent="onSignup" class="auth__form">
            <!-- SIGNUP : USERNAME -->
            <div class="form-group">
              <span class="auth__label">Username</span>
              <input
                type="text"
                :maxlength="max"
                class="auth__input"
                name="username"
                placeholder="johndoo"
                v-model="username"
                v-focus="fieldErrors.username"
                @focus="fieldErrors.username = true"
                @blur="fieldErrors.username = false"
                autocomplete="off"
              />
              <!-- LOGIN: username[ERROR]: Show when element is focused -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.username.error }"
                v-if="!$v.username.required && fieldErrors.username"
                >Username is required</span
              >
              <!-- LOGIN: username[ERROR]: Show when submit button is pressed -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.username.error }"
                v-if="
                  !fieldErrors.username &&
                  submitErrors.username &&
                  !$v.username.required
                "
                >Username is required</span
              >
              <!-- LOGIN: username[ERROR]: Show when there is a invalidation -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.username.error }"
                v-if="$v.username.required && !$v.username.usernameValidator"
                >Should be alphanumeric and cannot use special characters other
                than: . - _</span
              >
            </div>
            <!-- SIGNUP : EMAIL GROUP-->
            <div class="form-group">
              <span class="auth__label">Email</span>
              <input
                type="text"
                class="auth__input"
                name="email"
                placeholder="johndoo@abc.com"
                v-model="email"
                v-focus="fieldErrors.email"
                @focus="fieldErrors.email = true"
                @blur="fieldErrors.email = false"
                autocomplete="off"
              />
              <!-- LOGIN: email[ERROR]: Show when element is focused -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.email.error }"
                v-if="!$v.email.required && fieldErrors.email"
                >Email is required</span
              >
              <!-- LOGIN: email[ERROR]: Show when submit button is pressed -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.email.error }"
                v-if="
                  !fieldErrors.email && submitErrors.email && !$v.email.required
                "
                >Email is required</span
              >
              <!-- LOGIN: email[ERROR]: Show when there is a invalidation -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.email.error }"
                v-if="$v.email.required && !$v.email.email"
              >
                Please enter a valid email address</span
              >
            </div>
            <!-- SIGNUP : PASSWORD GROUP -->
            <div class="form-group">
              <span class="auth__label">Password</span>
              <div class="auth__password-box">
                <input
                  :type="showPassword ? 'text' : 'password'"
                  class="auth__password-box__field"
                  placeholder="***********"
                  v-model="password"
                  v-focus="fieldErrors.password"
                  @focus="fieldErrors.password = true"
                  @blur="fieldErrors.password = false"
                />
                <i
                  class="auth__password-box__icon"
                  :title="showPassword ? 'Hide Password' : 'Show Password'"
                  :class="showPassword ? 'bx bx-hide' : 'bx bx-show'"
                  @click="toggleShowPassword"
                ></i>
              </div>
              <!-- LOGIN: password[ERROR]: Show when element is focused -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.password.error }"
                v-if="!$v.password.required && fieldErrors.password"
                >Password is required
              </span>
              <!-- LOGIN: password[ERROR]: Show when submit button is pressed -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.password.error }"
                v-if="
                  !fieldErrors.password &&
                  submitErrors.password &&
                  !$v.password.required
                "
                >Password is required</span
              >
              <!-- LOGIN: password[ERROR]: Show when there is a invalidation -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.password.error }"
                v-if="$v.password.required && !$v.password.minLength"
                >minimum 8 Characters Long
              </span>
            </div>
            <!-- SIGNUP : CONFIRM PASSWORD GROUP -->
            <div class="form-group">
              <span class="auth__label">Confirm Password</span>
              <input
                type="password"
                class="auth__input"
                placeholder="***********"
                v-model="confirmPassword"
                v-focus="fieldErrors.confirmPassword"
                @focus="fieldErrors.confirmPassword = true"
                @blur="fieldErrors.confirmPassword = false"
              />
              <!-- LOGIN: confirmPassword[ERROR]: Show when element is focused -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.confirmPassword.error }"
                v-if="
                  !$v.confirmPassword.required && fieldErrors.confirmPassword
                "
                >Confirm Password is required
              </span>
              <!-- LOGIN: confirmPassword[ERROR]: Show when submit button is pressed -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.confirmPassword.error }"
                v-if="
                  !fieldErrors.confirmPassword &&
                  submitErrors.confirmPassword &&
                  !$v.confirmPassword.required
                "
                >Confirm Password is required</span
              >
              <!-- LOGIN: confirmPassword[ERROR]: Show when there is invalidation -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.password.error }"
                v-if="
                  $v.confirmPassword.required &&
                  !$v.confirmPassword.sameAsPassword
                "
              >
                Should be same as Password
              </span>
            </div>
            <!-- SIGNUP: NAME GROUP -->
            <div class="form-group">
              <span class="auth__label">What Should we call you?</span>
              <input
                type="text"
                class="auth__input"
                name="first-name"
                placeholder="e.g. Amelia Earhart, Ama, AE"
                v-model.trim="firstName"
                v-focus="fieldErrors.firstName"
                @focus="fieldErrors.firstName = true"
                @blur="fieldErrors.firstName = false"
              />
              <!-- LOGIN: firstName[ERROR]: Show when is focused -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.firstName.required }"
                v-if="!$v.firstName.required && fieldErrors.firstName"
                >Name is required</span
              >
              <!-- LOGIN: firstName[ERROR]: Show when submit button is pressed -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.firstName.error }"
                v-if="
                  !fieldErrors.firstName &&
                  submitErrors.firstName &&
                  !$v.firstName.required
                "
                >Name is required</span
              >
              <!-- LOGIN: firstName[ERROR]: Show when there is invalidation -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.firstName.required }"
                v-if="$v.firstName.required && !$v.firstName.minLength"
                >should be at least 2 words</span
              >
            </div>
            <!-- SIGNUP: Plan -->
            <div class="form-group">
              <span class="auth__label">Subscription Plan</span>
              <input
                type="text"
                class="auth__input"
                name="redeem-code"
                placeholder="Optional (fi34-ki89-u3u0-e59l)"
                v-model.trim="tjPlan"
                disabled
              />
            </div>
            <div class="pageError" v-if="this.pageError.length > 0">
              {{ this.pageError }}
            </div>
            <!-- SIGNUP : SUBMIT BUTTON GROUP -->
            <div class="form-group">
              <button
                class="ed-btn__blim auth-btn--lg"
                :disabled="isLoading"
                :style="{
                  opacity: isLoading ? 0.7 : 1,
                  cursor: isLoading ? 'default' : 'pointer',
                }"
              >
                Create Account
                <span
                  :style="{
                    opacity: isLoading ? 1 : 0,
                  }"
                  class="spinner-border spinner-border-sm"
                ></span>
              </button>
            </div>
          </form>
        </div>

        <!-------------------------- IF PARAMETER IS LOGIN---------------- -->
        <div class="auth" v-else>
          <div class="auth__header" v-if="otherSignupMethods">
            <hr class="auth__break" />
            <span class="auth__header__label">or Login with username and password</span>
          </div>
          <form @submit.prevent="onLogin" class="auth__form">
            <!-- LOGIN : USERNAME GROUP -->
            <div class="form-group">
              <span class="auth__label">Username</span>
              <input
                type="text"
                :maxlength="max"
                class="auth__input"
                name="username"
                placeholder="johndoo"
                v-model="username"
                v-focus="fieldErrors.username"
                @focus="fieldErrors.username = true"
                @blur="fieldErrors.username = false"
                autocomplete="off"
              />
              <!-- LOGIN: username[ERROR]: Show when element is focused -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.username.error }"
                v-if="!$v.username.required && fieldErrors.username"
                >username is required</span
              >
              <!-- LOGIN: username[ERROR]: Show when submit button is pressed -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.username.error }"
                v-if="
                  !fieldErrors.username &&
                  submitErrors.username &&
                  !$v.username.required
                "
                >username is required</span
              >
              <!-- LOGIN: username[ERROR]: Show when there is a invalidation -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.username.error }"
                v-if="$v.username.required && !$v.username.usernameValidator"
                >Should be alphanumeric and cannot use special characters other
                than: . - _</span
              >
            </div>
            <!-- LOGIN : PASSWORD GROUP -->
            <div class="form-group">
              <span class="auth__label">Password</span>
              <div class="auth__password-box">
                <input
                  :type="showPassword ? 'text' : 'password'"
                  class="auth__password-box__field"
                  placeholder="***********"
                  v-model="password"
                  v-focus="fieldErrors.password"
                  @focus="fieldErrors.password = true"
                  @blur="fieldErrors.password = false"
                />
                <i
                  class="auth__password-box__icon"
                  :title="showPassword ? 'Hide Password' : 'Show Password'"
                  :class="showPassword ? 'bx bx-hide' : 'bx bx-show'"
                  @click="toggleShowPassword"
                />
              </div>
              <!-- LOGIN: password[ERROR]: Show when element is focused -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.password.error }"
                v-if="fieldErrors.password && !$v.password.required"
                >password is required
              </span>
              <!-- LOGIN: password[ERROR]: Show when submit is Clicked -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.password.error }"
                v-if="
                  !fieldErrors.password &&
                  !$v.password.required &&
                  submitErrors.password
                "
                >password is required</span
              >
              <!-- LOGIN: password[ERROR]: Show when validation is incorrect -->
              <span
                class="auth__notifier"
                :class="{ shake: !$v.password.error }"
                v-if="$v.password.required && !$v.password.minLength"
                >minimum 8 Characters Long
              </span>
            </div>
            <div class="pageError" v-if="this.pageError.length > 0">
              {{ this.pageError }}
            </div>
            <!-- LOGIN : SUBMIT BUTTON GROUP -->
            <div class="form-group">
              <button
                class="auth-btn auth-btn--lg"
                :disabled="isLoading"
                :style="{
                  opacity: isLoading ? 0.7 : 1,
                  cursor: isLoading ? 'default' : 'pointer',
                }"
              >
                Login
                <span
                  :style="{
                    opacity: isLoading ? 1 : 0,
                  }"
                  class="spinner-border spinner-border-sm"
                ></span>
              </button>
            </div>
            <!-- LOGIN : FORGOT PASSWORD -->
            <div class="form-group">
              <button
                type="button"
                class="no-border-btn"
                @click="forgotPasswordRedirect"
              >
                Forgot Password ?
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';
import {
  required, sameAs, email, minLength,
} from 'vuelidate/lib/validators';
import { focus } from 'vue-focus';
// Widget GIF
import Widgets from '@/assets/images/components/Widgets.gif';
// Books GIF
import Books from '@/assets/images/components/Books.gif';
// Search GIF
import Search from '@/assets/images/components/search.gif';
// SearchDepth GIF
import SearchDepth from '@/assets/images/components/SearchDepth.gif';
// Notes GIF
import Notes from '@/assets/images/components/Notes.gif';
// VisualiseData GIF
import VisualiseData from '@/assets/images/components/VisualiseData.gif';
// Background GIF
import Background from '@/assets/images/backgrounds/background-1.jpg';

/**
 * fn: usernameValidator
 * Params
 *  value: String
 * return
 *  test: Boolean
 */
const usernameValidator = (value) => {
  const reg = /^[A-Za-z][A-Za-z0-9_.-]{2,30}$/;
  return reg.test(value);
};

export default {
  name: 'auth-page',
  metaInfo: {
    title: 'Login / Signup',
  },
  directives: {
    focus,
  },
  data() {
    return {
      mode: 'login',
      otherSignupMethods: true,
      baseURI: process.env.VUE_APP_AXIOS_URI_BASE,
      max: 100,
      showPassword: false,
      backgroundUrl: Background,
      slide: 5,
      firstName: '',
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      errors: [],
      isLoading: false,
      pageError: '',
      tjKey: '',
      tjPlan: 'TJ Pro - $̶2̶0̶ $0 per Month (100% OFF Applied)',
      fieldErrors: {
        firstName: false,
        username: false,
        email: false,
        password: false,
        confirmPassword: false,
      },
      submitErrors: {
        firstName: false,
        username: false,
        email: false,
        password: false,
        confirmPassword: false,
      },
      slides: [
        {
          title: {
            primary: 'Explore',
            secondary: 'Search',
          },
          description:
            'Search efficiently, explore linked resources. Search contextually on the internet and in your Thought Graphs.',
          img: Search,
        },
        {
          title: {
            primary: 'Introducing',
            secondary: 'Widgets',
          },
          description: 'Widgets are your canvas for any story you want.',
          img: Widgets,
        },
        {
          title: {
            primary: 'Explore according to',
            secondary: 'Search Depth',
          },
          description:
            'Choose the level of proficiency and depth you want to search. Dive as deep as you want to.',
          img: SearchDepth,
        },
        {
          title: {
            primary: 'Add your',
            secondary: 'Notes',
          },
          description:
            'Capture your thoughts as notes in various content forms. Pin extracts as notes from the internet.',
          img: Notes,
        },
        {
          title: {
            primary: 'Look at',
            secondary: 'Books Library',
          },
          description:
            'Enriched book reading experience, because we know books are our best friend.',
          img: Books,
        },
        {
          title: {
            primary: 'Visualise',
            secondary: 'Thoughts',
          },
          description: 'Use graphs & visualisation to tell a unique story.',
          img: VisualiseData,
        },
      ],
    };
  },
  components: {
    Carousel3d,
    Slide,
  },
  methods: {
    cleanUp() {
      this.username = '';
      this.password = '';
      this.username = '';
      this.password = '';
      this.confirmPassword = '';
      this.showPassword = false;
    },
    // clearFieldErrors() {
    //   Object.keys(this.submitErrors).map((each) => {
    //     this.fieldErrors[each] = false;
    //     return each;
    //   });
    // },
    onLogin() {
      this.$v.$touch();
      const fields = ['username', 'password'];
      // this.clearFieldErrors();
      if (!this.$v.username.$invalid && !this.$v.password.$invalid) {
        this.toggleLoading();
        this.$store
          .dispatch('auth/login', {
            username: this.username,
            password: this.password,
          })
          .then(() => {
            this.toggleLoading();
            setTimeout(() => {
              this.cleanUp();
            }, 4000);
            this.successRedirect();
          })
          .catch((err) => {
            this.toggleLoading();
            this.logPageError(err.response.data.message);
          });
      } else {
        // this.logPageError("!Fields can't be empty");
        const invalidFields = Object.keys(this.$v.$params).filter(
          (fieldName) => this.$v[fieldName].$invalid,
        );
        invalidFields.forEach((each) => {
          if (fields.includes(each)) {
            this.submitErrors[each] = true;
            setTimeout(() => {
              this.submitErrors[each] = false;
            }, 2000);
          }
        });
      }
    },
    onSignup() {
      this.$v.$touch();
      if (
        !this.$v.username.$invalid
        && !this.$v.email.$invalid
        && !this.$v.password.$invalid
        && !this.$v.confirmPassword.$invalid
        && !this.$v.firstName.$invalid
      ) {
        this.toggleLoading();
        this.$store
          .dispatch('auth/signUp', {
            firstName: this.firstName,
            username: this.username,
            password: this.password,
            email: this.email,
            tjKey: this.tjKey,

          })
          .then(() => {
            this.toggleLoading();
            setTimeout(() => {
              this.cleanUp();
            }, 4000);
            this.successRedirect();
          })
          .catch((err) => {
            this.toggleLoading();
            this.logPageError(err.response.data.message);
          });
      } else {
        // this.logPageError("!Fields can't be empty");
        const invalidFields = Object.keys(this.$v.$params).filter(
          (fieldName) => this.$v[fieldName].$invalid,
        );
        invalidFields.forEach((each) => {
          this.submitErrors[each] = true;
          setTimeout(() => {
            this.submitErrors[each] = false;
          }, 2000);
        });
      }
    },
    successRedirect() {
      this.$router.push(this.$route.query.redirectTo || { name: 'Home' });
    },
    forgotPasswordRedirect() {
      this.$router.push({ name: 'Forgot-Password' });
    },
    loginHandler() {
      this.$store.dispatch('auth/login', this.user).then(() => {
        this.successRedirect();
      });
    },
    toggleLoading() {
      this.isLoading = !this.isLoading;
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    changeRedirect(action) {
      this.cleanUp();
      this.mode = action;
      const redirectTo = this.$route.query.redirectTo || {
        name: 'Auth',
        params: { action },
      };
      this.$router.push(redirectTo);
    },
    logPageError(err) {
      this.pageError = err;
    },
    onSlideMove() {
      document.querySelector('.carousel-3d-slide.current');
    },
    focus(field) {
      this.fieldErrors[field] = true;
    },
  },
  mounted() {
    const { action } = this.$route.params;
    if (action === 'signup') {
      this.mode = 'signup';
    } else if (action === 'signup-bootcamp24') {
      this.mode = 'signup-bootcamp24';
    } else {
      this.mode = 'login';
    }
  },
  validations: {
    firstName: {
      required,
      minLength: minLength(2),
    },
    username: {
      required,
      usernameValidator,
    },
    password: {
      required,
      minLength: minLength(8),
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs('password'),
    },
    email: {
      required,
      email,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/abstracts/variables";

.carousel-3d-container {
  height: auto !important;
  padding-top: 30px;
  user-select: none;
  .carousel-3d-slider {
    height: 40rem !important;
  }
  .carousel-3d-slide {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 77rem !important;
    height: 40rem !important;
    background: none;
    border: none;
    // box-shadow: 0 0 30px 15px rgb(0 0 0 / 20%);
    .title {
      font-size: 1.375rem;
    }
  }
  .right-1 {
    transform: translateX(250px) translateZ(25px) rotateY(-60deg) !important;
  }
  .left-1 {
    transform: translateX(-800px) translateZ(80px) rotateY(60deg) !important;
  }
  .carousel-3d-slide.current {
    transform: translateX(-285px) !important;
    // width: 576px !important;
    .introduction {
      display: block;
      opacity: 1;
      transition: all 0.8s ease-in-out;
    }
  }
  img {
    width: 60% !important;
    // height: 100%;
    border-radius: 0.3rem;
    border: none;
    box-shadow: 0 0 30px 15px rgba(0, 0, 0, 0.2);
  }
}

.introduction {
  opacity: 0;
  font-size: 0.8rem;
  text-align: center;
  width: 50%;
  position: absolute;
  top: 1.5rem;
  &__title {
    &--primary {
      color: var(--white);
    }
    &--secondary {
      color: #f3d250;
    }
  }

  &__desc {
    color: var(--offWhite);
  }
}

.pageError {
  color: var(--accentPink);
  margin-bottom: 0.2em;
  font-size: 0.9em;
}

.no-border-btn {
  border: none;
  color: var(--edvantaBlue);
  background: none;
}
// image background -> HERE
// linear-gradient(to bottom, rgba(255,255,255, 0.95), rgba(255,255,255, 0.95)),url(${backgroundUrl})
</style>
