const universalMap = [{
  hotKey: 'alt+shift+1',
  mode_name: 'default',
}, {
  hotKey: 'alt+shift+2',
  mode_name: 'explore',
}, {
  hotKey: 'alt+shift+1',
  mode_name: 'looked_at',
}, {
  hotKey: 'alt+shift+3',
  mode_name: 'add_nodes',
}, {
  hotKey: 'alt+shift+4',
  mode_name: 'add_relationships',
}, {
  hotKey: 'alt+shift+5',
  mode_name: 'remove',
}, {
  hotKey: 'alt+shift+6',
  mode_name: 'hsl',
}, {
  hotKey: 'alt+shift+7',
  mode_name: 'combo_explore',
}, {
  hotKey: 'alt+shift+8',
  mode_name: 'combo_expand_collapse',
}, {
  hotKey: 'alt+shift+0',
  mode_name: 'clear_graph',
}, {
  hotKey: 'alt+shift+f',
  mode_name: 'function_call',
}];

export default universalMap;
