<template>
  <div class="dropdown" v-click-outside="closeMenu">
    <a
      class="d-flex user-settings"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <div class="logout__menu d-flex align-items-center">
        <div class="user-profile">
          <img
          v-if="imageUrl !== null"
          :src="imageUrl"
          :alt="username"
          class="user-profile__img"
        />
        <img
          v-else
          src="https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
          :alt="username"
          class="user-profile__img"
          @click="toggleMenu"
        />
        </div>
        <i class="bx bx-chevron-down box-icon" @click="toggleMenu"></i>
      </div>
    </a>
    <div
      class="user-dropdown-menu dropdown-menu-right"
      :class="
        isMenuOpen
          ? 'user-dropdown-menu--active'
          : 'user-dropdown-menu--inactive'
      "
      aria-labelledby="dropdownMenuButton1"
    >
      <div class="user-dropdown-menu__user-info">
        <span class="username">{{ username }}</span>
        <span class="email">{{ email }}</span>
      </div>
      <hr class="user-dropdown-menu__user-info__seperator" />
      <div class="user-dropdown-menu__points-panel">
        <div class="user-points">
          <span class="user-points__header">{{ username }}'s Points:</span>
          <span class="user-points__count">{{ userPoints }}</span>
        </div>
        <div class="user-position">
          <span
            class="user-position__value ed-badge__blim"
            v-for="role in roles"
            :key="role"
            >{{ role }}</span
          >
        </div>
        <!-- <div class="user-leaderboard__container">
          <div
            class="user-leaderboard__task"
            v-for="leaderboard in leaderboards"
            :key="leaderboard.title"
          >
            <span class="user-leaderboard__task-title">
              {{ leaderboard.title }}
            </span>
            <ul class="user-leaderboard__task-info">
              <li
                class="user-leaderboard__task-info-item"
                v-for="task in leaderboard.tasks"
                :key="task"
              >
                {{ task }}
              </li>
            </ul>
          </div>
        </div> -->
      </div>
      <div class="user-dropdown-menu__footer">
        <button class="ed-btn__blim ed-btn__sm" @click="editProfile()">
          Edit Profile
        </button>
        <button class="ed-btn ed-btn__sm" @click="logout()">
          {{ $t("comp_logout--text") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import clickOutside from '@/directives/click-outside';

export default {
  name: 'userMenu',
  directives: {
    clickOutside,
  },
  props: {
    imageUrl: {
      type: String,
      default:
        'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png',
    },
    username: {
      type: String,
      default: 'NA',
    },
    email: {
      type: String,
      default: 'NA',
    },
    roles: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isMenuOpen: false,
      leaderboards: [
        {
          title: 'New Goals',
          tasks: ['Objective 1', 'Objective 2', 'Objective 3'],
        },
        {
          title: 'Completed Goals',
          tasks: ['Objective 1', 'Objective 2', 'Objective 3'],
        },
        {
          title: 'Aquired Skills',
          tasks: ['Objective 1', 'Objective 2', 'Objective 3'],
        },
      ],
    };
  },
  mounted() {
    this.fetchPoints();
  },
  computed: {
    userPoints() {
      const points = this.$store.getters['points/getPoints'];
      return points;
    },
  },
  methods: {
    ...mapActions({
      logoutAction: 'auth/logout',
      pointsAction: 'points/setPoints',
    }),
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      this.fetchPoints();
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    editProfile() {
      this.$router.push({ name: 'edit-profile' });
    },
    logout() {
      this.logoutAction().then(() => {
        this.$router.push({ name: 'Auth', params: { action: 'Login' } });
      });
    },
    async fetchPoints() {
      const data = await this.pointsAction(this.username);
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-dropdown-menu {
  position: absolute;
  background: var(--white);
  border-radius: 0.3rem;
  left: unset;
  right: 0;
  top: 3.6rem;
  border: none;
  box-shadow: 0px 0px 6px 0.1px var(--shadow-1);
  justify-content: left;
  flex-direction: column;
  min-width: 20vw;
  padding: 1rem;
  padding-bottom: 5rem;
  //   height: 65vh;
  user-select: none;

  &--active {
    display: flex;
    animation: open 300ms ease-in-out forwards;
    &__user-info {
        opacity: 0;
        animation: show-up 1s ease-in-out forwards;
    }
  }
  &--inactive {
    // display: none;
    animation: close 600ms ease-in-out forwards;
    &__user-info {
        animation: show-off 100ms ease-in-out;
    }
  }

  &__user-info {
    cursor: default;
    color: var(--black);
    //   font-weight: 300;
    display: none;
    flex-direction: column;
    align-items: center;
    &__seperator {
      display: none;
    }
    .username {
      font-weight: 400;
      font-size: 1.2rem;
      font-style: italic;
      &::before {
        content: "@";
      }
    }
    .email {
      font-size: 1rem;
    }
  }

  &__points-panel {
    cursor: default;
    // max-height: 15em;
    overflow: auto;
    overflow-x: hidden;
    .user-points {
      margin-bottom: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      &__header {
        font-weight: 600;
        margin-right: 0.3em;
      }
      &__count {
        font-weight: 400;
        font-size: 4rem;
      }
    }
    .user-position {
      display: flex;
      justify-content: center;
    }

    .user-leaderboard {
      &__container {
        margin-top: 1rem;
      }
      &__task-title {
        font-weight: 600;
        font-size: 1.2rem;
      }
      &__task-info {
        list-style: none;
        padding-left: 0;
      }
      &__task-info-item {
        &::before {
          content: ">";
          margin-right: 0.3rem;
        }
      }
    }
  }

  &__footer {
    position: absolute;
    // border-top: .1rem solid var(--shadow-1);
    box-shadow: 0px -0.1rem 0.1rem var(--shadow-2);
    width: 100%;
    left: 0;
    bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    flex-wrap: wrap;
  }
}

@keyframes open {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  95% {
    transform: translateY(0);
    // opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes close {
  0% {
    transform: translateY(0);
  }
  95% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    visibility: hidden;
    transform: translateY(-100%);
    opacity: 0;
  }
}

@keyframes show-up {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes show-off {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.logout__avatar {
  vertical-align: middle;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}

.logout__menu {
  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 1260px) {
  .user-dropdown-menu {
    &__user-info {
      display: flex;

      &__seperator {
        display: block;
      }
    }
  }
}
</style>
