<template>
  <form class="d-flex mx-1" v-on:submit.prevent="fetchInformation()">
    <vue-simple-suggest
      type="search"
      :placeholder="$t('comp_navbar--searchPlaceholder')"
      aria-label="Search"
      v-model="searchWord"
      :debounce="200"
      :controls="{
        selectionUp: [38, 33],
        selectionDown: [40, 34],
        select: [13, 36],
        showList: [40],
        hideList: [27, 35],
      }"
      :nullable-select="true"
      ref="suggestComponent"
      value-attribute="id"
      display-attribute="text"
      @select="onSuggestSelect"
      :class="customClass"
      :list="getAutoCompleteSuggest"
    ></vue-simple-suggest>
  </form>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import VueSimpleSuggest from 'vue-simple-suggest';

import 'vue-simple-suggest/dist/styles.css';

export default {
  data() {
    return {
      searchWord: '',
    };
  },

  props: {
    customClass: {
      type: String,
      default: 'input-wrap',
    },
  },

  computed: {
    ...mapState(['suggestionData', 'autocompleteSuggestionData']),
  },

  created() {
    this.$highway.singleDispatcher('searchEngine/setKeyword');
  },
  watch: {
    ...mapActions(['suggestions', 'autoCompleteSuggestion']),
    async searchWord(payload) {
      this.autoCompleteSuggestion(payload);
    },
  },

  components: {
    VueSimpleSuggest,
  },

  methods: {
    ...mapActions(['suggestions', 'autoCompleteSuggestion']),
    getAutoCompleteSuggest() {
      return this.autocompleteSuggestionData;
    },
    getSuggestionList() {
      this.suggestions(this.searchWord);
      return this.suggestionData;
    },
    // onSuggestClick() {
    //   this.fetchInformation();
    // },
    onSuggestSelect(data) {
      if (data) {
        this.searchWord = data;
      }
      this.fetchInformation();
    },
    fetchInformation() {
      if (this.searchWord !== '' && this.searchWord) {
        // keep it clean
        const wordObj = {
          word: this.searchWord, label: this.searchWord, type: 'string', lang: this.$store.state.locale.currentLocale,
        };
        // this.searchWord = '';

        // this.$store.commit('SET_CURRENT_ENTITY', wordObj);
        this.$store.dispatch('setCurrentThought', { thoughtObject: wordObj });
        // document.getElementById('gsc-i-id1').value = this.searchWord;
        // document.querySelector('.gsc-search-button.gsc-search-button-v2').click();

        // this.$store.dispatch('askWolfram', this.searchWord);
      }
      this.$store.dispatch('eventToEventStore', {
        verb: 'SEARCHED_FOR',
        value: {
          searchEntity: this.searchWord,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
