<template>
  <ed-dropdown
    :selected="selectedLanguage"
    :listItems="languages"
    :mapFunction="(locale) => locale.language"
    :isSelected="onChange"
    customClass="ed-dropdown__sm"
  />
</template>

<script>
import edDropdown from './ed-forms/ed-dropdown.vue';

export default {
  name: 'LanguageSelector',
  components: {
    edDropdown,
  },
  data() {
    return {
      selectedLanguage:
        localStorage.getItem('locale')
        || this.$store.state.locale.currentLocale
        || 'en',
    };
  },

  computed: {
    languages() {
      return this.$store.state.locale.availableLocales;
    },
  },

  methods: {
    onChange(tab) {
      this.$store.dispatch('changeLocale', tab);
      this.$i18n.locale = tab;
      this.selectedLanguage = tab;
    },
  },
  convertLanguageList(langList) {
    const newList = [];
    langList.map((each) => {
      newList.push(each.language);
      return each;
    });

    return newList;
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/abstracts/variables";
.lang-select {
  width: 5em !important;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  padding: 0.5em;
  border-radius: 5px;
  transition: all ease-in-out 200ms;

  &:hover {
    background-color: $edvantaButtonColor;
    cursor: pointer;
  }

  &:focus,
  &:active {
    outline: none;
    border: none;
    box-shadow: none;
  }
}
</style>
