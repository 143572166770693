export default {
  getData: (state) => state.test,
  getExploreData: (state) => state.g6_explore_data,
  getProps(state) {
    const props = [];
    state.g6_explore_data.nodes.forEach((i) => {
      if (i.props) {
        const propKeys = Object.keys(i.props);
        propKeys.forEach((l) => props.push(l));
      }
    });
    const propUnique = Array.from(new Set(props));
    return propUnique;
  },
  getDimensionArray: (state) => state.g6_explore_data.nodes.map((i) => i.label),
};
