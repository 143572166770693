export default {
  namespaced: true,

  state: {
    keyword: '',
  },

  getters: {
    getKeyword(state) {
      return state.keyword;
    },
  },

  actions: {
    setKeyword({ commit }, payload) {
      commit('SET_KEYWORD', payload.word);
    },
  },

  mutations: {
    SET_KEYWORD(state, keyword) {
      state.keyword = keyword;
    },
  },
};
