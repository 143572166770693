<template>
  <div class="d-flex flex-row align-items-center p-2 userinfo">
    <div class="d-flex flex-column justify-content-center align-items-end userinfo-content">
      <div class="userinfo-content__username">
        {{ username }}
      </div>
      <div class="userinfo-content__email">
        {{ email }}
      </div>
    </div>
    <UserMenu :imageUrl="imageUrl" :username="username" :email="email" :roles="roles"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserMenu from '@/common/components/UserMenu.vue';

export default {
  name: 'UserInfo',

  components: {
    UserMenu,
  },

  computed: {
    ...mapGetters({
      user: 'auth/authUser',
    }),

    imageUrl() {
      return this.user?.profileImage.Location;
    },

    username() {
      return this.user?.username || '';
    },

    email() {
      return this.user.email;
    },

    roles() {
      return this.user.roles;
    },
  },
};
</script>

<style lang="scss" scoped>
.userinfo {
  &-content {
    &__username {
      font-size: 1rem;
      font-weight: 500;
    }
    &__email {
      font-size: 0.9rem;
    }
  }
}

@media screen and (max-width: 1260px){
    .userinfo {
      &-content {
        display: none !important;
      }
    }
}
</style>
