import hotKeyMappings from '@/config/hotKeyMappings';
import universalMap from '@/config/universalMapping';

const fetchShortcut = (label, _key) => {
  const val = universalMap.filter((eachMap) => eachMap.mode_name === _key);
  const val2 = hotKeyMappings.filter((eachMap) => eachMap.mapping === _key);
  if (val.length > 0) {
    return `${label} (${val[0].hotKey})`;
  }
  if (val2.length > 0) {
    return `${label} (${val2[0].key})`;
  }
  return label;
};

export default {
  install(app) {
    // eslint-disable-next-line no-param-reassign
    app.prototype.$fetchShortcut = fetchShortcut;
  },
};
