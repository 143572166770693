function wikiWordFetch(event) {
  event.preventDefault();
  let txt = '';
  if (window.getSelection) {
    txt = window.getSelection();
  } else if (document.getSelection) {
    txt = document.getSelection();
  } else if (document.selection) {
    txt = document.selection.createRange().text;
  }
  return txt.toString().trim();
}

export default wikiWordFetch;
