import chroma from 'chroma-js';

export default ({
  currentWord, currentGraphData, userName, payload,
}) => {
  const newNodes = [...currentGraphData.nodes];
  const newEdges = [...currentGraphData.edges];
  const comboDup = [...currentGraphData.combos];

  const newCombos = comboDup.filter((item) => item.id !== payload.graphId);

  const combo = {
    id: payload.graphId,
    label: payload.graphName,
    tjData: payload,
    style: {
      lineWidth: 1,
      opacity: 0.3,
      fill: chroma.random().darken().hex(),
    },
  };

  let edge = {};

  const currentEntityInGraph = currentGraphData.nodes.find((el) => el.id === currentWord);

  if (currentEntityInGraph !== undefined) {
    edge = {
      source: payload.graphId,
      target: currentWord,
      label: `added by ${userName}`,
    };

    newEdges.push(edge);
  } else {
    const nodeEl = {
      id: currentWord,
      label: currentWord,
      type: 'circle',
      style: {
        fill: chroma.random().darken().hex(),
        stroke: '#fff',
      },
      cluster: currentWord,
    };

    newNodes.push(nodeEl);
    edge = {
      source: payload.graphId,
      target: currentWord,
      label: `added by ${userName}`,
    };

    newEdges.push(edge);
  }

  newCombos.push(combo);

  const data = {
    nodes: newNodes,
    edges: newEdges,
    combos: newCombos,
  };

  return data;
};
