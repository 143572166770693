import flagMapping from '../config/flagMapping';

const flagReader = (flags) => {
  const flagAbstracts = flags.trim().toLowerCase().split('-').slice(1);
  const flagList = {};
  flagAbstracts.forEach((flag) => {
    // console.log('flagReader', flag);
    flagList[flagMapping[flag.trim()]] = true;
  });
  return flagList;
};

export default flagReader;
