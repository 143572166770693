<template>
  <div class="d-flex">
    <a
      @click="toggleThoughtMapRecordingStatus()"
      class="position-relative bottom-dock-btn"
    >
      <i
        :name="recordIcon"
        :color="recordColor"
        :class="`bx bx-${recordIcon} broadcast ${recordColor}`"
        :animation="recordAnimation"
        :title="recordTitle"
      ></i>
    </a>
    <a
      @click="toggleReplay()"
      class="position-relative bottom-dock-btn"
      v-if="hasReplayMutations"
    >
      <i
        :name="replayIcon"
        :color="replayColor"
        :class="`bx bx-${recordIcon} broadcast ${recordColor}`"
        :animation="replayAnimation"
      ></i>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      speechData: '',
      mutationSeries: [],
      replayStatus: false, // false is paused
    };
  },
  computed: {
    isActive() {
      return this.$store.state.isThoughtMapRecording;
    },
    recordIcon() {
      return this.isActive ? 'book-bookmark' : 'bookmark-plus';
    },
    recordColor() {
      return this.isActive ? 'broadcast--active' : 'bradcast--inactive';
    },
    recordAnimation() {
      return this.isActive ? '' : '';
    },
    recordTitle() {
      return this.isActive ? 'Automatic Thought Map ON' : 'Thought Map in Manual';
    },
    replayIcon() {
      return this.replayStatus ? 'sun' : 'play';
    },
    replayColor() {
      return this.replayStatus ? '#5C5F58' : '#3fEEE6';
    },
    replayAnimation() {
      return this.replayStatus ? 'spin' : '';
    },

    hasReplayMutations() {
      return (
        this.$store.state.mutationSeries
        && this.$store.state.mutationSeries.length > 0
      );
    },
  },

  /* eslint-disable */
  mounted() {
    this.$root.$on('recordthoughtmap', this.toggleThoughtMapRecordingStatus);
  },
  beforeDestroy() {
    this.$root.$off('recordthoughtmap');
  },
  methods: {
    toggleThoughtMapRecordingStatus() {
      this.$store.dispatch('toggleThoughtMapRecordingState');
    },
    toggleRecordingToShare() {
      if (this.isActive) {
        this.endRecording();
      } else {
        this.startRecording();
      }
      this.isActive = !this.isActive;
    },
    async startRecording() {
      this.mutationSeries = [];
      this.startingState = await this.$store.dispatch('startThoughtRecord');
      let lastUpdateMillis = new Date().getTime();
      this.unsubscribe = this.$store.subscribe((mutation) => {
    
        const mutationSnapshotMillis = new Date().getTime();
        const mutationSnapshot = {
          mutation,
          timeDeltaMillis: mutationSnapshotMillis - lastUpdateMillis,
        };
        this.mutationSeries.push(mutationSnapshot);
        lastUpdateMillis = mutationSnapshotMillis;
      });
    },
    endRecording() {
      this.$store.dispatch('endThoughtRecord', {
        mutationSeries: this.mutationSeries,
        startingState: this.startingState,
      });
   
      // this.$store.dispatch('replayMutationSeries', [...this.mutationSeries]);
    },
    async toggleReplay() {
      this.replayStatus = true;
      await this.$store.dispatch(
        'replayMutationSeries',
        this.$store.state.mutationSeries,
      );
      this.replayStatus = false;
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss" scoped>
.voice-btn {
  border-radius: unset !important;
}

.voice-active {
  color: #0d6efd;
}

.voice-inactive {
  color: #000000;
}

.broadcast {
  font-size: 1.5rem;
  &--active {
    color: #0c706b;
  }
  &--inactive {
    color: #a5a5a5;
  }
}
</style>
