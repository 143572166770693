<template>
  <div class="d-flex">
    <li v-if="abilities.can('rearrange', 'Layout')" class="dock-icons" title="Edit Layout">
      <a @click="toggleEdit()" class="position-relative bottom-dock-btn">
        <i :class="`${edit ? 'bottom-dock-btn__icon--active' : 'bottom-dock-btn__icon--inactive'} bx bx-edit-alt bottom-dock-btn__icon`"></i>
      </a>
    </li>
    <li v-if="abilities.can('resize', 'Layout')" class="dock-icons" title="Resize Layout" >
      <a @click="toggleResize()" class="position-relative bottom-dock-btn">
        <i :class="`${resize ? 'bottom-dock-btn__icon--active' : 'bottom-dock-btn__icon--inactive'} bx bx-customize bottom-dock-btn__icon`"></i>
      </a>
    </li>
  </div>
</template>

<script>
import AbilityMixin from '@/mixins/AbilityMixin';

export default {

  name: 'ModifyLayout',
  mixins: [AbilityMixin],
  computed: {
    edit() {
      return this.$store.state.layouts?.edit;
    },

    resize() {
      return this.$store.state.layouts?.resize;
    },
  },

  mounted() {
    this.$root.$on('editLayout', this.toggleEdit);
    this.$root.$on('resizeLayout', this.toggleResize);
  },

  beforeDestroy() {
    this.$root.$off('editLayout');
    this.$root.$off('resizeLayout');
  },

  methods: {
    toggleEdit() {
      this.$store.dispatch('layouts/editLayout');
    },

    toggleResize() {
      this.$store.dispatch('layouts/resizeLayout');
    },
  },
};
</script>

<style lang="scss" scoped></style>
