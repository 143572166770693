<template>
  <div
    class="d-inline-flex sidebar-dark flex-column flex-shrink-0 text-white sidebar-fn sidebar-width"
    :class="{
      'sidebar-open': this.sidebarToggleStatus,
      'sidebar-close': !this.sidebarToggleStatus,
    }">
    <!-- <a
      href="/"
      class="d-flex align-items-center mb-3 mb-md-0 text-white text-decoration-none justify-content-center px-3 py-3 navbar-logo"
    >
    <img src="@/assets/images/logo.svg" alt="Thoughtjumper" class="nav-middle__logo"/>
    </a> -->
    <!-- <hr /> -->
    <ul class="nav nav-pills flex-column mb-auto">
      <li class="nav-item" v-for="route in routes" :key="route.id">
        <!-- this link will only be active at `/` -->
        <!-- <router-link to="/" exact></router-link> -->
        <!-- NOTE: The default active class matching behavior is inclusive match. For example,
        <router-link to="/a"> will get this class applied as long as the current path starts with /a/ or is /a. -->
        <router-link
          :to="route.uri"
          class="d-flex nav-item__link"
          aria-current="page"
          activeClass="nav-item__link--active"
          exact
        >
          <i
            :class="`nav-item__icon bx bx-${route.icon.name}`"
          ></i>
          {{ $t(route.name) }}
        </router-link>
      </li>
    </ul>

    <hr />
    <!-- Links that are static or not used often are places at bottom -->
    <!-- <ul class="pageList">
      <li v-for="page in staticPages" :key="page.id" class="pageList__item">
        <router-link :to="page.uri">
          {{ $t(page.name) }}
        </router-link>
      </li>
    </ul> -->
    <Footer/>
  </div>
</template>

<script>
import Footer from '@/common/components/Footer.vue';

export default {
  name: 'Sidebar',

  components: {
    Footer,
  },

  data() {
    return {
      routes: [
        {
          id: 1,
          name: 'comp_sidebar--r1',
          icon: {
            name: 'home-alt',
          },
          uri: '/',
        },
        {
          id: 2,
          name: 'comp_sidebar--r2',
          icon: {
            name: 'stats',
          },
          uri: '/dashboard',
        },
        // {
        //   id: 3,
        //   name: 'comp_sidebar--r3',
        //   icon: {
        //     name: 'cube',
        //   },
        //   uri: '/VR',
        // },
        // {
        //   id: 4,
        //   name: 'comp_sidebar--r4',
        //   icon: {
        //     name: 'shapes',
        //     type: 'solid',
        //   },
        //   uri: '/ThreeDAssets',
        // },
        // {
        //   id: 5,
        //   name: 'comp_sidebar--r5',
        //   icon: {
        //     name: 'git-branch',
        //     // type: 'solid',
        //   },
        //   uri: '/snippet',
        // },
        {
          id: 6,
          name: 'comp_sidebar--r6',
          icon: {
            name: 'slider-alt',
          },
          uri: '/Settings',
        },
        {
          id: 8,
          name: 'comp_sidebar--r11',
          icon: {
            name: 'accessibility',
          },
          uri: '/accessibility',
        },
        // {
        //   id: 9,
        //   name: 'comp_sidebar--r9',
        //   icon: {
        //     name: 'rectangle',
        //   },
        //   uri: '/second-view',
        // },
        // {
        //   id: 10,
        //   name: 'comp_sidebar--r10',
        //   icon: {
        //     name: 'rectangle',
        //   },
        //   uri: '/third-view',
        // },
      ],

      staticPages: [
        {
          id: 1,
          name: 'comp_sidebar--privacy',
          uri: '/privacyPolicy',
        },
        {
          id: 2,
          name: 'comp_sidebar--terms',
          uri: '/termsOfUse',
        },
      ],
    };
  },

  computed: {
    sidebarToggleStatus() {
      return this.$store.state.uiStates.sidebarToggleStatus;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/abstracts/variables";
.sidebar-dark{
  background-color: rgba($edvantaBlue, .05);
  opacity: 1;
  width: 15%;
  // min-width: 17rem;
}
.sidebar-fn {
  z-index: 4;
  position: fixed;
  top: 0;
  height: 100vh;
  padding-top: 10vh;
}

.sidebar-open {
  transform: translate(0%);
  transition: all ease-in-out 200ms;
}

.sidebar-close {
  transform: translate(-100%);
  transition: all ease-in-out 200ms;
}

.nav-item {
  margin-bottom: .5rem;

  &__link {
    color: $edvantaBlue;
    text-decoration: none;
    padding: .5rem;
    border-radius: $kRadius;

    &:hover {
      // color: $black;
      background-color: rgba($edvantaBlue, .06);
    }

    &--active {
      // color: $edvantaBlue;
      background-color: rgba($edvantaBlue, .06);
      // border: 0.1rem solid $lightGray;
    }

  }

  &__icon {
    fill: currentColor;
    color: currentColor;
    font-size: 1.5rem;
    margin-right: .5rem;
  }
}

.pageList {
  &__item {
    list-style: none;
    line-height: 1.4;
    margin-top: $kMediumSpace;
    margin-bottom: $kMediumSpace;

    a {
      text-decoration: none;
      color: rgba(255, 255, 255, 0.7);
      transition: all ease-out 200ms;
      font-size: small;
    }

    a:hover {
      color: rgba(255, 255, 255, 1);
      transition: all ease-in 200ms;
    }
  }
}

.navbar-logo{
  background-color: $white;
  border-radius: $kRadius;
}
</style>
