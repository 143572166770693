import G6 from '@antv/g6';
import graphReactor from './index';

/**
   * @fn graphInstance
   * @params
   *---------------------------------------- | -------------------------------------------|
   *  graphName:
   *    type: String
   *    desc: GraphName is a string which tell the name of instance
   *    sample: 'explore'
   *---------------------------------------- | -------------------------------------------|
   *
   * @return
   *  Graph Instance (watch out index.js and GraphOps in the same file)
  */
const graphInstance = (graphName) => {
  switch (graphName.toLowerCase()) {
    case 'explore': return 'exploreInstance';
    case 'Explore': return 'exploreInstance';
    case 'Linked': return 'linkedInstance';
    default: return null;
  }
};

/**
   * @fn graph
   * @params
   *---------------------------------------- | -------------------------------------------|
   *  graphName:
   *    type: String
   *    desc: GraphName is a string which tell the name of instance
   *    sample: 'explore'
   *---------------------------------------- | -------------------------------------------|
   *
   * @return
   *  Graph Instance (watch out index.js and GraphOps in the same file)
  */
const graph = (graphName) => {
  if (typeof graphName !== 'string') return graphName;
  const instance = graphInstance(graphName);
  return graphReactor[instance];
};

G6.registerNode('tj-node', {
  options: {
    style: {},
    stateStyles: {
      hover: {
        fill: '#B22222',
      },
      selected: {},
    },
  },
  draw(cfg, group) {
    const width = 53; const
      height = 27;
    // const style = cfg.style || {};

    const keyShape = group.addShape('rect', {
      attrs: {
        x: -width * 0.55,
        y: -height * 0.6,
        width: width * 1.1,
        height: height * 1.2,
        fill: '#FFA07A',
        opacity: 0.9,
        lineWidth: 0,
        radius: (height / 2 || 13) * 1.2,
      },
      name: 'halo-shape',
      draggable: true,
    });

    return keyShape;
  },
});

const GraphInitializer = (payloads, graphName) => {
  /**
   * @props:
   *  payloads:
   * graph}
  */
  const instance = graphInstance(graphName);
  if (!graphReactor[instance]) {
    const newGraphInstance = new G6.Graph(payloads);
    graphReactor[graphInstance(graphName)] = newGraphInstance;
  } else {
    // console.log('MY TOKEN', 'GRPAH ENGINE CAN\'T be re-init');
  }
};

const eventListener = (event, graphName, callback) => {
  const instance = graphInstance(graphName);
  if (graphReactor[instance] && graphReactor[instance] !== null) {
    graphReactor[instance].on(event, callback);
  }
};

const graphData = (graphName, payloads) => {
  const instance = graphInstance(graphName);
  if (graphReactor[instance] && graphReactor[instance] !== null) {
    graphReactor[instance].data(payloads);
  }
};

const graphOperation = (operation, graphName, ...payload) => {
  const instance = graphInstance(graphName);
  if (payload && payload.length > 0) {
    return graphReactor[instance][operation](...payload);
  }
  return graphReactor[instance][operation]();
};

export default {
  GraphInitializer,
  eventListener,
  graphData,
  graphOperation,
  graphInstance,
  graph,
};
