let store;
let socket;

/*
  Audio Recording stream functions ----------------------
*/
// get audio stream
async function createAudioStream() {
  const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true }).catch((e) => { throw e; });
  return audioStream;
}

// get audio track
function getAudioTrack(audioStream) {
  return audioStream.getAudioTracks()[0];
}

/*
  Screen Recoding functions -----------------------------
*/
// saving the recorded stream
// function saveFile(recordedChunks, mimeType) {
//   console.log('Recorded chunks', recordedChunks);
//   const blob = new Blob(recordedChunks, {
//     type: mimeType,
//   });
//   store.dispatch('uploadToAWS', blob).then(() => {
//     console.log('Upload successful');
//   });
// }

function saveScreenRecording(recordedChunks, mimeType) {
  return new Promise((resolve, reject) => {
    const blob = new Blob(recordedChunks, {
      type: mimeType,
    });
    store.dispatch('uploadToAWS', blob).then((data) => {
      console.log('RECORDER', 'uploadeToAWS is here');
      // console.log('xvf', data);
      store.dispatch('addNoteToNotesLibrary', data);
      socket.emit('add_note', data);
      resolve(true);
    }).catch((error) => {
      reject(error);
    });
  });
}

// converting the stream to a file
function createRecorder(stream, mimeType) {
  // the stream data is stored in this array
  let recorderChunks = [];

  const mediaRecorder = new MediaRecorder(stream);

  mediaRecorder.ondataavailable = (e) => {
    if (e.data.size > 0) {
      recorderChunks.push(e.data);
    }
  };

  // mediaRecorder.onstop = () => {
  //   console.log('Recording stop');
  //   saveFile(recorderChunks, mimeType);
  //   recorderChunks = [];
  // };

  // mediaRecorder.onstop = () => new Promise((resolve, reject) => {
  //   saveScreenRecording(recorderChunks, mimeType).then((data) => {
  //     console.log('RECORDER', 'Reaching here');
  //     recorderChunks = [];
  //     resolve(data);
  //   }).catch((error) => {
  //     reject(error);
  //   });
  // });

  mediaRecorder.stopRecording = () => new Promise((resolve, reject) => {
    saveScreenRecording(recorderChunks, mimeType).then((data) => {
      console.log('RECORDER', 'Reaching here');
      recorderChunks = [];
      mediaRecorder.stop();
      const tracks = mediaRecorder.stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });

  mediaRecorder.start(200);
  // For every 200ms the stream data will be stored in a seperate chunk.

  return mediaRecorder;
}

// -------------- START RECORDING ----------------------- //
// recording the screen
async function screenRecorder() {
  const stream = await navigator.mediaDevices.getDisplayMedia({
    audio: true,
    video: { mediaSource: 'screen' },
  });

  // get the audio from microphone
  const audioStream = await createAudioStream();
  stream.addTrack(getAudioTrack(audioStream));

  return stream;
}

export default {
  install(app, options) {
    store = options.store;
    socket = options.socket;
    const appInstance = app;
    appInstance.prototype.$screenRecorderPlugin = {
      screenRecorder,
      createRecorder,
      saveScreenRecording,
    };
  },
};
