export default {
  EDIT_LAYOUT: (state) => {
    state.edit = !state.edit;
  },

  RESIZE_LAYOUT: (state) => {
    state.resize = !state.resize;
  },

  SET_UPDATED_VIEW: (state, updatedView) => {
    state.view = updatedView;
  },
};
