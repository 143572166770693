import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true, // works for actions, mutations and getters... because for state its namespaced by default
  state: {
    goals: [],
    attachmentModal: false,
    addModal: false,
    isLoading: false,
    selectedGoal: {},
  },
  actions,
  mutations,
  getters,
};
