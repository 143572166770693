export default {
  fetchGoalWithId: (state) => (goalId) => {
    const myGoals = [...state.goals];

    const goalWithId = myGoals.filter((each) => each.id === goalId);
    if (goalWithId.length > 0) {
      return goalWithId[0];
    }
    return [];
  },
  fetchCompletedGoals: (state) => {
    const myGoals = [...state.goals];
    const completedGoals = myGoals.filter((goal) => goal.status);
    return completedGoals;
  },
  fetchIncompleteGoals: (state) => {
    const myGoals = [...state.goals];
    const incompleteGoals = myGoals.filter((goal) => !goal.status);
    return incompleteGoals;
  },
};
