<template>
  <DraggableDiv>
      <template slot="main">
        <Webcam />
      </template>
    </DraggableDiv>
</template>

<script>
import DraggableDiv from '@/common/components/DraggableDiv.vue';
import Webcam from '@/common/components/Webcam.vue';

export default {
  name: 'Camera',

  components: {
    DraggableDiv,
    Webcam,
  },
};
</script>

<style lang="scss" scoped>

</style>
