<template>
  <div class="sidebar-toggle" title="Sidebar" :class="isSidebarOpen ? 'sidebar-toggle__active' : 'sidebar__inactive'">
    <i
      class="sidebar-toggle-btn d-flex bx bx-menu-alt-left"
      title="Sidebar Button"
      @click="openSidebar()"
    ></i>
  </div>
</template>

<script>
export default {
  name: 'SidebarToggle',
  data() {
    return {
      isSidebarOpen: false,
    };
  },

  mounted() {
    this.$root.$on('sidebar', this.openSidebar);
  },
  beforeDestroy() {
    this.$root.$off('sidebar');
  },
  methods: {
    openSidebar() {
      this.$store.dispatch('toggleSidebar', true);
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
