let store;

function updateEdgeType(graph, type) {
  graph.getEdges().forEach((edge) => {
    edge.update({
      type,
      labelCfg: {
        position: 'top',
        refY: -10,
        color: '#fff',
        style: {
          fontSize: 15,
          fill: '#fff',
          fontWeight: 500,
          fontFamily: 'Poppins',
          color: '#fff',
        },
      },
    });
  });
}

/**
 * fn loadGraphConfigs
 * desc
 *  load Graph config is a Vue plugin used to track
 *  Graph Configs for all types of Graphs and change
 *  its properties according to change in properties
 *  in Node Operations
 */
async function loadGraphConfigs(graph) {
  // subscribe store to track mutation
  store.subscribe(async (mutation) => {
    // if mutation type is 'SET_GRAPH_PROPERTIES'
    if (mutation.type === 'SET_GRAPH_PROPERTIES' && store.state.graphConfigs) {
      // console.log('graph properties', mutation.payload.property, graph);
      const { property, layout } = mutation.payload;
      const { graphConfigs } = store.state;
      if (property === 'layoutType') {
        const currentLayout = graphConfigs[property].toLowerCase();
        if (graphConfigs[property].toLowerCase() === 'gforce') {
          graph.updateLayout({
            type: graphConfigs[property],
            edgeStrength: graphConfigs[currentLayout].edgeStrength,
            nodeStrength: graphConfigs[currentLayout].nodeStrength,
            linkDistance: graphConfigs[currentLayout].linkDistance,
            preventOverlap: graphConfigs[currentLayout].preventOverlap,
          });

          updateEdgeType(graph, 'cubic-vertical');
        } else if (graphConfigs[property].toLowerCase() === 'force') {
          graph.updateLayout({
            type: graphConfigs[property],
            edgeStrength: graphConfigs[currentLayout].edgeStrength,
            linkDistance: graphConfigs[currentLayout].linkDistance,
            nodeStrength: graphConfigs[currentLayout].nodeStrength,
            nodeSize: graphConfigs[currentLayout].nodeSize,
            preventOverlap: graphConfigs[currentLayout].preventOverlap,
          });
        } else if (graphConfigs[property].toLowerCase() === 'dagre') {
          graph.updateLayout({
            type: graphConfigs[property],
            nodesep: graphConfigs[currentLayout].nodesep,
            ranksep: graphConfigs[currentLayout].ranksep,
            rankdir: graphConfigs[currentLayout].rankdir,
            align: graphConfigs[currentLayout].align,
          });

          updateEdgeType(graph, 'polyline');
        } else if (graphConfigs[property].toLowerCase() === 'concentric') {
          graph.updateLayout({
            type: graphConfigs[property],
            linkedDistance: graphConfigs[currentLayout].linkDistance,
            nodeSize: graphConfigs[currentLayout].nodeSize,
            sweep: graphConfigs[currentLayout].sweep,
          });
        } else if (graphConfigs[property].toLowerCase() === 'radial') {
          graph.updateLayout({
            type: graphConfigs[property],
            linkDistance: graphConfigs[currentLayout].linkDistance,
            maxIteration: graphConfigs[currentLayout].maxIteration,
            unitRadius: graphConfigs[currentLayout].unitRadius,
            focusNode: graphConfigs[currentLayout].focusNode,
            preventOverlap: graphConfigs[currentLayout].preventOverlap,
            nodeSize: graphConfigs[currentLayout].nodeSize,
          });
        } else if (graphConfigs[property].toLowerCase() === 'circular') {
          graph.updateLayout({
            type: graphConfigs[property],
            startRadius: graphConfigs[currentLayout].startRadius,
            endRadius: graphConfigs[currentLayout].endRadius,
            clockwise: graphConfigs[currentLayout].clockwise,
            preventOverlap: graphConfigs[currentLayout].preventOverlap,
          });
        } else if (graphConfigs[property].toLowerCase() === 'fruchterman') {
          graph.updateLayout({
            type: graphConfigs[property],
            gravity: graphConfigs[currentLayout].gravity,
            speed: graphConfigs[currentLayout].speed,
            clusterGravity: graphConfigs[currentLayout].clusterGravity,
            maxIteration: graphConfigs[currentLayout].maxIteration,
            preventOverlap: graphConfigs[currentLayout].preventOverlap,
          });
        } else if (graphConfigs[property].toLowerCase() === 'mds') {
          graph.updateLayout({
            type: graphConfigs[property],
            linkDistance: graphConfigs[currentLayout].linkDistance,
            preventOverlap: graphConfigs[currentLayout].preventOverlap,
          });
        } else if (graphConfigs[property].toLowerCase() === 'grid') {
          graph.updateLayout({
            type: graphConfigs[property],
            rows: graphConfigs[currentLayout].rows,
            cols: graphConfigs[currentLayout].cols,
            nodeSize: graphConfigs[currentLayout].nodeSize,
            preventOverlap: graphConfigs[currentLayout].preventOverlap,
          });
        } else {
          graph.updateLayout({
            type: graphConfigs[property],
          });
        }
      } else if (property === 'rankdir' || property === 'align' || property === 'focusNode') {
        graph.updateLayout({
          [property]: graphConfigs[layout][property],
        });
      } else {
        graph.updateLayout({
          [property]: parseInt(graphConfigs[layout][property], 10),
        });
      }
    }
  });
}

export default {
  install(app, options) {
    // configure the app
    if (!options || !options.store) {
      throw new Error('Please initialise plugin with a Vuex store');
    }
    store = options.store;
    // graph = options.graphReactor;
    const appInstance = app;
    appInstance.prototype.$graphLayoutConfig = {
      loadGraphConfigs,
    };
  },
};
