/* eslint-disable no-unused-vars */
const { AbilityBuilder, Ability } = require('@casl/ability');
const abilitiesJson = require('../../build_data/abilityMatrix.json');

const defineRolebilities = (role, can, cannot, rules, user) => {
  const roleMatrix = abilitiesJson[role];
  roleMatrix.forEach((ability) => {
    if (ability.value) {
      can(ability.action, ability.subject);
    } else {
      cannot(ability.action, ability.subject);
    }
  });
};

function defineAbilitiesFor(user) {
  const { can, cannot, rules } = new AbilityBuilder(Ability);

  const roles = user.roles || [];

  const rolesMatrix = Object.keys(abilitiesJson);
  defineRolebilities('Default', can, cannot, rules, user); // A starting set

  // Now for each type
  rolesMatrix.forEach((role) => {
    if (roles.includes(role)) {
      defineRolebilities(role, can, cannot, rules, user);
    }
  });

  return new Ability(rules);
}

module.exports = {
  defineAbilitiesFor,
};
