<template>
  <div class="dropdown" v-click-outside="closeDropdown">
    <i
      class="custom-box-css dropdown__toggle box-plussecondary box-icon bx bx-plus"
      type="button"
      title="Widget List"
      @click="toggleDropdown()"
    ></i>
    <div
      class="dropdown__menu"
      :class="
        mounted
          ? isDropDown
            ? 'dropdown__menu--show'
            : 'dropdown__menu--hidden'
          : ''
      "
    >
      <input
        type="text"
        class="dropdown__search"
        v-model="compfilter"
        placeholder="Search Here..."
      />
      <ul class="dropdown__list">
        <li
          class="dropdown__item dropdown__select-multiple d-flex align-items-center justify-content-between"
        >
          <label for="select-multiple" class="dropdown__link"
            >Select Multiple
          </label>
          <input
            type="checkbox"
            name="select-multiple"
            id="select-multiple"
            v-model="isMultiSelect"
            class="dropdown__select-multiple"
          />
        </li>
        <li class="dropdown__item" v-if="isMultiSelect">
          <button class="ed-btn ed-btn__sm" @click="addMultipleWidgets()">
            Add Widgets
          </button>
        </li>
        <li
          class="dropdown__item dropdown__item-added"
          v-for="(opt, key) in componentsFilter()"
          :key="key"
        >
          <span
            class="dropdown__link"
            @click="addLayout(layoutKey, opt.name)"
            :title="getShortcut(opt.name, opt.name)"
            >{{ opt.options.title }}</span
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { coreComponents, coreComponentOptions } from '@/config/componentLoader';
import AbilityMixin from '@/mixins/AbilityMixin';
import clickOutside from '@/directives/click-outside';
import sleep from '@/utils/sleep';

export default {
  name: 'ComponentLoader',
  components: {
    ...coreComponents,
  },
  directives: {
    clickOutside,
  },
  props: {
    layoutKey: {
      type: String,
    },
    addToWidget: {
      type: Function,
    },
    componentOptionsData: {
      type: Array,
      default: () => coreComponentOptions,
    },
  },
  mixins: [AbilityMixin],
  data() {
    return {
      compfilter: '',
      isLoading: false,
      isMultiSelect: false,
      selected: [],
      mounted: false,
      isDropDown: false,
    };
  },
  computed: {
    componentsOptions() {
      return this.componentOptionsData
        .filter((widget) => this.abilities.can('widget', widget.name))
        .sort((a, b) => {
          if (a.options.title < b.options.title) return -1;
          return a.options.title > b.options.title ? 1 : 0;
        });
    },
  },
  methods: {
    componentsFilter() {
      if (this.compfilter.length > 0) {
        return this.componentsOptions.filter(
          (item) => item.options.title.toLowerCase().includes(this.compfilter)
            || item.options.title.toUpperCase().includes(this.compfilter)
            || item.options.title.includes(this.compfilter),
        );
      }
      return this.componentsOptions;
    },
    addLayout(key, option) {
      if (this.isMultiSelect) {
        console.log('xvf', 'add to List', key, option);
        this.selected.push({ key, option });
      } else {
        this.closeDropdown();
        setTimeout(() => {
          this.addToWidget(key, option);
        }, 100);
      }
    },
    addMultipleWidgets() {
      this.closeDropdown();
      this.isMultiSelect = false;
      this.selected.forEach((widget) => {
        sleep(1000).then(() => {
          this.addToWidget(widget.key, widget.option);
        });
      });
    },
    getShortcut(label, _key) {
      return this.$fetchShortcut(label, _key);
    },
    toggleDropdown() {
      this.mounted = true;
      if (this.isDropDown) {
        this.isDropDown = false;
      } else {
        this.isDropDown = true;
      }
    },
    setClose() {
      this.compfilter = '';
      this.isDropDown = false;
      // this.mounted = false;
    },
    closeDropdown() {
      this.compfilter = '';
      this.isDropDown = false;
      // this.isMultiSelect = false;
      // this.selected = [];
      setTimeout(() => {
        this.mounted = false;
      }, 450);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  &__menu {
    visibility: hidden;
    width: 10rem;
    max-height: 30vh;
    background: #fff;
    border-radius: 0.3rem;
    overflow: auto;
    position: absolute;
    left: -6rem;
    top: 3rem;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    // transform: translate(-8rem, -1rem);

    &--show {
      animation: open 400ms ease-in-out forwards;
    }

    &--hidden {
      visibility: visible;
      animation: close 400ms ease-in-out forwards;
    }
  }

  &__search {
    position: sticky;
    top: 0;
    /* left: 30px; */
    width: 100%;
    border: none;
    border-bottom: 1px solid #000;
    padding: 0.5rem 1rem;

    &:focus {
      outline: none;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    cursor: pointer;
  }

  &__link {
    cursor: pointer;
  }

  &__item {
    padding: 0.1rem 1rem;
    font-size: 1rem;

    &-added {
      &:hover {
        background-color: #e9ecef;
      }
    }
  }

  &__select-multiple {
    border-bottom: var(--bs-list-group-border-width) solid
      var(--bs-list-group-border-color);
  }
}

@keyframes open {
  0% {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
  }
  90% {
    visibility: visible;
    max-height: 30vh;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes close {
  0% {
    height: 30vh;
    opacity: 1;
  }
  90% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
}
</style>
