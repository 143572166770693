// list the components to be loaded in vue pages
const coreComponents = {
  ThreeDModelWidget: () => import('../common/widgets/ThreeDModelWidget/ThreeDModelWidget.vue'),
  ActivityTypeWidget: () => import('../common/widgets/ActivityTypeWidget/ActivityTypeWidget.vue'),
  ArchiveWidget: () => import('../common/widgets/ArchiveWidget/ArchiveWidget.vue'),
  BookLibraryWidget: () => import('../common/widgets/BookLibraryWidget/BookLibraryWidget.vue'),
  CompareWidget: () => import('../common/widgets/CompareWidget/CompareWidget.vue'),
  DendroGraphWidget: () => import('../common/widgets/DendroGraphWidget/DendroGraphWidget.vue'),
  DimensionWidget: () => import('../common/widgets/DimensionWidget/DimensionWidget.vue'),
  DetailWidget: () => import('../common/widgets/DetailWidget/DetailWidget.vue'),
  EditorWidget: () => import('../common/widgets/EditorWidget/EditorWidget.vue'),
  GlassWidget: () => import('../common/widgets/GlassWidget/GlassWidget.vue'),
  GlossaryWidget: () => import('../common/widgets/GlossaryWidget/GlossaryWidget.vue'),
  GraphControlsWidget: () => import('../common/widgets/GraphControlsWidget/GraphControlsWidget.vue'),
  GraphExploreWidget: () => import('../common/widgets/GraphExploreWidget/GraphExploreWidget.vue'),
  GraphIndexWidget: () => import('../common/widgets/GraphIndexWidget/GraphIndexWidget.vue'),
  GraphExploreVRWidget: () => import('../common/widgets/GraphExploreVRWidget/GraphExploreVRWidget.vue'),
  GraphLibraryWidget: () => import('../common/widgets/GraphLibraryWidget/GraphLibraryWidget.vue'),
  GutenbergLibraryWidget: () => import('../common/widgets/GutenbergLibraryWidget/GutenbergLibraryWidget.vue'),
  G6NodeFilterWidget: () => import('../common/widgets/G6NodeFilterWidget/G6NodeFilterWidget.vue'),
  HelpWidget: () => import('../common/widgets/HelpWidget/HelpWidget.vue'),
  IframeWidget: () => import('../common/widgets/IframeWidget/IframeWidget.vue'),
  InformationWidget: () => import('../common/widgets/InformationWidget/InformationWidget.vue'),
  // LayoutController: () => import('../common/components/LayoutController.vue'),
  LinkedThoughtsWidget: () => import('../common/widgets/LinkedThoughtsWidget/LinkedThoughtsWidget.vue'),
  // LoaderSemantic: () => import('../common/components/LoaderSemantic.vue'),
  // MiniExplore: () => import('../common/components/MiniExplore.vue'),
  NotesLibraryWidget: () => import('../common/widgets/NotesLibraryWidget/NotesLibraryWidget.vue'),
  QuestLibraryWidget: () => import('../common/widgets/QuestLibraryWidget/QuestLibraryWidget.vue'),
  RecentActivityWidget: () => import('../common/widgets/RecentActivityWidget/RecentActivityWidget.vue'),
  Recentevents: () => import('../common/components/Recentevents.vue'),
  SearchEngineWidget: () => import('../common/widgets/SearchEngineWidget/SearchEngineWidget.vue'),
  SettingsMainWidget: () => import('../common/widgets/SettingsMainWidget/SettingsMainWidget.vue'),
  // SkillGraph: () => import('../common/components/SkillGraph.vue'),
  SnippetWidget: () => import('../common/widgets/SnippetWidget/SnippetWidget.vue'),
  ThoughtMapWidget: () => import('../common/widgets/ThoughtMapWidget/ThoughtMapWidget.vue'),
  // ThoughtMarkdown: () => import('../common/components/ThoughtMarkdown.vue'),
  ThoughtVerseWidget: () => import('../common/widgets/ThoughtVerseWidget/ThoughtVerseWidget.vue'),
  ThoughtVerseAltWidget: () => import('../common/widgets/ThoughtVerseAltWidget/ThoughtVerseAltWidget.vue'),
  ThoughtVerseControlsWidget: () => import('../common/widgets/ThoughtVerseControlsWidget/ThoughtVerseControlsWidget.vue'),
  TreasureMarkerWidget: () => import('../common/widgets/TreasureMarkerWidget/TreasureMarkerWidget.vue'),
  TwitterFeedWidget: () => import('../common/widgets/TwitterFeedWidget/TwitterFeedWidget.vue'),
  UserProfileComp: () => import('../common/components/UserProfileComp.vue'),
  VideosWidget: () => import('../common/widgets/VideosWidget/VideosWidget.vue'),
  EddieWidget: () => import('../common/widgets/EddieWidget/EddieWidget.vue'),
  // VisitedGraphVR: () => import('../common/components/VisitedGraphVR.vue'),
  // VisitedGraphAlt: () => import('../common/components/VisitedGraphAlt.vue'),
  WikiDataCardWidget: () => import('../common/widgets/WikiDataCardWidget/WikiDataCardWidget.vue'),
  WolFramWidget: () => import('../common/widgets/WolFramWidget/WolFramWidget.vue'),
  SearchWidget: () => import('../common/widgets/SearchWidget/SearchWidget.vue'),
  QuestCheckpointWidget: () => import('../common/widgets/QuestCheckpointWidget/QuestCheckpointWidget.vue'),
  GoalsWidget: () => import('../common/widgets/GoalsWidget/GoalsWidget.vue'),
  UserEventGraphWidget: () => import('../common/widgets/UserEventsGraphWidget/UserEventGraphWidget.vue'),
  KaaroWidget: () => import('../common/widgets/PromptLibraryWidget/PromptLibraryWidget.vue'),
  CodeEditorWidget: () => import('../common/widgets/CodeEditorWidget/CodeEditorWidget.vue'),
  JobsWidget: () => import('../common/widgets/JobsWidget/JobsWidget.vue'),
  DrawWidget: () => import('../common/widgets/DrawWidget/DrawWidget.vue'),
};

const coreComponentList = [
  {
    name: 'SearchWidget',
    component: coreComponents.SearchWidget,
    options: {
      title: 'Global Search',
      description: 'Able to Search Globally and set current Entity',
    },
  },
  {
    name: 'ThreeDModelWidget',
    component: coreComponents.ThreeDModelWidget,
    options: {
      title: '3D Models',
      description: 'View in 3D',
    },
  },
  {
    name: 'DimensionWidget',
    component: coreComponents.DimensionWidget,
    options: {
      title: 'Dimension',
      description: 'Dimension',
    },
  },
  {
    name: 'GlossaryWidget',
    component: coreComponents.GlossaryWidget,
    options: {
      title: 'Glossary',
      description: 'Glossary',
    },
  },
  {
    name: 'NotesLibraryWidget',
    component: coreComponents.NotesLibraryWidget,
    options: {
      title: 'Notes Library',
      description: 'View all your notes',
    },
  },
  {
    name: 'VideosWidget',
    component: coreComponents.VideosWidget,
    options: {
      title: 'Videos',
      description: 'View all your notes',
    },
  },
  {
    name: 'GraphIndexWidget',
    component: coreComponents.GraphIndexWidget,
    options: {
      title: 'Graph Index',
      description: 'TOC of graph',
    },
  },
  {
    name: 'TreasureMarker',
    component: coreComponents.TreasureMarker,
    options: {
      title: 'Treasure Marker',
      description: 'TreasureMarker',
    },
  },
  {
    name: 'QuestLibraryWidget',
    component: coreComponents.QuestLibraryWidget,
    options: {
      title: 'Quest Library',
      description: 'View all your Quests',
    },
  },
  {
    name: 'HelpWidget',
    component: coreComponents.HelpWidget,
    options: {
      title: 'Help',
      description: 'View all your Help',
    },
  },
  {
    name: 'EddieWidget',
    component: coreComponents.EddieWidget,
    options: {
      title: 'Eddie',
      description: 'A chatbot powered by openai.',
    },
  },
  {
    name: 'ThoughtMapWidget',
    component: coreComponents.ThoughtMapWidget,
    options: {
      title: 'Thought Map',
      description: 'ThoughtMap',
    },
  },
  {
    name: 'DetailWidget',
    component: coreComponents.DetailWidget,
    options: {
      title: 'Detail',
      description: 'Edit and view your nodes',
    },
  },
  {
    name: 'EditorWidget',
    component: coreComponents.EditorWidget,
    options: {
      title: 'Editor',
      description: 'Edit you notes',
    },
  },
  {
    name: 'G6NodeFilterWidget',
    component: coreComponents.G6NodeFilterWidget,
    options: {
      title: 'Thoughts Filter',
      description: 'G6Filter Node',
    },
  },
  {
    name: 'GraphLibraryWidget',
    component: coreComponents.GraphLibraryWidget,
    options: {
      title: 'Graph Library',
      description: 'View all your graph',
    },
  },
  {
    name: 'LinkedThoughtsWidget',
    component: coreComponents.LinkedThoughtsWidget,
    options: {
      title: 'Linked Thoughts',
      description: 'G6 Graph Wikidata',
    },
  },
  {
    name: 'GraphControlsWidget',
    component: coreComponents.GraphControlsWidget,
    options: {
      title: 'Graph Controls',
      description: 'control force graph with this component',
    },
  },
  // {
  //   name: 'LayoutController',
  //   component: coreComponents.LayoutController,
  //   options: {
  //     title: 'Layout Controller',
  //     description: 'Edit your layout with this config',
  //   },
  // },
  {
    name: 'WikiDataCardWidget',
    component: coreComponents.WikiDataCardWidget,
    options: {
      title: 'Data Card',
      description: 'Get information from wiki data',
    },
  },
  {
    name: 'WolFramWidget',
    component: coreComponents.WolFramWidget,
    options: {
      title: 'Ask Wolfram',
      description: 'Results from WolframAlpha',
    },
  },
  {
    name: 'RecentActivityWidget',
    component: coreComponents.RecentActivityWidget,
    options: {
      title: 'Recent Activity',
      description: 'Gist of what people are doing',
    },
  },
  {
    name: 'SnippetWidget',
    component: coreComponents.SnippetWidget,
    options: {
      title: 'Snippet',
      description: 'Get categorised data from Wiki data',
    },
  },
  {
    name: 'Twitterfeed',
    component: coreComponents.Twitterfeed,
    options: {
      title: 'Twitter Feed',
      description: 'Related tweets from Twitter',
    },
  },
  {
    name: 'CompareWidget',
    component: coreComponents.CompareWidget,
    options: {
      title: 'Compare',
      description: 'Compare your Widget',
    },
  },
  {
    name: 'DendroGraphWidget',
    component: coreComponents.DendroGraphWidget,
    options: {
      title: 'Ongoing Quest',
      description: 'DendroGram Visualization',
    },
  },
  {
    name: 'Recentevents',
    component: coreComponents.Recentevents,
    options: {
      title: 'Recent Events',
      description: 'View all your events',
    },
  },
  // {
  //   name: 'SkillGraph',
  //   component: coreComponents.SkillGraph,
  //   options: {
  //     title: 'Skill graph',
  //     description: 'View all your skills',
  //   },
  // },
  {
    name: 'BookLibraryWidget',
    component: coreComponents.BookLibraryWidget,
    options: {
      title: 'Book Library',
      description: 'View all your books',
    },
  },
  // {
  //   name: 'MiniExplore',
  //   component: coreComponents.MiniExplore,
  //   options: {
  //     title: 'Mini Explore',
  //     description: 'Explore your idea',
  //   },
  // },
  {
    name: 'InformationWidget',
    component: coreComponents.InformationWidget,
    options: {
      title: 'Information',
      description: 'Short Description for Explore',
    },
  },
  {
    name: 'ThoughtVerseWidget',
    component: coreComponents.ThoughtVerseWidget,
    options: {
      title: 'Thoughtverse',
      description: 'Immersive Exploration',
    },
  },
  {
    name: 'ThoughtVerseAltWidget',
    component: coreComponents.ThoughtVerseAltWidget,
    options: {
      title: 'Thoughtverse alt',
      description: 'Immersive Exploration in AR',
    },
  },
  {
    name: 'ThoughtVerseControlsWidget',
    component: coreComponents.ThoughtVerseControlsWidget,
    options: {
      title: 'Thoughtverse Controls',
      description: 'Graph Controls',
    },
  },
  // {
  //   name: 'LoaderSemantic',
  //   component: coreComponents.LoaderSemantic,
  //   options: {
  //     title: 'LoaderSemantic',
  //     description: 'View all async calls',
  //   },
  // },
  {
    name: 'GutenbergLibraryWidget',
    component: coreComponents.GutenbergLibraryWidget,
    options: {
      title: 'Gutenberg Library',
      description: 'View books from Gutenberg',
    },
  },
  {
    name: 'IframeWidget',
    component: coreComponents.IframeWidget,
    options: {
      title: 'Iframe',
      description: 'Dive into another world',
    },
  },
  // {
  //   name: 'VisitedGraphVR',
  //   component: coreComponents.VisitedGraphVR,
  //   options: {
  //     title: 'Visited Graph VR',
  //     description: 'All visited Links in the last Day',
  //   },
  // },
  // {
  //   name: 'VisitedGraphAlt',
  //   component: coreComponents.VisitedGraphAlt,
  //   options: {
  //     title: 'Visited Graph Alt',
  //     description: 'All visited Links in the last Day 3D',
  //   },
  // },
  // {
  //   name: 'ThoughtMarkdown',
  //   component: coreComponents.ThoughtMarkdown,
  //   options: {
  //     title: 'Thought Markdown',
  //     description: 'Autogenerated Markdown from events',
  //   },
  // },
  {
    name: 'GraphExploreWidget',
    component: coreComponents.GraphExploreWidget,
    options: {
      title: 'Graph Explore',
      description: 'GraphExplore',
    },
  },
  {
    name: 'SearchEngineWidget',
    component: coreComponents.SearchEngineWidget,
    options: {
      title: 'Search Engine',
      description: 'To search the Keyword',
    },
  },
  {
    name: 'GraphExploreVRWidget',
    component: coreComponents.GraphExploreVRWidget,
    options: {
      title: 'Graph Explore VR',
      description: 'GraphExploreVR',
    },
  },
  {
    name: 'QuestCheckpointWidget',
    component: coreComponents.QuestCheckpointWidget,
    options: {
      title: 'Quest Navigation',
      description: 'access the quest checkpoints',
    },
  },
  {
    name: 'GoalsWidget',
    component: coreComponents.GoalsWidget,
    options: {
      title: 'Goals',
      description: 'access the User Goals',
    },
  },
  {
    name: 'UserEventGraphWidget',
    component: coreComponents.UserEventGraphWidget,
    options: {
      title: 'Events Graph',
      description: 'access the User Event Graph',
    },
  },
  {
    name: 'KaaroWidget',
    component: coreComponents.KaaroWidget,
    options: {
      title: 'Kaaro',
      description: 'Create, show, edit, delete intents',
    },
  },
  {
    name: 'ArchiveWidget',
    component: coreComponents.ArchiveWidget,
    options: {
      title: 'Books Archive',
      description: 'Archive books',
    },
  },
  {
    name: 'CodeEditorWidget',
    component: coreComponents.PromptLibraryWidget,
    options: {
      title: 'Code Editor',
      description: 'Run your codes in this code editor',
    },
  },
  {
    name: 'JobsWidget',
    component: coreComponents.JobsWidget,
    options: {
      title: 'Jobs Widget',
      description: 'Search Jobs through SERP API',
    },
  },
  {
    name: 'DrawWidget',
    component: coreComponents.DrawWidget,
    options: {
      title: 'Draw Widget',
      description: 'Draw anything and add it to notes or graph',
    },
  },
];

const asscendingAlgoritm = (a, b) => a.name.localeCompare(b.name);
const coreComponentOptions = coreComponentList.sort(asscendingAlgoritm);
export { coreComponents, coreComponentOptions };
