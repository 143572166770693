import chroma from 'chroma-js';
import graphReactor from '@/common/core/Graph/graphReactor';
import GraphEngine from '@/common/core/Graph/graphReactor/GraphEngine';

function queryOperation(payload) {
  Object.values(payload).forEach((item) => {
    item.combos.forEach((combo) => {
      graphReactor.exploreInstance.addItem('combo', combo);
    });
    // graphReactor.exploreInstance.updateLayout();

    item.nodes.forEach((node) => {
      // const nodeExist = graphReactor.exploreInstance.findById(node.id);
      // if (!nodeExist) {
      const nodePayload = {
        type: 'triangle',
        label: node.label,
        tjType: 'custom-node',
        description: { content: '' },
        ...(node?.comboId && { comboId: node.comboId }),
        size: 30,
        labelCfg: {
          /* label's position, options: center, top, bottom, left, right */
          position: 'top',
          color: '#fff',
          /* label's offset to the keyShape, 4 by default */
          offset: 2,
          /* label's style */
          style: {
            fontSize: 15,
            fill: '#fff',
            fontWeight: 500,
            fontFamily: 'Poppins',
            color: '#fff',
          },
        },
        style: {
          size: [200],
          stroke: '#fff',
          fill: '#fc8217',
          lineWidth: 1,
          // shadowOffsetX: 10,
          // shadowOffsetY: 10,
          shadowColor: '#fc8217',
          shadowBlur: 10,
        },
        id: node.label, // Generate a unique id
      };
      GraphEngine.addVertex('explore', nodePayload);
      // }
    });
    item.edges.forEach((edge) => {
      // graphReactor.exploreInstance.addItem('edge', edge);
      GraphEngine.addEdge('explore', edge);
    });
  });
  // graphReactor.exploreInstance.updateLayout();
}

function updateMyNode(val) {
  const updatedNode = GraphEngine.getVertexValue('explore', val.id);
  updatedNode.update(val);
}

function nodeSearch(payload) {
  // commenting out this line because we're not able to use this one with our custom nodes
  // const searchNodeItem = graphReactor.exploreInstance.findById(payload);

  // still has one problem that if the nodes will be available with same label then it will pickup only the first node.
  const searchNodeItem = graphReactor.exploreInstance.find('node', (node) => node.get('model').label === payload);
  // Find all focused item and clear their state.
  graphReactor.exploreInstance.findAllByState('node', 'searchEd').forEach((node) => {
    graphReactor.exploreInstance.clearItemStates(node, 'searchEd');
  });
  graphReactor.exploreInstance.findAllByState('node', 'searchRelatedEd').forEach((node) => {
    graphReactor.exploreInstance.clearItemStates(node, 'searchRelatedEd');
  });
  graphReactor.exploreInstance.findAllByState('edge', 'searchRelatedEd').forEach((edge) => {
    graphReactor.exploreInstance.clearItemStates(edge, 'searchRelatedEd');
  });
  // focus the item that you searched
  searchNodeItem.getNeighbors('node').forEach((neighbor) => {
    // graphReactor.exploreInstance.setItemState(neighbor, 'selected', false);
    graphReactor.exploreInstance.setItemState(neighbor, 'searchRelatedEd', true);
  });
  searchNodeItem.getOutEdges().forEach((edge) => {
    // graphReactor.exploreInstance.setItemState(edge, 'selected', false);
    graphReactor.exploreInstance.setItemState(edge, 'searchRelatedEd', true);
  });
  graphReactor.exploreInstance.focusItem(searchNodeItem);

  // disable any focusEd State if there is one
  graphReactor.exploreInstance.setItemState(searchNodeItem, 'searchEd', true);
}

function nodeSearchClear() {
  graphReactor.exploreInstance.findAllByState('node', 'searchEd').forEach((node) => {
    graphReactor.exploreInstance.clearItemStates(node, 'searchEd');
  });
}

function nodeSearchFocus(focusNodes) {
  graphReactor.exploreInstance.findAllByState('node', 'searchEd').forEach((node) => {
    graphReactor.exploreInstance.clearItemStates(node, 'searchEd');
  });

  graphReactor.exploreInstance.findAllByState('node', 'searchRelatedEd').forEach((node) => {
    graphReactor.exploreInstance.clearItemStates(node, 'searchRelatedEd');
  });
  graphReactor.exploreInstance.findAllByState('edge', 'searchRelatedEd').forEach((edge) => {
    graphReactor.exploreInstance.clearItemStates(edge, 'searchRelatedEd');
  });

  // And then get the focusItem [Array] = and then loop through it and set the focusEd state to true
  focusNodes.forEach((nodeId) => {
    const currentNode = graphReactor.exploreInstance.findById(nodeId);
    graphReactor.exploreInstance.setItemState(currentNode, 'searchEd', true);
  });
}

// function uniqueIdGenerate() {
//   // Generate random id for node
//   return Math.random().toString(36).substr(2, 9);
// }

function deleteSelectedNodes(payload) {
  payload.forEach((item) => {
    graphReactor.exploreInstance.removeItem(item);
  });
}

function removeItem(item) {
  graphReactor.exploreInstance.removeItem(item);
}

function nodeCreateSelect(payload, selectedNodes, isArrow) {
  // const uniqueId = uniqueIdGenerate();
  graphReactor.exploreInstance.addItem('node', {
    type: 'triangle',
    label: payload.currentLabel,
    tjType: 'custom-node',
    size: 30,
    labelCfg: {
      /* label's position, options: center, top, bottom, left, right */
      position: 'top',
      color: '#fff',
      /* label's offset to the keyShape, 4 by default */
      offset: 2,
      /* label's style */
      style: {
        fontSize: 15,
        fill: '#fff',
        fontWeight: 500,
        fontFamily: 'Poppins',
        color: '#fff',
      },
    },
    style: {
      size: [200],
      stroke: '#fff',
      fill: '#fc8217',
      lineWidth: 1,
      // shadowOffsetX: 10,
      // shadowOffsetY: 10,
      shadowColor: '#fc8217',
      shadowBlur: 10,
    },
    description: { content: payload?.currentDescription || '' },
    id: payload.currentLabel, // Generate a unique id
  });
  selectedNodes.forEach((node) => {
    graphReactor.exploreInstance.addItem('edge', {
      // eslint-disable-next-line
      source: node._cfg.id,
      target: payload.currentLabel,
      label: payload.currentEdgeLabel,
      ...(isArrow && {
        style: {
          endArrow: true,
        },
      }),
    });
  });

  // graphReactor.exploreInstance.updateLayout();
}

function comboCreateSelect(label, selectedNodes) {
  // const uniqueId = uniqueIdGenerate();
  const combo = {
    id: label,
    label,
    tjType: 'custom-combo',
    style: {
      lineWidth: 1,
      opacity: 0.3,
      fill: chroma.random().darken().hex(),
    },
  };
  const comboIds = [];
  const nodeIds = [];
  selectedNodes.forEach((node) => {
    const myModel = node.getModel();
    if (myModel.comboId && !comboIds.includes(myModel.comboId)) {
      comboIds.push(myModel.comboId);
    } else if (!myModel.comboId) {
      nodeIds.push(node.getModel().id);
    } else {
      // base collapsed
    }
  });
  graphReactor.exploreInstance.createCombo(combo, [...nodeIds, ...comboIds]);
}

async function addNoteToGraph(item, actor) {
  const userId = actor.username;
  // console.log({ userId });
  let node = {};
  switch (item.value.type || 'default-node') {
    case 'note':
      node = {
        id: item.id,
        // label: item.id,
        label: item.value.note.slice(0, 10),
        description: {
          content: item.value.note,
        },
        type: 'circle',
        tjType: 'note-text',
        tjData: {
          content: item.value.note,
          url: item.value.noteUrl,
        },
        tjNodeState: 'note-text',
      };
      break;
    case 'image':
      node = {
        id: item.id,
        label: item.value.note.substring(0, 15) || 'image',
        img: item.value.note || item.value.imageNote,
        type: 'image',
        size: 50,
        tjType: 'note-image',
        tjData: {
          content: item.value.note || item.value.imageNote,
          url: item.value.noteUrl,
        },

        tjNodeState: 'note-image',
      };
      break;
    case 'recording':
      node = {
        id: item.id,
        // label: item.id,
        label: 'recording',
        description: {
          content: item.value.note,
        },
        tjType: 'note-recording',
        type: 'circle',
        tjData: {
          content: item.value.note,
          url: item.value.noteUrl,
        },
        tjNodeState: 'note-recording',

      };
      break;

    case '3d':
      node = {
        id: item.id,
        label: item.value.label,
        description: {
          content: item.value.note,
        },
        tjType: 'note-3d',
        tjData: {
          content: item.value.note,
          url: item.value.noteUrl,
        },
        size: 30,
        type: 'circle',
        tjNodeState: 'note-3d',

      };
      break;

    case 'page':
      node = {
        id: item.id,
        // label: item.id,
        label: item.value.label || item.value.noteUrl,
        description: {
          content: item.value.noteUrl,
        },
        type: 'circle',
        tjData: {
          content: item.value.noteUrl,
          url: item.value.noteUrl,
        },
        tjType: 'note-page',

        tjNodeState: 'note-page',

      };
      break;
    case 'video':
      node = {
        id: item.id,
        // label: item.id,
        label: item.value?.videoLabel || 'video',
        description: {
          content: item.value.noteUrl,
        },
        tjType: 'note-video',
        tjData: {
          content: item.value.noteUrl,
          url: item.value.noteUrl,
        },
        size: 30,
        type: 'circle',
        style: {
          fill: '#cf0433',
          stroke: '#888',
        },
        tjNodeState: 'note-video',

      };
      break;
    default:
      node = {
        id: item.id,
        // label: item.id,
        label: item.value.note.slice(0, 10),
        description: {
          content: item.value.note,
        },
        tjData: {
          content: item.value.note,
          url: item.value.noteUrl,
        },
        tjType: 'note-text',
        tjNodeState: 'note-text',

      };
  }
  GraphEngine.addVertex('explore', node);
  // this is a user node which occurs when we attach anything from notes
  // for any update regarding that note change here
  if (!GraphEngine.getVertexValue('explore', userId)) {
    const userNode = {
      id: userId,
      label: userId,
      description: userId,
      tjType: 'user',
      size: 50,
      type: 'diamond',
      tjNodeState: 'user',
    };
    GraphEngine.addVertex('explore', userNode);
  }
  const edge = {
    source: userId,
    target: item.id,
    label: 'has noted',
    style: {
      endArrow: true,
    },
  };
  GraphEngine.addEdge('explore', edge);
  const mainid = GraphEngine.getVertexValue('explore', userId);
  const nodeItem = GraphEngine.getVertexValue('explore', item.id);
  console.log({ mainid, nodeItem });
  nodeItem.refresh();
  mainid.refresh();
  graphReactor.exploreInstance.focusItem(mainid, true);
  graphReactor.exploreInstance.updateLayout();
  return node;
}

function addNodeGraph(data, nodeDetails) {
  // const uniqueId = uniqueIdGenerate();
  let nodeShape; let
    nodeState;
  if (data.tjType) {
    nodeShape = data.tjType === 'function' ? 'diamond' : 'triangle';
    nodeState = data.tjType === 'function' ? 'function-node' : 'custom-node';
  } else {
    nodeShape = 'triangle';
    nodeState = 'custom-node';
  }
  GraphEngine.addVertex('explore', {
    x: data?.pos?.x || null,
    y: data?.pos?.y || null,
    size: 20,
    type: nodeShape,
    label: data?.label || nodeDetails,
    tjType: data.tjType || 'custom-node',
    id: data?.label || nodeDetails, // Generate a unique id,
    tjNodeState: nodeState,
    description: { content: '' },
    ...(data.tjType === 'function' ? { function: data.function } : {}),
  });
}

function setOpacityLevel(item, val) {
  graphReactor.exploreInstance.updateItem(item, {
    labelCfg: {
      style: {
        opacity: val,
      },
    },
  });
}

function setOpacityLevelByInstance(item, val, graphInstance) {
  graphInstance.updateItem(item, {
    labelCfg: {
      style: {
        opacity: val,
      },
    },
  });
}

function importDataIntoGraph(data) {
  graphReactor.exploreInstance.data(data);
}

function graphRender() {
  graphReactor.exploreInstance.render();
}

function focusItem(nodeItem, type) {
  let item;
  if (type === 'node') {
    item = GraphEngine.getVertexValue('explore', nodeItem);
    if (!item) {
      return false;
    }
  } else if (type === 'edge') {
    item = GraphEngine.getEdge('explore', nodeItem);
    if (!item) {
      return false;
    }
  }
  graphReactor.exploreInstance.focusItem(item, true, {
    easing: 'easeCubic',
    duration: 400,
  });
  return true;
}

function getItem(eventDetail) {
  let nodeItem;
  if (eventDetail.item) {
    nodeItem = eventDetail.item;
  } else if (eventDetail.id) {
    const graphItem = GraphEngine.getVertexValue('explore', eventDetail);
    nodeItem = graphItem;
  } else {
    throw new Error('Unable to select node from Graph');
  }
  return nodeItem;
}

function graphUpdateLayout(graphName) {
  // function Should be used in for update Layout
  graphReactor[graphName].updateLayout();
}

export default {
  queryOperation,
  updateMyNode,
  nodeSearch,
  nodeSearchClear,
  nodeSearchFocus,
  deleteSelectedNodes,
  nodeCreateSelect,
  comboCreateSelect,
  addNoteToGraph,
  addNodeGraph,
  removeItem,
  setOpacityLevel,
  setOpacityLevelByInstance,
  getItem,
  focusItem,
  graphRender,
  importDataIntoGraph,
  graphUpdateLayout,
};
