/* eslint-disable max-len */
<template>
  <div class="bottomDock" v-if="abilities.can('bottom_dock', 'service')">
    <ul
      class="
        list-group
        d-inline-flex
        flex-row
        justify-content-center
        bottomDock__list
      "
    >
      <li class="dock-icons">
        <SpeechRecognition />
      </li>
      <QuestBottomControls v-if="blockSpecialRoute && abilities.can('save', 'Quest')"/>
      <li class="dock-icons" v-if="blockSpecialRoute && abilities.can('save', 'Quest')">
        <a
          @click="saveToThoughtMap()"
          class="position-relative bottom-dock-btn"
        >
          <i
            class="bottom-dock-btn__icon bx bx-save"
            title="Save Current Thought to Quest"
          ></i>
        </a>
      </li>
      <li class="dock-icons" v-if="blockSpecialRoute && abilities.can('save', 'Quest')">
        <ShareCollaborate />
      </li>
      <ModifyLayout v-if="blockSpecialRoute" />
      <li class="dock-icons" title="Add new Panel" v-if="blockSpecialRoute && abilities.can('create', 'Layout')">
        <div class="bottom-dock-btn">
          <i
            class="d-flex bottom-dock-btn__icon bx bx-window-alt"
            @click="addPanel"
            title="Add new Panel"
          ></i>
        </div>
      </li>
      <li class="dock-icons" title="Add new Panel" v-if="blockSpecialRoute && abilities.can('save', 'Quest')">
        <div class="bottom-dock-btn">
          <i
            :class="`d-flex bottom-dock-btn__icon bx bx-leaf ${questModeNavigationIconClass}`"
            @click="toggleQuestModeNavigation"
            :title="'Quest Mode Navigation: ' + questModeNavigation"
          ></i>
        </div>
      </li>
      <li class="dock-icons" title="Add new Panel" v-if="blockSpecialRoute">
        <div class="bottom-dock-btn">
          <i
            :class="`d-flex bottom-dock-btn__icon bx bx-question-mark ${questModeIconClass}`"
            @click="toggleQuestMode"
            :title="'Quest Mode: ' + questMode"
          ></i>
        </div>
      </li>
      <li class="dock-icons" v-if="blockSpecialRoute">
        <a @click="toggleCamera()" class="bottom-dock-btn">
          <i
            name="camera"
            :class="`bottom-dock-btn__icon bx bx-camera ${iconClass}`"
            title="Toggle Camera"
          ></i>
        </a>
      </li>
      <ScreenRecorder v-if="abilities.can('note', 'ScreenRecording') && blockSpecialRoute" />
    </ul>
  </div>
</template>

<script>
import ModifyLayout from '@/common/components/ModifyLayout.vue';
import SpeechRecognition from '@/common/components/SpeechRecognition.vue';
import ShareCollaborate from '@/common/components/ShareCollaborate.vue';
import ScreenRecorder from '@/common/components/ScreenRecorder.vue';
import AbilityMixin from '@/mixins/AbilityMixin';
import notSpecialRoute from '@/utils/notSpecialRoute';
import QuestBottomControls from './QuestBottomControls.vue';

export default {
  name: 'BottomDock',
  components: {
    SpeechRecognition,
    ShareCollaborate,
    ModifyLayout,
    ScreenRecorder,
    QuestBottomControls,
  },
  mixins: [AbilityMixin],

  computed: {
    iconClass() {
      return this.$store.state.cameraStatus ? 'icon-active' : 'icon-inactive';
    },
    blockSpecialRoute() {
      return notSpecialRoute(this.$store.state.routeInfo, 'mobile');
    },
    questModeNavigation() {
      if (this.$store.state.questMode) {
        return 'ON';
      }
      return 'OFF';
    },
    questMode() {
      if (this.$store.state.quests.quest_mode) {
        return 'ON';
      }
      return 'OFF';
    },
    questModeNavigationIconClass() {
      return this.$store.state.questMode ? 'icon-success' : 'icon-inactive';
    },
    questModeIconClass() {
      return this.$store.state.quests.quest_mode ? 'icon-success' : 'icon-inactive';
    },
  },

  mounted() {
    this.$root.$on('savethought', this.saveToThoughtMap);
    this.$root.$on('openCamera', this.toggleCamera);
    this.$root.$on('addNewPanel', this.addPanel);
    window.addEventListener('keydown', this.handleKeyCombination);
  },
  beforeDestroy() {
    this.$root.$off('savethought');
    this.$root.$off('openCamera');
    this.$root.$off('addNewPanel');
  },
  methods: {
    handleKeyCombination(event) {
      // Check if 'L' key is pressed along with 'Alt' and 'Shift'
      if (event.altKey && event.code === 'KeyB') {
        event.preventDefault();
        this.$root.$emit('addPanel', this.$route.name);
      } else if (event.altKey && event.code === 'KeyT') {
        event.preventDefault();
        this.$store.dispatch('sendUpdateToThoughtMap');
      } else if (event.altKey && event.code === 'KeyQ') {
        event.preventDefault();
        this.toggleQuestMode();
      }
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.handleKeyCombination);
    },
    saveToSnapshot() {
      this.$store.dispatch('saveStateToSnaphot');
    },

    saveToThoughtMap() {
      this.$store.dispatch('sendUpdateToThoughtMap');
    },

    toggleCamera() {
      this.$store.dispatch('toggleCameraStatus');
    },

    addPanel() {
      this.$root.$emit('addPanel', this.$route.name);
    },
    toggleQuestModeNavigation() {
      this.$store.dispatch('toggleQuestMode');
    },
    toggleQuestMode() {
      const questMode = !this.$store.state.quests.quest_mode;
      this.$store.dispatch('quests/setQuestMode', questMode);

      if (questMode) {
        this.$store.dispatch('activateLayout', {
          layoutKey: '_b',
          componentName: 'DendroGraphWidget',
        });
        this.$notification.destroy();
        this.$notification.info({
          message: 'Quest Mode Enabled',
          placement: 'bottomRight',
        });
      } else {
        this.$store.dispatch('activateLayout', {
          layoutKey: '_b',
          componentName: 'GraphExploreWidget',
        });
        this.$notification.destroy();
        this.$notification.info({
          message: 'Quest Mode Disabled',
          placement: 'bottomRight',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bottomDock {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  /* z-index: 2; */
  border-radius: 0.7rem;
  box-shadow: 1px 1px 7px 2px #00000040;
  background: #fff;
}

// .dock-icons {
//   padding: 0.5rem 0.4rem;
//   position: relative;
//   display: block;
//   padding: 0.5rem 1rem;
//   color: #212529;
//   text-decoration: none;
//   background-color: #fff;
// }

.bottomDock__list {
  // box-shadow: 0em 0.125em 0.3125em rgba(0, 0, 0, 0.1);
  max-width: 80vmin;
  // flex-wrap: wrap;
}

.list-group-item {
  border: none;
}

// .list-group-item + .list-group-item {
//   border-left: 0.03125em solid rgba(0, 0, 0, 0.2);
// }

// @media screen and (max-width: 768px) {
//   .bottomDock {
//     display: none;
//   }
// }
</style>
