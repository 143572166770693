import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true, // works for actions, mutations and getters... because for state its namespaced by default
  state: {
    quest_graph_data: {},
    quest_graph_edges: {},
    is_quest_graph_loading: false,
    quest_mode: false,
    active_detail_for_quest: {},
    langchain: {
      context: '',
      res: null,
    },
    namespace: 'private',
    activePrompt: 'main',
    prompts: [],
  },
  actions,
  mutations,
  getters,
};
