import axios from 'axios';

export default {
  namespaced: true,

  state: {
    points: 0,
  },

  getters: {
    getPoints(state) {
      return state.points;
    },
  },

  actions: {
    setPoints({ commit }, username) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_AXIOS_URI}/points/${username}`)
          .then((data) => {
            commit('SET_POINTS', data.data.points);
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },

  mutations: {
    SET_POINTS(state, points) {
      state.points = points;
    },
  },
};
