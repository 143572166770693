const hotKeyMappings = [
  {
    key: 'alt+b',
    mapping: 'BookLibraryWidget',
  },
  {
    key: 'ctrl+q',
    mapping: 'QuestLibraryWidget',
  },
  {
    key: 'alt+ctrl+g',
    mapping: 'GraphControlsWidget',
  },
  {
    key: 'alt+t',
    mapping: 'ThoughtMapWidget',
  },
  {
    key: 'alt+ctrl+e',
    mapping: 'EddieWidget',
  },
  {
    key: 'alt+u',
    mapping: 'GutenbergLibraryWidget',
  },
  {
    key: 'alt+l',
    mapping: 'LinkedThoughtsWidget',
  },
  {
    key: 'alt+shift+g',
    mapping: 'GraphExploreWidget',
  },
  {
    key: 'alt+3',
    mapping: 'ThreeDModelViewerWidget',
  },
  {
    key: 'alt+n',
    mapping: 'NotesLibraryWidget',
  },
  {
    key: 'alt+e',
    mapping: 'EditorWidget',
  },
  {
    key: 'alt+d',
    mapping: 'DetailWidget',
  },
  {
    key: 'alt+w',
    mapping: 'WikiDataCardWidget',
  },
  {
    key: 'alt+r',
    mapping: 'RecentActivityWidget',
  },
  {
    key: 'alt+s',
    mapping: 'SnippetWidget',
  },
  {
    key: 'alt+f',
    mapping: 'TwitterfeedWidget',
  },
  // {
  //   key: 'alt+c',
  //   mapping: 'CompareWidget',
  // },
  {
    key: 'alt+shift+q',
    mapping: 'DendroGraphWidget',
  },
  {
    key: 'alt+h',
    mapping: 'HelpWidget',
  },
  // {
  //   key: 'alt+shift+r',
  //   mapping: 'RecenteventsWidget',
  // },
  // {
  //   key: 'alt+shift+s',
  //   mapping: 'SkillGraphWidget',
  // },
  {
    key: 'alt+m',
    mapping: 'MiniExploreWidget',
  },

  {
    key: 'alt+C',
    mapping: 'GraphControlsWidget',
  },
  {
    key: 'alt+i',
    mapping: 'InformationWidget',
  },
  {
    key: 'alt+shift+t',
    mapping: 'ThoughtverseWidget',
  },
  {
    key: 'alt+shift+a',
    mapping: 'ThoughtversealtWidget',
  },
  {
    key: 'ctrl+shift+f',
    mapping: 'ThoughtversecontrolsWidget',
  },
  {
    key: 'ctrl+alt+s',
    mapping: 'ShareCollaborateWidget',
  },
  {
    key: 'ctrl+alt+l',
    mapping: 'LoaderSemanticWidget',
  },
  // {
  //   key: 'alt+shift+v',
  //   mapping: 'VisitedGraphVRWidget',
  // },
  {
    key: 'alt+shift+o',
    mapping: 'ThoughtMarkdownWidget',
  },
  {
    key: 'alt+g',
    mapping: 'GraphExploreWidget',
  },
];

export default hotKeyMappings;
