class Tree {
  constructor(tree) {
    this.tree = tree;
    this.register = {};
  }

  init() {
    this.register = {};
    this.reverseGare(this.tree);
  }

  reverseGare(curr) {
    if (curr.first instanceof Object) {
      if (curr.first.first instanceof Object) {
        this.reverseGare(curr.first);
      } else {
        this.register[curr.first.first] = {
          parent: curr,
          val: curr.first.second,
          replace: 'first',
        };
      }

      if (curr.first.second instanceof Object) {
        this.reverseGare(curr.first);
      } else {
        this.register[curr.first.second] = {
          parent: curr,
          val: curr.first.first,
          replace: 'first',
        };
      }
    } if (curr.second instanceof Object) {
      if (curr.second.first instanceof Object) {
        this.reverseGare(curr.second);
      } else {
        this.register[curr.second.first] = {
          parent: curr,
          val: curr.second.second,
          replace: 'second',
        };
      }

      if (curr.second.second instanceof Object) {
        this.reverseGare(curr.second);
      } else {
        this.register[curr.second.second] = {
          parent: curr,
          val: curr.second.first,
          replace: 'second',
        };
      }
    }
  }

  remove(id) {
    const data = this.register[`${id}`];
    const { parent } = data;
    const { replace } = data;
    const { val } = data;

    parent[replace] = val;
    this.init();
    return this.tree;
  }
}

export default Tree;
