// myActions.js

import axios from 'axios';
import swal from 'sweetalert';

// const baseURL = `${process.env.VUE_APP_AXIOS_URI_BASE}/api/v1`; // Replace '/api/v1' with your actual base URL for the API

// const api = axios.create({
//   baseURL,
//   headers: {
//     Authorization: `Bearer ${localStorage.getItem('userToken')}`,
//   },
// });

export default {
  /** Description: Used to create Quest Like Graph In `On GoingQuest Widget`
   *
   * - Fetches data in tree like format and then overrides data of CurrentQuest from store.
   * - And when that happens QuestGraph gets updated as well
   */
  async questCreationWithAI({ dispatch, commit }, exploreEntity) {
    const questGraphData = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/eddie/prompt`, {
      prompt: exploreEntity,
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      },
    });
    // trigger ongoing quest creation event
    const eventPayload = {
      verb: 'CREATED_ON_GOING_QUEST',
      value: {
        title: exploreEntity,
      },
    };
    dispatch('eventToEventStore', eventPayload, { root: true });
    commit('SET_QUEST_GRAPH_DATA', questGraphData.data);
  },

  /**
   * Description: This is sort of main entry point of that entire widget... As of writing this (29/05) there
   * are two sort action that happens...
   *
   * 1. if quest_mode is enabled (that you can enable by clicking on `?` icon in GraphControls widget)
   * 2. You questCreation with AI will work.... where each nodeType is `openai_node`
   * 3. Else it would be normal wikidata fetch action.
   */

  async questGraphAction({
    commit, rootState, state, dispatch,
  }, wordObj) {
    // commit('SET_ACTIVE_GRAPH_ID', null, { root: true });
    // if quest mode enable only then run it
    if (state.quest_mode) {
      // if there is active graph from quest library then ask before you override it
      if (rootState.active_graph_id.quest_library) {
        const value = await swal({
          title: 'Override Graph',
          text: 'Do you want to override graph?',
          icon: 'warning',
          buttons: true,
          dangerMode: true,
        });
        // if they say yes then override it or else first deactive the graph and then fetch it
        if (value) {
          commit('IS_QUEST_GRAPH_LOADING', true);
          await dispatch('questCreationWithAI', wordObj.word);
          commit('IS_QUEST_GRAPH_LOADING', false);
        } else {
          commit('SET_ACTIVE_GRAPH_ID', {
            _id: null,
            graph_dimension: 'quest_library',
          }, { root: true });
          commit('IS_QUEST_GRAPH_LOADING', true);
          await dispatch('questCreationWithAI', wordObj.word);
          commit('IS_QUEST_GRAPH_LOADING', false);
        }
      } else {
        // esle if there is not graph active and its empty already then simply fetch it in case of active quest mode
        commit('IS_QUEST_GRAPH_LOADING', true);
        await dispatch('questCreationWithAI', wordObj.word);
        commit('IS_QUEST_GRAPH_LOADING', false);
      }
    }
    // } else {
    //   try {
    //     const res = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/custom/snippet/graph`, {
    //       params: {
    //         word: wordObj.word,
    //         type: wordObj.type,
    //         lang: wordObj.lang,
    //       },
    //     });

    //     const mainNode = res.data.nodes[0];
    //     mainNode.custom_id = mainNode.id;
    //     mainNode.children = res.data.nodes.slice(1, 20).map((el) => ({ ...el, custom_id: el.id }));
    //     const questGraphEdges = res.data.edges.reduce((acc, item) => {
    //       // eslint-disable-next-line no-prototype-builtins
    //       if (!acc.hasOwnProperty(`${item.source}${item.target}`)) {
    //         acc[`${item.source}${item.target}`] = item.label;
    //       }
    //       return acc;
    //     }, {});

    //     commit('SET_QUEST_GRAPH_EDGES', questGraphEdges);

    //     commit('SET_QUEST_GRAPH_DATA', mainNode);
    //   } catch (error) {
    //     console.error('Error retrieving quest graph:', error);
    //   }
    // }
  },

  async groupWiseQuestNode({ rootState }, nodeIds) {
    const questGraphData = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/eddie/quests-group`, {
      nodeIds,
      anchor: rootState.gmodule.anchor,
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      },
    });
    return questGraphData;
  },

  /**
   * `?` icon of GraphControls: that enables quest_mode and explore_mode for this `questGraphAction` and all
   */
  async setQuestMode({ commit }, payload) {
    commit('SET_LIBRARY_MODE_QUEST', payload, { root: true });
    commit('SET_QUEST_MODE', payload);
  },

  // eslint-disable-next-line
  /**
   * Description: This one is used to expand branch, its currently very specific to that exploreMode questGraphAction...
   * needs refactoration
   */
  async expandQuestBranch({ commit }, payload) {
    commit('IS_QUEST_GRAPH_LOADING', true);
    const questGraphEdges = payload.edges.reduce((acc, item) => {
      // eslint-disable-next-line no-prototype-builtins
      if (!acc.hasOwnProperty(`${item.source}${item.target}`)) {
        acc[`${item.source}${item.target}`] = item.label;
      }
      return acc;
    }, {});

    commit('SET_QUEST_GRAPH_EDGES', questGraphEdges);
    commit('IS_QUEST_GRAPH_LOADING', false);
  },

  /** If you have data then directly update it. */
  async setQuestGraphData({ commit }, data) {
    commit('SET_QUEST_GRAPH_DATA', data);
  },

  /**
   * @fn => streamInformationFromGenAI
   * @desc using fetch to Steam and return the content from openAI;
   * @params
   *  payload: {
   *    prompt:String,
   *    context:String,
   *    node_info_section:Object: {
   *      video: false,
   *      questions: false,
   *      article: false,
   *    },
   *    prompt_id:String
   *  }
  */
  async streamInformationFromGenAI(_, payload) {
    console.log('xvf', 'gen', payload);
    const payloadData = {
      prompt: payload.prompt,
      context: payload.context,
    };

    const token = localStorage.getItem('userToken');
    const response = await fetch(`${process.env.VUE_APP_AXIOS_URI}/eddie/prompt/info/stream`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // Add this line
      },
      body: JSON.stringify({
        ...payloadData,
        node_info_section: payload.node_info_section,
        prompt_id: payload.prompt_id,
        stream: true,
      }),
    });

    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
    let nodeDescription = '';

    // The following function will process chunks as soon as they are available
    let chunk = await reader.read();
    while (!chunk.done) {
      const decodedChunk = decoder.decode(chunk.value);
      console.log(decodedChunk);
      nodeDescription += decodedChunk;
      // eslint-disable-next-line no-param-reassign
      // wordObj.tjModel.description += decodedChunk;
      // if (wordObj.tjModel.label.trim() === state.active_detail_for_quest.tjModel.label.trim()) { dispatch('temporaryDetailUpdate', wordObj.tjModel, { root: true }); }
      // eslint-disable-next-line no-await-in-loop
      chunk = await reader.read();
    }
    return nodeDescription;
  },
  /**
   * Fetch info of Node in specific format from opneai
   */

  // eslint-disable-next-line no-empty-pattern
  async fetchQuestNodeInfo({
    dispatch, commit, state, rootState,
  }, wordObj) {
    const payloadData = {
      prompt: wordObj.tjModel.label.trim(),
      context: wordObj.context,
    };
    // eslint-disable-next-line no-param-reassign
    wordObj.tjModel.description = '';
    commit('SET_ACTIVE_DETAIL_FOR_QUEST', wordObj);
    // eslint-disable-next-line no-param-reassign
    dispatch('temporaryDetailUpdate', wordObj.tjModel, { root: true });
    const token = localStorage.getItem('userToken');
    const response = await fetch(`${process.env.VUE_APP_AXIOS_URI}/eddie/prompt/info/stream`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // Add this line
      },
      body: JSON.stringify({
        ...payloadData,
        node_info_section: rootState.node_info_section,
        ...(wordObj.intent ? { prompt_id: wordObj.intent } : state.activePrompt !== 'Select A New Learning Intent' && { prompt_id: state.activePrompt.id }),
        stream: true,
      }),
    });

    const IntentName = state.activePrompt.label;
    const eventPayload = {
      verb: 'SEARCHED_NODE',
      value: {
        searchEntity: wordObj.label,
        Intent: (typeof IntentName !== 'undefined') ? IntentName : 'Default',
      },
    };
    dispatch('eventToEventStore', eventPayload, { root: true });

    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
    let nodeDescription = '';

    // The following function will process chunks as soon as they are available
    let chunk = await reader.read();
    while (!chunk.done) {
      const decodedChunk = decoder.decode(chunk.value);
      console.log(decodedChunk);
      nodeDescription += decodedChunk;
      // eslint-disable-next-line no-param-reassign
      wordObj.tjModel.description += decodedChunk;
      if (wordObj.tjModel.label.trim() === state.active_detail_for_quest.tjModel.label.trim()) { dispatch('temporaryDetailUpdate', wordObj.tjModel, { root: true }); }
      // eslint-disable-next-line no-await-in-loop
      chunk = await reader.read();
    }

    return nodeDescription;
  },

  // eslint-disable-next-line no-unused-vars
  async fetchQuestByKeyword(_, keyword, type = 'quest_library') {
    const url = `${process.env.VUE_APP_AXIOS_URI}/g6graph/query?type=${type}&keyword=${keyword}`;
    const response = await axios.post(url);
    return response.data;
  },

  async fetchQuestExploreInfo({ rootState }, qid) {
    const url = `${process.env.VUE_APP_AXIOS_URI}/custom/snippet/graph`;
    const params = {
      // eslint-disable-next-line
      word: qid, //! this one is supposed to serve only QID type node
      type: 'entity',
      lang: rootState.locale.currentLocale,
    };

    const response = await axios.get(url, { params });
    return response.data;
  },

  async fetchAllPrompts({ commit }) {
    const promptResponse = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/prompts/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      },
    });
    console.log('xvf', promptResponse.data);

    commit('SET_PROMPTS', promptResponse.data);
  },

  async createPrompt({ commit }, { label, prompt, visibility }) {
    const promptResponse = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/prompts/`, {
      label, prompt, visibility,
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      },
    });
    console.log('xvf', promptResponse.data);
    commit('ADD_TO_PROMPTS', { prompt: promptResponse.data });
  },

  async updatePrompt({ commit }, {
    id, label, visibility, prompt,
  }) {
    const promptResponse = await axios.put(`${process.env.VUE_APP_AXIOS_URI}/prompts/${id}`, {
      label, prompt, visibility,
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      },
    });
    console.log('xvf', promptResponse.data);
    commit('UPDATE_TO_PROMPTS', { prompt: promptResponse.data });
  },

  async deletePrompt({ commit }, { id }) {
    const promptResponse = await axios.delete(`${process.env.VUE_APP_AXIOS_URI}/prompts/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      },
    });
    console.log('xvf', promptResponse.data);
    commit('DELETE_A_PROMPT', { prompt: promptResponse.data });
  },

  async setActivePrompt({ dispatch, commit }, prompt) {
    const eventPayload = {
      verb: 'CHANGED_INTENT',
      value: {
        title: prompt.label,
      },
    };
    dispatch('eventToEventStore', eventPayload, { root: true });
    commit('SET_ACTIVE_PROMPT', prompt);
  },
  // eslint-disable-next-line no-empty-pattern
  async updateVectorDatabaseToTrainModel({ rootState, state, commit }, { trainFile, customNamespace }) {
    // Namespace handling
    let definedNamespace = 'main';
    if (state.namespace === 'private') {
      definedNamespace = rootState.auth.user.username;
    } else if (state.namespace === 'custom') {
      definedNamespace = customNamespace;
    }

    // Create FormData object and attach the file
    const formData = new FormData();
    formData.append('train_file', trainFile);
    formData.append('namespace', definedNamespace);
    const response = await axios.post(
      `${process.env.VUE_APP_AXIOS_URI}/eddie/vector/update`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      },
    );
    commit('SET_LANGCHAIN_RESPONSE', response.data.text);
  },

  async askMeQuestionFromTheContextYouHaveTrainedModelOn({ rootState, state, commit }, { question, customNamespace }) {
    // Namespace handling
    let definedNamespace = 'main';
    if (state.namespace === 'private') {
      definedNamespace = rootState.auth.user.username;
    } else if (state.namespace === 'custom') {
      definedNamespace = customNamespace;
    }

    const response = await axios.post(
      `${process.env.VUE_APP_AXIOS_URI}/eddie/vector/ask`,
      {
        prompt: question,
        namespace: definedNamespace,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      },
    );
    console.log('xvf', response.data);
    commit('SET_LANGCHAIN_CONTEXT', response.data.text);
  },

  async updateNamespaceForVectorDB({ commit }, newNamespace) {
    commit('UPDATE_NAMESPACE', newNamespace);
  },
};
