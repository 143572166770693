const propertiesPrompt = (nodes, properties, anchor) => `Given a list of nodes, povide their respective properties that might be meaningful for that node.
${properties ? '\nInclude these Properties(where it makes sense): Role, Radius, Type \n\n' : ''}

List Of Nodes: Narendra Modi, venus, Sun \n\n
Properties:{
  "Narendra Modi": {
    "Role": "Prime Minister of India",
    "Political Party": "Bharatiya Janata Party",
    "Birth Date": "September 17, 1950",
    "Birth Place": "Vadnagar, India"
  },
  "venus": {
    "Type": "Planet",
    "Distance from the Sun": "108.2 million kilometers",
    "Radius": "6,051.8 kilometers",
    "Orbital Period": "225 Earth days",
    "Atmosphere": "Carbon dioxide, sulfuric acid clouds"
  },
  "Sun": {
    "Type": "Star",
    "Mass": "1.989 x 10^30 kilograms",
    "Age": "Approximately 4.6 billion years",
    "Temperature": "Approximately 15 million degrees Celsius",
    "Composition": "Mainly hydrogen and helium"
  }
}


List Of Nodes: ${nodes}
${properties ? ` \nInclude these Properties(where it makes sense ${anchor ? `in context of ${anchor}` : ''}): ${properties} \n\n` : ''}

INSTRUCTION: Make sure to return JSON valid data only.\n\n
Properties:`;

export default {
  propertiesPrompt,
};
