export default {
  SET_GOALS: (state, goals) => {
    state.goals = goals;
  },

  ADD_GOAL: (state, goal) => {
    const myGoals = [...state.goals];
    myGoals.push(goal);
    state.goals = myGoals;
  },

  UPDATE_GOAL: (state, updatedGoal) => {
    const myGoals = [...state.goals];
    state.goals = [];
    const goalIndex = myGoals.findIndex((goal) => goal.id === updatedGoal.id);

    if (goalIndex !== -1) {
      // Replace the existing goal with the updated goal
      myGoals.splice(goalIndex, 1, updatedGoal);
    }
    state.goals = myGoals;
  },

  CREATE_ATTACHMENT_REQUEST: (state, updatedGoal) => {
    state.selectedGoal = updatedGoal;
  },

  DELETE_GOAL: (state, goalId) => {
    const goalIndex = state.goals.findIndex((goal) => goal.id === goalId);

    if (goalIndex !== -1) {
      // Remove the goal from the goalsList array
      state.goals.splice(goalIndex, 1);
    }
  },

  TOGGLE_ADD_MODAL: (state) => {
    state.addModal = !state.addModal;
  },
};
