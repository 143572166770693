import { render, staticRenderFns } from "./BottomDock.vue?vue&type=template&id=6e45aaeb&scoped=true"
import script from "./BottomDock.vue?vue&type=script&lang=js"
export * from "./BottomDock.vue?vue&type=script&lang=js"
import style0 from "./BottomDock.vue?vue&type=style&index=0&id=6e45aaeb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e45aaeb",
  null
  
)

export default component.exports