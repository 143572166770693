var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-page",attrs:{"id":"auth-page"}},[_c('div',{staticClass:"auth-page__col-2"},[_c('div',{staticClass:"tj-logo"},[_c('img',{attrs:{"src":require('@/assets/images/logo.svg'),"alt":""}})]),_c('div',{staticClass:"auth-box"},[_c('div',{staticClass:"header"},[_c('span',{staticClass:"auth-label"},[_vm._v("Don't have an account?")]),_c('button',{staticClass:"ed-btn__blim auth-btn--sm",on:{"click":_vm.successRedirect}},[_vm._v(" Signup ")])]),_vm._m(0),_c('div',{staticClass:"auth__img-container"},[_c('img',{staticClass:"auth__img",attrs:{"src":require('@/assets/images/illustrations/illustration-4.svg')}})]),_c('div',{staticClass:"auth"},[_c('form',{staticClass:"auth__form",on:{"submit":function($event){$event.preventDefault();return _vm.onSignup.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('span',{staticClass:"auth__label"},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"},{name:"focus",rawName:"v-focus",value:(_vm.fieldErrors.email),expression:"fieldErrors.email"}],staticClass:"auth__input",attrs:{"type":"text","name":"email","placeholder":"johndoo@abc.com","autocomplete":"off"},domProps:{"value":(_vm.email)},on:{"focus":function($event){_vm.fieldErrors.email = true},"blur":function($event){_vm.fieldErrors.email = false},"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),(!_vm.$v.email.required && _vm.fieldErrors.email)?_c('span',{staticClass:"auth__notifier",class:{ shake: !_vm.$v.email.error }},[_vm._v("Email is required")]):_vm._e(),(
                !_vm.fieldErrors.email && _vm.submitErrors.email && !_vm.$v.email.required
              )?_c('span',{staticClass:"auth__notifier",class:{ shake: !_vm.$v.email.error }},[_vm._v("Email is required")]):_vm._e(),(_vm.$v.email.required && !_vm.$v.email.email)?_c('span',{staticClass:"auth__notifier",class:{ shake: !_vm.$v.email.error }},[_vm._v(" Please enter a valid email address")]):_vm._e()]),(this.pageError.length > 0)?_c('div',{staticClass:"pageError"},[_vm._v(" "+_vm._s(this.pageError)+" ")]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"ed-btn__blim auth-btn--lg",style:({
                opacity: _vm.isLoading ? 0.7 : 1,
                cursor: _vm.isLoading ? 'default' : 'pointer',
              }),attrs:{"disabled":_vm.isLoading}},[_vm._v(" Send Reset Password Request "),_c('span',{staticClass:"spinner-border spinner-border-sm",style:({
                  opacity: _vm.isLoading ? 1 : 0,
                })})])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"other-methods-box"},[_c('h3',{staticClass:"other-methods__header"},[_vm._v("Forgot Password")]),_c('span',{staticClass:"auth__label"},[_vm._v("We will help you")])])}]

export { render, staticRenderFns }