<template>
  <!-- :class="isActive ? 'dropdown--active' : ''" -->
  <div
    class="ed-dropdown ed-dropdown--active"
    :class="customClass"
    v-click-outside="hide"
    :style="styles"
  >
    <div class="picker" v-if="!this.customPicker" @click="toggleActive">
      <p class="picker__text">{{ selected }}</p>
      <i
        :class="isActive ? 'bx bx-chevron-up' : 'bx bx-chevron-down'"
        class="picker__icon"
      />
    </div>
    <div class="ed-dropdown__list-container" v-show="isActive || isDropped">
      <input v-if="searchFilter" type="text" v-model="filter" class="ed-input__field" placeholder="Search Here...">
      <ul class="ed-dropdown__list">
        <li
          class="ed-dropdown__item"
          v-for="item in resultList"
          :key="item"
          :class="activeTabClass(item)"
          @click="selectActive(item)"
        >
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import clickOutside from '@/directives/click-outside';

export default {
  name: 'ed-dropdown',
  props: {
    selected: {
      type: String,
    },
    listItems: {
      type: Array,
    },
    isSelected: {
      type: Function,
    },
    styles: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
    /**
     * turn this 'true' to
     * set a search bar in dropdown
     */
    searchFilter: {
      type: Boolean,
      default: false,
    },
    customPicker: {
      type: Boolean,
      default: false,
    },
    isDropped: {
      type: Boolean,
      default: false,
    },
    toggleDropped: {
      type: Function,
    },
    /**
     * map function will always be a
     * parameterised function which will be used to
     * map the list if required
     */
    mapFunction: {
      type: Function,
      default: (item) => item,
    },
  },
  directives: {
    clickOutside,
  },
  data() {
    return {
      isActive: false,
      filter: '',
    };
  },
  mounted() {
    if (this.selected.length < 1) {
      this.isSelected(this.listItems[0]);
    }
  },
  computed: {
    resultList() {
      if (this.filter.length > 0) {
        return this.listItems.filter((item) => item.toLowerCase().includes(this.filter) || item.toUpperCase().includes(this.filter) || item.includes(this.filter));
      }
      return this.listItems.map(this.mapFunction);
    },
  },
  methods: {
    toggleActive() {
      this.isActive = !this.isActive;
    },
    selectActive(tab) {
      this.isSelected(tab);
      this.toggleActive();
      this.filter = '';
    },
    activeTabClass(tab) {
      return tab.toLowerCase() === this.selected.toLowerCase() ? 'ed-dropdown__item--active' : '';
    },
    hide() {
      this.isActive = false;
      this.filter = '';
      if (this.customPicker && this.isDropped) {
        this.toggleDropped();
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
