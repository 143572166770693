import GraphEngine from '@/common/core/Graph/graphReactor/GraphEngine';
import graphReactor from '@/common/core/Graph/graphReactor';

const setOpacityLabel = (item, val, graphInstance) => {
  const updateBlock = {
    labelCfg: {
      style: {
        opacity: val,
      },
    },
  };
  GraphEngine.setVertexValue(graphInstance, {
    item,
    value: updateBlock,
  }, '-update');
};

const clearStates = (graphInstance) => {
  let tempGraphInstance = graphInstance;
  if (typeof tempGraphInstance === 'string') tempGraphInstance = graphReactor.exploreInstance;
  tempGraphInstance.getNodes().forEach((node) => {
    GraphEngine.setVertexValue(graphInstance, {
      item: node,
      state: ['opactiyO', 'focusEd', 'relatedEd'],
    }, '-s-cs');
    // graphInstance.clearItemStates(node, ['opacityO', 'focusEd', 'relatedEd']);
    setOpacityLabel(node, 1, graphInstance);
  });
  tempGraphInstance.getEdges().forEach((edge) => {
    GraphEngine.setEdgeValue(graphInstance, {
      item: edge,
      state: ['opacityO', 'relatedEd'],
    }, '-s-cs');
    // graphInstance.clearItemStates(edge, ['opacityO', 'relatedEd']);
    setOpacityLabel(edge, 1, graphInstance);
  });

  // graphInstance.getCombos().forEach((combo) => {
  //   graphInstance.clearItemStates(combo);
  // });
};

export default (nodeModel, graphInstance, dispatch) => {
  clearStates(graphInstance);
  let tempGraphInstance = graphInstance;
  if (typeof tempGraphInstance === 'string') tempGraphInstance = graphReactor.exploreInstance;
  /**
   * 1. '-all' flag get the value of
   * all nodes.
   *
   * 2. '-state' update the state value
   * of nodes.
   */
  GraphEngine.getVertexValue(graphInstance, {}, '-all').forEach((node) => {
    GraphEngine.setVertexValue(graphInstance, {
      item: node, state: 'opacityO', value: true,
    }, '-state');
    // setOpacityLabel(node, 0.1);
  });

  tempGraphInstance.getNodes().forEach((node) => {
    // graphInstance.setItemState(node, 'opacityO', true);
    GraphEngine.setVertexValue(graphInstance, {
      item: node, state: 'opacityO', value: true,
    }, '-state');
    setOpacityLabel(node, 0.1, graphInstance);
    // }
  });

  /**
   * 1. '-all' flags get the
   * value of all edges.
   *
   * 2. '-state' update the
   * state of edges.
  */
  GraphEngine.getEdgeValue(graphInstance, {}, '-all').forEach((edge) => {
    // update the edge state
    GraphEngine.setEdgeValue(graphInstance, {
      item: edge, state: 'opacityO', value: true,
    }, '-state');
    setOpacityLabel(edge, 0.1, graphInstance);
  });
  tempGraphInstance.getEdges().forEach((edge) => {
    // graphInstance.setItemState(edge, 'opacityO', true);
    GraphEngine.setEdgeValue(graphInstance, {
      item: edge, state: 'opacityO', value: true,
    }, '-state');
    setOpacityLabel(edge, 0.1, graphInstance);
  });
  tempGraphInstance.setItemState(nodeModel.id, 'focusEd', true);
  tempGraphInstance.setItemState(nodeModel.id, 'selectEd', true);
  GraphEngine.setVertexValue(graphInstance, {
    item: nodeModel.id,
    state: 'focusEd',
    value: true,
  }, '-state');
  setOpacityLabel(nodeModel.id, 1, graphInstance);
  tempGraphInstance.getNeighbors(nodeModel.id).forEach((neighbor) => {
    tempGraphInstance.setItemState(neighbor, 'relatedEd', true);
    setOpacityLabel(neighbor, 1, graphInstance);
  });
  const findEdge = tempGraphInstance.findAll('edge', (edge) => {
    if (edge.get('model').source === nodeModel.id || edge.get('model').target === nodeModel.id) {
      return true;
    }
    return false;
  });
  findEdge.forEach((edge) => {
    tempGraphInstance.setItemState(edge, 'relatedEd', true);
    tempGraphInstance.setItemState(edge, 'selectEd', true);
    setOpacityLabel(edge, 1, graphInstance);
  });
  // focusItem
  tempGraphInstance.focusItem(nodeModel.id);
  dispatch('toggleHslState', false, { root: true });
};
