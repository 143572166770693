<template lang="">
    <div class="request">
      <div class="request__loading">
        <span class="spinner-border text-info request__spinner"></span>
        <span class="request__text">Please wait! We are Processing your request</span>
      </div>
    </div>
</template>
<script>
export default {
  name: 'ForgotPassword',
  mounted() {
    const { passwordRequestKey } = this.$route.params;
    setTimeout(() => {
      if (passwordRequestKey) {
        this.$store.dispatch('auth/validateResetPasswordRequest', passwordRequestKey).then((data) => {
          if (data.status === 200) {
            const redirectTo = {
              name: 'Reset-Password',
              params: { passwordRequestKey },
            };
            this.$router.push(redirectTo);
          }
        }).catch((err) => {
          const redirectTo = {
            name: 'Auth',
            params: { action: 'Login' },
          };
          this.$router.push(redirectTo);
          throw err;
        });
      } else {
        const redirectTo = {
          name: 'Auth',
          params: { action: 'Login' },
        };
        this.$router.push(redirectTo);
      }
    }, 3000);
  },
};
</script>
<style lang="scss" scoped>
.request {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__loading {
    display: flex;
    align-items: center;
  }

  &__text {
    margin-left: 1rem;
  }
}
</style>
