<template>
  <div class="d-flex">
    <li class="dock-icons" title="Save As">
      <a @click="saveAsQuest()" class="position-relative bottom-dock-btn" title="Quest Save As">
        <i class="bottom-dock-btn__icon bx bx-briefcase-alt-2"></i>
      </a>
    </li>
    <li class="dock-icons" title="Save to Quest" v-if="hasCurrentQuest">
      <a @click="saveToCurrentQuest()" class="position-relative bottom-dock-btn" title="Quest Save to Current">
        <i class="bottom-dock-btn__icon bx bx-cloud-upload"></i>
      </a>
    </li>
    <li class="dock-icons" title="Load from Quest"  v-if="hasCurrentQuest" >
      <a @click="loadFromCurrentQuest()" class="position-relative bottom-dock-btn" title="Quest Load from current (overwrite)">
        <i class="bottom-dock-btn__icon bx bx-cloud-download" ></i>
      </a>
    </li>
    <li class="dock-icons" title="Replay Quest" v-if="canReplayQuest" >
      <a @click="reaplayQuest()" class="position-relative bottom-dock-btn" title="Replay all thoughts">
        <i class="bottom-dock-btn__icon bx bx-reply" name="reply"></i>
      </a>
    </li>
  </div>
</template>

<script>
export default {
  name: 'QuestBottomControls',
  mounted() {

  },
  computed: {
    edit() {
      return this.$store.state.layout.edit;
    },

    resize() {
      return this.$store.state.layout.resize;
    },
    hasCurrentQuest() {
      return !!this.$store.state.current_quest_id;
    },
    canReplayQuest() {
      return this.$store.state.current_quest_id;
    },

    isSaving() {
      return false;
    },
    isLoading() {
      return false;
    },
    isReplaying() {
      return false;
    },
  },

  methods: {
    saveToCurrentQuest() {
      this.$store.dispatch('saveToCurrentQuest');
    },

    loadFromCurrentQuest() {
      this.$store.dispatch('loadFromCurrentQuest');
    },

    reaplayQuest() {
      this.$store.dispatch('replayQuest');
    },
    saveAsQuest() {
      this.$store.dispatch('saveAsQuest');
    },
  },
};
</script>

<style lang="scss" scoped></style>
