export default {
  m: 'multiple',
  multiple: 'multiple',
  rm: 'remove',
  remove: 'remove',
  u: 'update',
  update: 'update',
  t: 'type',
  type: 'type',
  c: 'combo',
  combo: 'combo',
  state: 'state',
  s: 'state',
  clearstate: 'clearState',
  cs: 'clearState',
  all: 'fetchAll',
  fetchall: 'fetchAll',
  bystate: 'byState',
  bs: 'byState',
};
