<template>
  <div class="d-flex thoughtNav mx-2">
    <div v-if="getCurrentEntityPositionInThoughtMap() != -1">
      <span v-for="(node, i) in allLinkedNodes" :key="node.label">
        <div class="d-flex align-items-center" v-if="node.label === currentEntityLabel">
          <i class="box-icon bx bx-chevrons-left" title="Previous" @click="goBackOne" v-if="i > 0"></i>
          <span class='currentEntLabel live px-1'>{{node.label}}</span>
          <i class="box-icon bx bx-chevrons-right" title="Next" @click="goForwardOne" v-show="i < allLinkedNodes.length-1"></i>
        </div>
      </span>
    </div>
    <div v-else>
      <span>
        <div class="d-flex align-items-center">
          <i class="box-icon bx bx-chevrons-left" title="Previous" @click="goToLast" v-if="allLinkedNodes && allLinkedNodes.length > 0" ></i>
          <span class='currentEntLabel px-1'>{{currentEntityLabel}}</span>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ThoughtNavController',
  mounted() {
    this.$root.$on('goForwardThought', this.goForwardOne);
    this.$root.$on('goBackThought', this.goBackOne);
  },
  beforeDestroy() {
    this.$root.$off('goForwardThought');
    this.$root.$off('goBackThought');
  },

  computed: {
    currentEntityLabel() {
      return this.$store.state.current_thought?.label;
    },
    allLinkedNodes() {
      return this.$store.state.nav_data.nodes.filter((el) => el.tjtype === 'entity');
    },
  },

  methods: {
    ...mapGetters({
      getAvailableThoughtNodes: 'getAvailableThoughtNodes',
      getCurrentEntityPositionInThoughtMap: 'getCurrentEntityPositionInThoughtMap',
    }),
    goBackOne() {
      const indexOfCurrent = this.getAvailableThoughtNodes().findIndex((el) => el.label === this.currentEntityLabel);
      const newCurrent = this.getAvailableThoughtNodes()[indexOfCurrent - 1];
      // const wordObj = {
      //   word: newCurrent.label, label: newCurrent.label, type: 'string', lang: 'en',
      // };
      // console.log({ indexOfCurrent, newCurrent, available: this.getAvailableThoughtNodes() });
      this.$store.dispatch('turnOffThoughtMapRecordingState');
      this.$store.dispatch('setCurrentThought', { ...newCurrent.thoughtvalue });
    },
    goForwardOne() {
      const indexOfCurrent = this.getAvailableThoughtNodes().findIndex((el) => el.label === this.currentEntityLabel);
      const newCurrent = this.getAvailableThoughtNodes()[indexOfCurrent + 1];
      // const wordObj = {
      //   word: newCurrent.label, label: newCurrent.label, type: 'string', lang: 'en',
      // };
      // console.log({ indexOfCurrent, newCurrent, available: this.getAvailableThoughtNodes() });
      this.$store.dispatch('turnOffThoughtMapRecordingState');
      this.$store.dispatch('setCurrentThought', { ...newCurrent.thoughtvalue });
      if (this.allLinkedNodes[this.allLinkedNodes.length - 1].label === this.currentEntityLabel) {
        this.$store.dispatch('toggleThoughtMapRecordingState');
      }
    },
    goToLast() {
      const last = this.getAvailableThoughtNodes()[this.getAvailableThoughtNodes().length - 1];
      // const wordObj = {
      //   word: last.label, label: last.label, type: 'string', lang: 'en',
      // };

      this.$store.dispatch('turnOffThoughtMapRecordingState');
      this.$store.dispatch('setCurrentThought', { ...last.thoughtvalue });
    },
    saveToCurrentQuest() {
      this.$store.dispatch('saveToCurrentQuest');
    },

    loadFromCurrentQuest() {
      this.$store.dispatch('loadFromCurrentQuest');
    },

    reaplayQuest() {
      this.$store.dispatch('replayQuest');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/abstracts/variables";

.currentEntLabel {
  color: $gray;
  transform: translateY(-2px);
  &.live {
    color: $edvantaBlue;
    cursor: default;
  }
}

.thoughtNav {
  min-width: 100px;
  align-items: center;
}
</style>
