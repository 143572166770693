import Vue from 'vue';
import VueMeta from 'vue-meta';
import { Sketch } from 'vue-color';
import ReadMore from 'vue-read-more';
import mavonEditor from 'mavon-editor';
import marked from 'marked';
import Antd from 'ant-design-vue';
import SmartTable from 'vuejs-smart-table';
import VueTextToSpeech from 'vue-text-to-speech';
import VModal from 'vue-js-modal';
import JsonViewer from 'vue-json-viewer';
import axios from 'axios';
import { Splitpanes, Pane } from 'splitpanes';
import VueGtag from 'vue-gtag';
import VueSocketIOExt from 'vue-socket.io-extended';
import io from 'socket.io-client';
import vClickOutside from 'click-outside-vue3';
import Vuelidate from 'vuelidate';
import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from 'vue-loaders';
import Multiselect from 'vue-multiselect';
import VueLoadersBallBeat from 'vue-loaders/dist/loaders/ball-beat';
import api from '@/services/api';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap';
import wikiWordFetch from './directives/dblclick-termselect';
import { SendAllThistoNotes, nodeFocusFromNote } from './directives/notes-send';
import i18n from './i18n';
import 'splitpanes/dist/splitpanes.css';
import 'tributejs/dist/tribute.css';
// Custom Plugins Import
import highway from './plugins/highway';
import screenRecorderPlugin from './plugins/edScreen';
import graphConfig from './plugins/GraphConfigs';
import { defineAbilitiesFor } from './plugins/defineAbility';
import { codeList, nodeLink, underlineExtension } from './plugins/markedUtils';
import fetchShortcut from './plugins/fetchShortcut';
import '@/assets/scss/app.scss';
import 'ant-design-vue/dist/antd.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';

axios.interceptors.request.use(
  async (config) => {
    // Add the Authorization header if it's not already set
    if (!config.headers.Authorization) {
      const token = localStorage.getItem('userToken');
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// register globally
Vue.component('multiselect', Multiselect);

const socket = io(`${process.env.VUE_APP_NOTIFICATION_SERVER}`);

marked.use({ extensions: [codeList, nodeLink, underlineExtension] });
Vue.use(Antd);
Vue.use(VueMeta);
Vue.use(mavonEditor);
Vue.use(ReadMore);
Vue.use(Vuelidate);
Vue.use(VueLoaders);
Vue.use(VueLoadersBallBeat);
Vue.use(VueTextToSpeech);
Vue.use(vClickOutside);
Vue.use(VueSocketIOExt, socket);
Vue.component('color-picker', Sketch);
Vue.component('Splitpanes', Splitpanes);
Vue.component('Pane', Pane);
Vue.config.productionTip = false;
Vue.prototype.$http = api;
Vue.prototype.$socket = socket;
Vue.prototype.$wikiWordFetch = wikiWordFetch;
// Register Custom Plugins here
Vue.use(highway, { store });
Vue.use(screenRecorderPlugin, { store, socket });
Vue.use(graphConfig, { store });
Vue.prototype.$defineAbility = defineAbilitiesFor;

Vue.config.ignoredElements = ['a-scene', 'a-entity'];
Vue.use(SmartTable);
Vue.use(JsonViewer);
Vue.use(VModal, { dynamicDefault: { draggable: true, resizable: true } });
Vue.use(VueGtag, {
  config: { id: 'G-88Y1HRJKD7' },
});
Vue.use(fetchShortcut);
// Vue.mixin(mixins);

document.SendAllThistoNotes = SendAllThistoNotes;
document.nodeFocusFromNote = nodeFocusFromNote;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
