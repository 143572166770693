export default {
  fetchGoalWithId: (state) => (goalId) => {
    const myGoals = [...state.goals];

    const goalWithId = myGoals.filter((each) => each.id === goalId);
    if (goalWithId.length > 0) {
      return goalWithId[0];
    }
    return [];
  },

  allPrompts: (state) => [{ _id: 'main', label: 'default' }, ...state.prompts],
};
