import { mapGetters } from 'vuex';

export default {

  methods: {
  },
  computed: {
    ...mapGetters({
      user: 'auth/authUser',
    }),
    abilities() {
      if (this.user && this.user.username) {
        return this.$defineAbility(this.user);
      }
      return this.$defineAbility({});
    },
  },
};
