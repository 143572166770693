<template>
  <div>
    <!-- <select
      class="form-select form-select-sm lang-select"
      aria-label="Language Selector"
      v-model="currentRoute"
    >
      <option v-for="page in allPages" :value="page.routeName" :key="page.id">
        {{ page.text }}
      </option>
    </select> -->
    <ed-dropdown
      :selected="getRouteName(currentRoute)"
      :listItems="allPages"
      :mapFunction="(item) => item.text"
      :isSelected="onChange"
      styles="min-width: 10rem"
      customClass="ed-dropdown__sm"
    />
  </div>
</template>

<script>
import edDropdown from './ed-forms/ed-dropdown.vue';

export default {
  props: ['currentRoute'],
  components: {
    edDropdown,
  },
  data() {
    return {
      selectedView: 'Home',
      allPages: [
        {
          id: 1,
          text: 'Explore view',
          routeName: 'Home',
          viewName: 'exploreView',
        },
        {
          id: 2,
          text: 'Author view',
          routeName: 'AuthorView',
          viewName: 'authorView',
        },
        {
          id: 3,
          text: 'Simplified view',
          routeName: 'SimplifiedView',
          viewName: 'simplifiedView',
        },
        {
          id: 4,
          text: 'Reading view',
          routeName: 'ReadingView',
          viewName: 'readingView',
        },
        {
          id: 5,
          text: 'Canvas View',
          routeName: 'canvas',
          viewName: 'canvasView',
        },
        {
          id: 6,
          text: 'Mobile View',
          routeName: 'MobileView',
          viewName: 'mobileView',
        },
      ],
    };
  },
  methods: {
    onChange(tab) {
      this.allPages.forEach((page) => {
        if (page.text === tab) {
          this.$router.push({
            name: page.routeName,
          });

          this.$store.dispatch('layouts/toggleChangeView', page.viewName);
          const eventPayload = {
            verb: 'CHANGE_VIEW',
            value: {
              title: page.viewName,
            },
          };
          this.$store.dispatch('eventToEventStore', eventPayload);
        }
      });
    },
    getRouteName(tab) {
      const r = this.allPages.filter((e) => e.routeName === tab);
      if (r.length > 0) {
        return r[0].text;
      }
      return this.allPages[0].text;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/abstracts/variables';
.lang-select {
  width: 8.5em !important;
  text-transform: uppercase;
  padding: 0.5em;
  border-radius: 0.3125rem;
  transition: all ease-in-out 200ms;

  &:hover {
    background-color: $edvantaButtonColor;
    cursor: pointer;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}
</style>
