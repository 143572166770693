import axios from 'axios';

export default {
  namespaced: true,

  state: {
    isCompiling: false,
  },

  actions: {
    getCompiledOutput({ commit }, { lang, code }) {
      commit('IS_CODE_COMPILING', true);
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_AXIOS_URI}/code-compile`, {
          lang,
          code: code.trim(),
        }).then((response) => {
          // console.log('xvf', response);
          if (response.status === 200) {
            resolve({ message: 'Complied Successfully', output: response.data.output });
          } else {
            resolve({ message: 'there is some compilation error please check your code' });
          }
        }).catch((error) => {
          // console.log('xvf', error);
          commit('IS_CODE_COMPILING', false);
          reject(error);
        });
      });
    },
    stopCompiling({ commit }) {
      commit('IS_CODE_COMPILING', false);
    },
  },

  mutations: {
    IS_CODE_COMPILING(state, payload) {
      state.isCompiling = payload;
    },
  },
};
